import { loadStripe } from '@stripe/stripe-js';

// Initialize Stripe with public key
const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY);

// Base URL for API calls
const API_BASE_URL = import.meta.env.PROD 
  ? 'https://app.qvee.fr/api'  // Production URL
  : '/api';                     // Development URL

// Product configurations
export const STRIPE_PRODUCTS = {
  basic: {
    priceId: import.meta.env.VITE_STRIPE_PRICE_BASIC,
    productId: import.meta.env.VITE_STRIPE_PRODUCT_BASIC,
    price: 1000, // 10€
    name: 'Basic',
    type: 'payment',
    description: 'Pour démarrer avec la gestion de vos cuvées',
    features: [
      'Jusqu\'à 5 cuvées',
      'Génération de QR codes',
      'Traduction 24 langues',
      'Design simplifié',
      'Support par email'
    ]
  },
  standard: {
    priceId: import.meta.env.VITE_STRIPE_PRICE_STANDARD,
    productId: import.meta.env.VITE_STRIPE_PRODUCT_STANDARD,
    price: 34800, // 348€
    name: 'Standard',
    type: 'subscription',
    description: 'Pour les vignerons qui veulent plus de flexibilité',
    features: [
      'Cuvées illimitées',
      'Génération de QR Codes',
      'Traduction 24 langues',
      'Design simplifié',
      'Support par email'
    ]
  },
  premium: {
    priceId: import.meta.env.VITE_STRIPE_PRICE_PREMIUM,
    productId: import.meta.env.VITE_STRIPE_PRODUCT_PREMIUM,
    price: 64800,
    name: 'Premium',
    type: 'subscription',
    description: 'Pour une gestion professionnelle complète',
    features: [
      'Cuvées illimitées',
      'Génération de QR Codes',
      'Traduction 24 langues',
      'Support en ligne 7j/7',
      'Design des cuvées personnalisées',
      'Options avancées de QR Codes',
      'Statistiques avancées',
      'Assistance technique dédiée',
      'Mises à jour en avant-première'
    ]
  },
  gold: {
    priceId: 'gold_plan',
    productId: 'gold_product',
    price: 0,
    name: 'Gold',
    type: 'subscription',
    description: 'Plan partenaire exclusif',
    features: [
      'Cuvées illimitées',
      'Génération de QR Codes',
      'Traduction 24 langues',
      'Support en ligne 7j/7',
      'Design des cuvées personnalisées',
      'Options avancées de QR Codes',
      'Statistiques avancées',
      'Assistance technique dédiée',
      'Mises à jour en avant-première'
    ],
    hidden: true,
    partnerOnly: true
  }
};

export const createPaymentIntent = async (amount: number, email: string, metadata: any) => {
  try {
    const response = await fetch(`${API_BASE_URL}/stripe/create-payment-intent`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        amount,
        email,
        metadata
      }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Erreur lors de la création de l\'intention de paiement');
    }

    return await response.json();
  } catch (error: any) {
    console.error('Error creating payment intent:', error);
    throw error;
  }
};

export const createCheckoutSession = async ({
  plan,
  email,
  successUrl,
  cancelUrl,
  customerData
}: {
  plan: 'basic' | 'standard' | 'premium';
  email: string;
  successUrl: string;
  cancelUrl: string;
  customerData: {
    firstName: string;
    lastName: string;
    companyName: string;
  };
}) => {
  try {
    const product = STRIPE_PRODUCTS[plan];
    if (!product) {
      throw new Error('Plan invalide');
    }

    const response = await fetch(`${API_BASE_URL}/stripe/create-checkout-session`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        priceId: product.priceId,
        email,
        successUrl,
        cancelUrl,
        customerData,
        mode: product.type === 'subscription' ? 'subscription' : 'payment'
      }),
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error || 'Erreur lors de la création de la session de paiement');
    }

    const session = await response.json();
    return session;
  } catch (error: any) {
    console.error('Error creating checkout session:', error);
    throw error;
  }
};

export { stripePromise };