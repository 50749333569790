import React, { useState, useEffect } from 'react';
import { Info } from 'lucide-react';
import type { CuveeFormData } from '../CuveeForm';

interface CuveeNutritionalInfoProps {
  data: CuveeFormData;
  onChange: (updates: Partial<CuveeFormData>) => void;
}

const CuveeNutritionalInfo: React.FC<CuveeNutritionalInfoProps> = ({ data, onChange }) => {
  const [alcoholContent, setAlcoholContent] = useState(data.alcoholContent?.toString() || '');
  const [residualSugar, setResidualSugar] = useState(
    data.nutritionalValues?.carbohydrates?.toString() || '0'
  );
  const [manualEnergyKj, setManualEnergyKj] = useState(data.energy?.kj?.toString() || '0');
  const [manualEnergyKcal, setManualEnergyKcal] = useState(data.energy?.kcal?.toString() || '0');
  const [isEnergyManual, setIsEnergyManual] = useState(data.isEnergyManual || false);

  // Mettre à jour les valeurs manuelles quand les données changent
  useEffect(() => {
    if (data.energy && data.isEnergyManual) {
      setManualEnergyKj(data.energy.kj.toString());
      setManualEnergyKcal(data.energy.kcal.toString());
      setIsEnergyManual(true);
    }
  }, [data.energy, data.isEnergyManual]);

  // Gérer le changement des valeurs manuelles
  const handleManualEnergyChange = (type: 'kj' | 'kcal', value: string) => {
    if (type === 'kj') {
      setManualEnergyKj(value);
    } else {
      setManualEnergyKcal(value); 
    }

    // Mettre à jour les valeurs dans le state parent
    if (isEnergyManual) {
      onChange({
        energy: {
          kj: type === 'kj' ? parseFloat(value) || 0 : parseFloat(manualEnergyKj) || 0,
          kcal: type === 'kcal' ? parseFloat(value) || 0 : parseFloat(manualEnergyKcal) || 0
        }
      });
    }
  };

  // Gérer le changement du mode manuel/auto
  const handleManualModeChange = (checked: boolean) => {
    setIsEnergyManual(checked);
    
    // Mettre à jour le state parent avec le flag isEnergyManual et les valeurs d'énergie
    onChange({
      isEnergyManual: checked,
      energy: checked ? {
        kj: parseFloat(manualEnergyKj) || 0,
        kcal: parseFloat(manualEnergyKcal) || 0
      } : calculateEnergyAndCarbs(parseFloat(alcoholContent) || 0, parseFloat(residualSugar) || 0)
    });
  };

  // Initialiser les valeurs nutritionnelles au chargement du composant
  useEffect(() => {
    if (!data.nutritionalValues) {
      onChange({
        nutritionalValues: {
          fat: 0,
          saturatedFat: 0,
          carbohydrates: 0,
          sugars: 0,
          proteins: 0,
          salt: 0
        }
      });
    }
  }, []);

  const handleNutritionalValueChange = (field: keyof CuveeFormData['nutritionalValues'], value: string) => {
    if (!data.nutritionalValues) return;

    const numValue = parseFloat(value) || 0;
    onChange({
      nutritionalValues: {
        ...data.nutritionalValues,
        [field]: numValue
      }
    });
  };

  // Initialiser les ingrédients au chargement du composant
  useEffect(() => {
    if (!data.ingredients) {
      onChange({
        ingredients: {
          vinification: [],
          preservatives: [],
          regulators: [],
          stabilizers: [],
          clarifiers: [],
          otherAdditives: [],
          packagingGases: []
        }
      });
    }
  }, []);

  const handleIngredientChange = (category: keyof CuveeFormData['ingredients'], index: number, value: string) => {
    if (!data.ingredients) return;

    const newIngredients = {
      ...data.ingredients,
      [category]: [...data.ingredients[category]]
    };
    newIngredients[category][index] = value;

    onChange({ ingredients: newIngredients });
  };

  const handleAddIngredient = (category: keyof CuveeFormData['ingredients']) => {
    if (!data.ingredients) return;

    const newIngredients = {
      ...data.ingredients,
      [category]: [...data.ingredients[category], '']
    };

    onChange({ ingredients: newIngredients });
  };

  const handleRemoveIngredient = (category: keyof CuveeFormData['ingredients'], index: number) => {
    if (!data.ingredients) return;

    const newIngredients = {
      ...data.ingredients,
      [category]: data.ingredients[category].filter((_, i) => i !== index)
    };

    onChange({ ingredients: newIngredients });
  };

  // Calcul des valeurs nutritionnelles
  useEffect(() => {
    // Ne pas recalculer si en mode manuel
    if (isEnergyManual) return;

    const alcohol = parseFloat(alcoholContent) || 0;
    const sugar = parseFloat(residualSugar) || 0;
    const { energyKcal, energyKj, carbohydrates } = calculateEnergyAndCarbs(alcohol, sugar);

    onChange({
      isEnergyManual: false,
      energy: {
        kj: energyKj,
        kcal: energyKcal
      },
      nutritionalValues: {
        ...data.nutritionalValues,
        carbohydrates: carbohydrates,
        sugars: carbohydrates
      }
    });
  }, [alcoholContent, residualSugar]);

  const calculateEnergyAndCarbs = (alcoholVol: number, residualSugar: number) => {
    const alcoholEnergyKcal = (alcoholVol * 8 * 0.8 / 100) * 7;
    const alcoholEnergyKj = alcoholEnergyKcal * 4.184;
    const sugarEnergyKcal = residualSugar * 4;
    const sugarEnergyKj = sugarEnergyKcal * 4.184;
    const calculatedEnergyKcal = alcoholEnergyKcal + sugarEnergyKcal;
    const calculatedEnergyKj = alcoholEnergyKj + sugarEnergyKj;
    
    // N'utiliser les valeurs calculées que si le mode manuel n'est pas activé
    const finalEnergyKcal = isEnergyManual ? parseFloat(manualEnergyKcal) || 0 : calculatedEnergyKcal;
    const finalEnergyKj = isEnergyManual ? parseFloat(manualEnergyKj) || 0 : calculatedEnergyKj;
    const carbohydrates = residualSugar;

    return {
      energyKcal: Math.round(finalEnergyKcal * 10) / 10,
      energyKj: Math.round(finalEnergyKj * 10) / 10,
      carbohydrates: Math.round(carbohydrates * 10) / 10
    };
  };

  return (
    <div className="space-y-6">
      {/* Calculateur d'énergie */}
      <div className="bg-gray-50 p-6 rounded-lg">
        <h3 className="text-lg font-medium text-gray-900 mb-4">Calcul Energie</h3>
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
          <div className="col-span-2 flex items-center justify-end mb-2">
            <label className="flex items-center cursor-pointer">
              <input
                type="checkbox"
                checked={isEnergyManual}
                onChange={(e) => handleManualModeChange(e.target.checked)}
                className="h-4 w-4 text-purple-600 focus:ring-purple-500 border-gray-300 rounded"
              />
              <span className="ml-2 text-sm text-gray-600">
                Éditer manuellement les valeurs d'énergie
              </span>
            </label>
          </div>

          <div>
            <label htmlFor="alcoholContent" className="block text-sm font-medium text-gray-700">
              Volume d'alcool (%/Vol)
            </label>
            <input
              type="number"
              id="alcoholContent"
              value={alcoholContent}
              onChange={(e) => setAlcoholContent(e.target.value)}
              step="0.1"
              min="0"
              max="100"
              className="mt-1 block w-full pl-3 pr-3 py-3 border border-gray-300 bg-gray-50 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent transition duration-150 ease-in-out"
            />
          </div>

          <div>
            <label htmlFor="residualSugar" className="block text-sm font-medium text-gray-700">
              Sucres résiduels (g/L)
            </label>
            <input
              type="number"
              id="residualSugar"
              value={residualSugar}
              onChange={(e) => setResidualSugar(e.target.value)}
              step="0.1"
              min="0"
              className="mt-1 block w-full pl-3 pr-3 py-3 border border-gray-300 bg-gray-50 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent transition duration-150 ease-in-out"
            />
          </div>
        </div>

        <div className="mt-4 p-4 bg-white rounded-lg border border-gray-200">
          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-1">
              <label htmlFor="energyKj" className="text-sm text-gray-500">
                Energie (kJ/100ml)
              </label>
              <input
                type="number"
                id="energyKj"
                value={isEnergyManual ? manualEnergyKj : data.energy?.kj || 0}
                onChange={(e) => handleManualEnergyChange('kj', e.target.value)}
                step="0.1"
                min="0"
                className={`block w-full pl-3 pr-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent ${
                  isEnergyManual ? 'bg-white' : 'bg-gray-50'
                }`}
                disabled={!isEnergyManual}
              />
            </div>
            <div className="space-y-1">
              <label htmlFor="energyKcal" className="text-sm text-gray-500">
                Energie (kcal/100ml)
              </label>
              <input
                type="number"
                id="energyKcal"
                value={isEnergyManual ? manualEnergyKcal : data.energy?.kcal || 0}
                onChange={(e) => handleManualEnergyChange('kcal', e.target.value)}
                step="0.1"
                min="0"
                className={`block w-full pl-3 pr-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent ${
                  isEnergyManual ? 'bg-white' : 'bg-gray-50'
                }`}
                disabled={!isEnergyManual}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Valeurs nutritionnelles */}
      <div className="bg-white p-6 rounded-lg border border-gray-200">
        <h3 className="text-lg font-medium text-gray-900 mb-4">Valeurs nutritionnelles</h3>
        <p className="text-sm text-gray-500 mb-4">Pour 100ml</p>

        <div className="space-y-4">
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Matières grasses
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <input
                  type="number"
                  value={data.nutritionalValues?.fat || 0}
                  onChange={(e) => handleNutritionalValueChange('fat', e.target.value)}
                  step="0.1"
                  min="0"
                  className="mt-1 block w-full pl-3 pr-3 py-3 border border-gray-300 bg-gray-50 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                />
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <span className="text-gray-500 sm:text-sm">g</span>
                </div>
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                dont acides gras saturés
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <input
                  type="number"
                  value={data.nutritionalValues?.saturatedFat || 0}
                  onChange={(e) => handleNutritionalValueChange('saturatedFat', e.target.value)}
                  step="0.1"
                  min="0"
                  className="mt-1 block w-full pl-3 pr-3 py-3 border border-gray-300 bg-gray-50 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                />
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <span className="text-gray-500 sm:text-sm">g</span>
                </div>
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Protéines
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <input
                  type="number"
                  value={data.nutritionalValues?.proteins || 0}
                  onChange={(e) => handleNutritionalValueChange('proteins', e.target.value)}
                  step="0.1"
                  min="0"
                  className="mt-1 block w-full pl-3 pr-3 py-3 border border-gray-300 bg-gray-50 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                />
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <span className="text-gray-500 sm:text-sm">g</span>
                </div>
              </div>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700">
                Sel
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <input
                  type="number"
                  value={data.nutritionalValues?.salt || 0}
                  onChange={(e) => handleNutritionalValueChange('salt', e.target.value)}
                  step="0.1"
                  min="0"
                  className="mt-1 block w-full pl-3 pr-3 py-3 border border-gray-300 bg-gray-50 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                />
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <span className="text-gray-500 sm:text-sm">g</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Additifs et ingrédients */}
      <div>
        <h3 className="text-lg font-medium text-gray-900 mb-4">Additifs soumis à étiquetage</h3>
        
        <div className="space-y-6">
          {[
            { key: 'vinification', label: 'Ingrédients lors de la vinification' },
            { key: 'preservatives', label: 'Conservateurs utilisés' },
            { key: 'regulators', label: 'Régulateurs utilisés' },
            { key: 'stabilizers', label: 'Stabilisants utilisés' },
            { key: 'clarifiers', label: 'Clarifiants' },
            { key: 'otherAdditives', label: 'Autres additifs' },
            { key: 'packagingGases', label: 'Gaz d\'emballage' }
          ].map(category => (
            <div key={category.key}>
              <label className="block text-sm font-medium text-gray-700 mb-2">
                {category.label}
              </label>
              <div className="space-y-2">
                {(data.ingredients?.[category.key as keyof CuveeFormData['ingredients']] || []).map((item, index) => (
                  <div key={index} className="flex items-center space-x-2">
                    <input
                      type="text"
                      value={item}
                      onChange={(e) => handleIngredientChange(
                        category.key as keyof CuveeFormData['ingredients'],
                        index,
                        e.target.value
                      )}
                      className="flex-1 pl-3 pr-3 py-3 border border-gray-300 bg-gray-50 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent transition duration-150 ease-in-out"
                      placeholder={`Entrez un ${category.label.toLowerCase()}`}
                    />
                    <button
                      type="button"
                      onClick={() => handleRemoveIngredient(
                        category.key as keyof CuveeFormData['ingredients'],
                        index
                      )}
                      className="text-red-600 hover:text-red-700"
                    >
                      Supprimer
                    </button>
                  </div>
                ))}
                <button
                  type="button"
                  onClick={() => handleAddIngredient(category.key as keyof CuveeFormData['ingredients'])}
                  className="text-sm text-purple-600 hover:text-purple-700"
                >
                  + Ajouter un élément
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CuveeNutritionalInfo;