import React, { useState, useEffect } from 'react';
import { Award, Loader2 } from 'lucide-react';
import { getCertificationImageUrl } from '../constants/certificationImages';

interface CertificationImageProps {
  name: string;
  className?: string;
}

const CertificationImage: React.FC<CertificationImageProps> = ({ name, className = "h-16 w-auto" }) => {
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let mounted = true;

    const loadImage = async () => {
      try {
        setLoading(true);
        setError(false);

        const url = await getCertificationImageUrl(name);
        
        // Preload the image
        const img = new Image();
        img.onload = () => {
          if (mounted) {
            setImageUrl(url);
            setLoading(false);
          }
        };
        img.onerror = () => {
          if (mounted) {
            setError(true);
            setLoading(false);
          }
        };
        img.src = url;
      } catch (err) {
        if (mounted) {
          setError(true);
          setLoading(false);
        }
      }
    };

    loadImage();

    return () => {
      mounted = false;
    };
  }, [name]);

  if (loading) {
    return (
      <div className={`flex items-center justify-center ${className}`}>
        <Loader2 className="h-6 w-6 text-gray-400 animate-spin" />
      </div>
    );
  }

  if (error || !imageUrl) {
    return (
      <div className={`flex items-center justify-center ${className}`}>
        <Award className="h-full w-full text-gray-400" />
      </div>
    );
  }

  return (
    <img 
      src={imageUrl}
      alt={`Certification ${name}`}
      className={className}
      onError={() => setError(true)}
    />
  );
};

export default CertificationImage;