// Configuration Brevo
export const BREVO_API_KEY = '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';
export const BREVO_API_URL = 'https://api.brevo.com/v3/smtp/email';
export const LOGO_URL = 'https://qvee.fr/assets/logo-full.png';
export const FAVICON_URL = 'https://firebasestorage.googleapis.com/v0/b/qvee-1651e.appspot.com/o/qvee%2Ffavicon-qvee.png?alt=media';

// Configuration Firebase
export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyDQKSaKo7xU-VRDGdMEaLn4mv32W_x0CV4",
  authDomain: "qvee-1651e.firebaseapp.com",
  projectId: "qvee-1651e",
  storageBucket: "qvee-1651e.firebasestorage.app",
  messagingSenderId: "622152058291",
  appId: "1:622152058291:web:68713ddee7250819ccb7c6",
  actionCodeSettings: {
    url: 'https://app.qvee.fr/__/auth/action',
    handleCodeInApp: true,
    iOS: {
      bundleId: 'com.qvee.app'
    },
    android: {
      packageName: 'com.qvee.app',
      installApp: true,
      minimumVersion: '12'
    },
    dynamicLinkDomain: 'qvee.page.link'
  }
};

// Configuration reCAPTCHA
const USE_CAPTCHA_DEBUG = import.meta.env.VITE_USE_CAPTCHA_DEBUG === 'true';

export const RECAPTCHA_SITE_KEY = USE_CAPTCHA_DEBUG 
  ? import.meta.env.VITE_RECAPTCHA_SITE_KEY_DEV 
  : import.meta.env.VITE_RECAPTCHA_SITE_KEY_PROD;

export const RECAPTCHA_SECRET_KEY = USE_CAPTCHA_DEBUG
  ? import.meta.env.VITE_RECAPTCHA_SECRET_KEY_DEV
  : import.meta.env.VITE_RECAPTCHA_SECRET_KEY_PROD;