import React, { useState } from 'react';
import { AlertTriangle, X, Mail, Lock, Eye, EyeOff } from 'lucide-react';
import { useAuthContext } from '../contexts/AuthContext';
import { doc, updateDoc } from 'firebase/firestore';
import { db, initiateEmailChange } from '../lib/firebase';

interface EmailChangeModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const EmailChangeModal: React.FC<EmailChangeModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
}) => {
  const { user } = useAuthContext();
  const [step, setStep] = useState<'form' | 'verification'>('form');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isAnimating, setIsAnimating] = useState(false);

  const handleSubmit = async () => {
    if (!user || !newEmail || !currentPassword) return;

    try {
      setIsLoading(true);
      setError(null);

      // 1. Stocker l'ID utilisateur dans le localStorage
      localStorage.setItem('pendingEmailUserId', user.uid);

      // 2. Stocker le nouvel email temporairement dans Firestore
      const userRef = doc(db, 'users', user.uid);
      await updateDoc(userRef, {
        pendingEmail: newEmail.toLowerCase(),
        pendingEmailTimestamp: new Date().toISOString(),
        pendingEmailCredential: {
          email: user.email,
          password: currentPassword
        }
      });

      // 3. Initier le changement d'email
      await initiateEmailChange(user.uid, newEmail, currentPassword);

      setIsAnimating(true);
      setTimeout(() => {
        setStep('verification');
        setIsAnimating(false);
      }, 300);
    } catch (err: any) {
      console.error('Error updating email:', err);
      setError(err.message || 'Une erreur est survenue');
      // En cas d'erreur, nettoyer le localStorage
      localStorage.removeItem('pendingEmailUserId');
    } finally {
      setIsLoading(false);
    }
  };

  const handleResendVerification = async () => {
    if (!user || !newEmail || !currentPassword) return;

    try {
      setIsLoading(true);
      setError(null);
      await initiateEmailChange(user.uid, newEmail, currentPassword);
    } catch (err: any) {
      setError(err.message || 'Erreur lors de l\'envoi du code');
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setStep('form');
    setCurrentPassword('');
    setNewEmail('');
    setError(null);
    // Nettoyer le localStorage à la fermeture
    localStorage.removeItem('pendingEmailUserId');
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex min-h-screen items-center justify-center p-4">
        <div
          className="fixed inset-0 bg-black bg-opacity-25 transition-opacity"
          onClick={handleClose}
        />

        <div className="relative w-full max-w-lg transform overflow-hidden rounded-lg bg-white p-6 text-left shadow-xl transition-all">
          {step === 'form' ? (
            <>
              <div className="flex items-center space-x-4 mb-6">
                <div className="flex-shrink-0">
                  <div className="h-12 w-12 rounded-full bg-purple-100 flex items-center justify-center">
                    <Mail className="h-6 w-6 text-purple-600" />
                  </div>
                </div>
                <div>
                  <h3 className="text-lg font-medium text-gray-900">
                    Changer d'adresse email
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Veuillez entrer votre mot de passe actuel et votre nouvelle adresse email
                  </p>
                </div>
              </div>

              <div className="space-y-4">
                <div>
                  <label htmlFor="currentPassword" className="block text-sm font-medium text-gray-700">
                    Mot de passe actuel
                  </label>
                  <div className="mt-1 relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <Lock className="h-5 w-5 text-gray-400" />
                    </div>
                    <input
                      type={showPassword ? "text" : "password"}
                      id="currentPassword"
                      value={currentPassword}
                      onChange={(e) => setCurrentPassword(e.target.value)}
                      className="block w-full pl-11 pr-10 py-3 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                      required
                    />
                    <button
                      type="button"
                      onClick={() => setShowPassword(!showPassword)}
                      className="absolute inset-y-0 right-0 pr-3 flex items-center"
                    >
                      {showPassword ? (
                        <EyeOff className="h-5 w-5 text-gray-400" />
                      ) : (
                        <Eye className="h-5 w-5 text-gray-400" />
                      )}
                    </button>
                  </div>
                </div>

                <div>
                  <label htmlFor="newEmail" className="block text-sm font-medium text-gray-700">
                    Nouvelle adresse email
                  </label>
                  <div className="mt-1 relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <Mail className="h-5 w-5 text-gray-400" />
                    </div>
                    <input
                      type="email"
                      id="newEmail"
                      value={newEmail}
                      onChange={(e) => setNewEmail(e.target.value)}
                      className="block w-full pl-11 pr-3 py-3 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                      required
                    />
                  </div>
                </div>

                {error && (
                  <div className="p-3 bg-red-50 border border-red-200 rounded-lg">
                    <p className="text-sm text-red-600">{error}</p>
                  </div>
                )}

                <div className="bg-yellow-50 border border-yellow-100 rounded-lg p-4">
                  <p className="text-sm text-yellow-800">
                    <strong>Important :</strong> Un email de vérification sera envoyé à votre nouvelle adresse. Vous devrez cliquer sur le lien dans cet email pour confirmer le changement.
                  </p>
                </div>
              </div>

              <div className="mt-6 flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={handleClose}
                  className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
                  disabled={isLoading}
                >
                  Annuler
                </button>
                <button
                  type="button"
                  onClick={handleSubmit}
                  disabled={isLoading || !currentPassword || !newEmail}
                  className="px-4 py-2 text-sm font-medium text-white bg-purple-600 rounded-lg hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 disabled:opacity-50"
                >
                  {isLoading ? 'Envoi en cours...' : 'Envoyer le lien de vérification'}
                </button>
              </div>
            </>
          ) : (
            <div className="text-center py-6">
              <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-purple-100">
                <Mail className="h-6 w-6 text-purple-600" />
              </div>
              <h3 className="mt-4 text-lg font-medium text-gray-900">
                Vérifiez votre nouvelle adresse email
              </h3>
              <p className="mt-2 text-sm text-gray-500">
                Un email de vérification a été envoyé à <strong>{newEmail}</strong>
              </p>
              <div className="mt-4 p-4 bg-yellow-50 border border-yellow-100 rounded-lg">
                <p className="text-sm text-yellow-700">
                  <strong>Important :</strong> Veuillez suivre ces étapes :
                </p>
                <ol className="mt-2 list-decimal list-inside text-sm text-yellow-700 space-y-1">
                  <li>Ouvrez l'email envoyé à <strong>{newEmail}</strong></li>
                  <li>Cliquez sur le lien de vérification dans l'email</li>
                  <li>Votre email sera automatiquement mis à jour</li>
                </ol>
              </div>

              {error && (
                <div className="mt-4 p-4 bg-red-50 border border-red-100 rounded-lg">
                  <p className="text-sm text-red-600">{error}</p>
                </div>
              )}

              <div className="mt-6 flex justify-center space-x-3">
                <button
                  onClick={handleResendVerification}
                  disabled={isLoading}
                  className="text-sm text-purple-600 hover:text-purple-500"
                >
                  {isLoading ? 'Envoi en cours...' : 'Renvoyer l\'email'}
                </button>
              </div>

              <div className="mt-4">
                <button
                  onClick={handleClose}
                  className="text-sm text-gray-500 hover:text-gray-700"
                >
                  Fermer
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmailChangeModal;