import React, { useState, useEffect } from 'react';
import { Building2, Shield, Bell, Clock, Menu, Crown } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuthContext } from '../contexts/AuthContext';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useNotifications } from '../hooks/useNotifications';
import NotificationsMenu from './NotificationsMenu';
import MobileMenu from './MobileMenu';

const Navbar: React.FC = () => {
  const { user } = useAuthContext();
  const [userProfile, setUserProfile] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const navigate = useNavigate();

  const { notifications, markAsRead, unreadCount } = useNotifications(
    user?.uid,
    userProfile?.plan || 'basic'
  );

  useEffect(() => {
    if (!user?.uid) {
      setLoading(false);
      return;
    }

    const unsubscribe = onSnapshot(doc(db, 'users', user.uid), (doc) => {
      if (doc.exists()) {
        setUserProfile(doc.data());
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [user]);

  const formatName = () => {
    if (!userProfile?.firstName || !userProfile?.lastName) return '';
    const lastName = userProfile.lastName.charAt(0).toUpperCase();
    return `${userProfile.firstName} ${lastName}.`;
  };

  const formatPlan = (plan: string) => {
    if (plan === 'gold') {
      return (
        <span className="inline-flex items-center text-yellow-600 font-medium">
          <Crown className="h-4 w-4 mr-1" />
          Gold
        </span>
      );
    }
    return plan.charAt(0).toUpperCase() + plan.slice(1);
  };

  const isAdmin = userProfile?.role === 'admin';

  if (loading) {
    return (
      <nav className="bg-white border-b border-gray-200 px-6 py-3">
        <div className="flex items-center justify-end">
          <div className="w-32 h-10 bg-gray-100 rounded-lg animate-pulse"></div>
        </div>
      </nav>
    );
  }

  return (
    <>
      <nav className="bg-white border-b border-gray-200 px-6 py-3">
        <div className="flex items-center">
          {/* Burger Menu - Left aligned */}
          <div className="md:hidden flex-none">
            <button
              onClick={() => setIsMobileMenuOpen(true)}
              className="p-2 rounded-lg hover:bg-gray-100 transition-colors"
              aria-label="Menu"
            >
              <Menu className="h-6 w-6 text-gray-600" />
            </button>
          </div>

          {/* Admin Search Bar */}
          {isAdmin && (
            <div className="flex-1 hidden md:flex items-center justify-start">
              <div className="relative w-64">
                <input
                  type="text"
                  placeholder="Rechercher..."
                  className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                />
              </div>
            </div>
          )}

          {/* Right-aligned items */}
          <div className="flex-1 flex items-center justify-end space-x-6">
            {userProfile && !userProfile.isEmailVerified && (
              <Link
                to="/settings"
                className="hidden md:flex items-center space-x-2 px-3 py-1 bg-yellow-50 border border-yellow-200 rounded-full hover:bg-yellow-100 transition-colors"
              >
                <span className="text-sm font-medium text-yellow-800">Non vérifié</span>
              </Link>
            )}

            {userProfile && userProfile.status === 'pending_deletion' && (
              <button 
                onClick={() => navigate('/settings')}
                className="hidden md:flex items-center space-x-2 px-3 py-1 bg-red-50 border border-red-200 rounded-full hover:bg-red-100 transition-colors"
              >
                <Clock className="h-4 w-4 text-red-600" />
                <span className="text-sm font-medium text-red-800">Suppression en cours</span>
              </button>
            )}

            {/* Only show notifications for non-admin users */}
            {!isAdmin && (
              <div className="relative">
                <button 
                  className="relative p-2 text-gray-600 hover:bg-gray-100 rounded-full transition-colors"
                  onClick={() => setShowNotifications(!showNotifications)}
                  aria-label="Notifications"
                >
                  <Bell className="h-6 w-6" />
                  {unreadCount > 0 && (
                    <span className="absolute top-0 right-0 h-4 w-4 bg-red-500 rounded-full text-xs text-white flex items-center justify-center">
                      {unreadCount}
                    </span>
                  )}
                </button>

                <NotificationsMenu
                  isOpen={showNotifications}
                  onClose={() => setShowNotifications(false)}
                  notifications={notifications}
                  onMarkAsRead={markAsRead}
                />
              </div>
            )}

            <div className="flex items-center space-x-3">
              <div className="hidden md:block text-right">
                <p className="text-sm font-medium text-gray-900">{formatName()}</p>
                {isAdmin ? (
                  <div className="flex items-center text-red-600">
                    <Shield className="h-4 w-4 mr-1" />
                    <span className="text-xs font-medium">Admin</span>
                  </div>
                ) : (
                  <Link 
                    to="/subscription" 
                    className={`text-xs font-medium ${
                      userProfile?.plan === 'gold' 
                        ? 'text-yellow-600 hover:text-yellow-700' 
                        : 'text-purple-600 hover:text-purple-700'
                    }`}
                  >
                    {userProfile?.plan ? formatPlan(userProfile.plan) : 'Basic'}
                  </Link>
                )}
              </div>
              <div className="h-10 w-10 rounded-full overflow-hidden flex items-center justify-center bg-gray-100">
                {userProfile?.company?.logo ? (
                  <img
                    src={userProfile.company.logo}
                    alt={`Logo ${userProfile.company.name}`}
                    className="h-full w-full object-cover"
                  />
                ) : (
                  <Building2 className="h-6 w-6 text-gray-600" />
                )}
              </div>
            </div>
          </div>
        </div>
      </nav>

      <MobileMenu
        isOpen={isMobileMenuOpen}
        onClose={() => setIsMobileMenuOpen(false)}
        isAdmin={isAdmin}
        userProfile={userProfile}
        userPlan={userProfile?.plan || 'basic'}
      />
    </>
  );
};

export default Navbar;