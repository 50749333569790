import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { CheckCircle2, Loader2, AlertTriangle } from 'lucide-react';
import { handleEmailActionURL } from '../lib/firebase';

const EmailVerificationHandler: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [status, setStatus] = useState<'loading' | 'success' | 'error'>('loading');
  const [error, setError] = useState<string | null>(null);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const handleEmailVerification = async () => {
      try {
        // 1. Récupérer les paramètres de l'URL
        const searchParams = new URLSearchParams(location.search);
        const mode = searchParams.get('mode');
        const actionCode = searchParams.get('oobCode');

        if (!mode || !actionCode) {
          throw new Error('Paramètres manquants');
        }

        // 2. Simuler une progression
        const progressInterval = setInterval(() => {
          setProgress(prev => {
            if (prev >= 90) {
              clearInterval(progressInterval);
              return 90;
            }
            return prev + 10;
          });
        }, 500);

        // 3. Gérer l'action d'email
        const result = await handleEmailActionURL(mode, actionCode);
        
        if (!result.success) {
          throw new Error(result.error || 'Erreur lors de la vérification');
        }

        // 4. Mise à jour finale
        clearInterval(progressInterval);
        setProgress(100);
        setStatus('success');
        
        // 5. Rediriger après 3 secondes
        setTimeout(() => {
          navigate('/login');
        }, 3000);
      } catch (err: any) {
        console.error('Error in email verification:', err);
        setStatus('error');
        setError(err.message || 'Une erreur est survenue');
      }
    };

    handleEmailVerification();
  }, [navigate, location]);

  return (
    <div className="min-h-screen bg-gray-50 flex items-center justify-center">
      <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full text-center">
        {status === 'loading' && (
          <>
            <div className="relative mx-auto w-24 h-24 mb-6">
              <div className="absolute inset-0 rounded-full border-4 border-purple-100"></div>
              <div
                className="absolute inset-0 rounded-full border-4 border-purple-500 animate-spin"
                style={{ 
                  borderRightColor: 'transparent', 
                  borderTopColor: 'transparent',
                  animationDuration: '2s'
                }}
              ></div>
              <div className="absolute inset-0 flex items-center justify-center">
                <Loader2 className="h-12 w-12 text-purple-500" />
              </div>
            </div>
            <h2 className="text-xl font-semibold text-gray-900 mb-2">
              Mise à jour de votre email...
            </h2>
            <p className="text-gray-600 mb-4">
              Veuillez patienter pendant que nous finalisons la mise à jour.
            </p>
            <div className="w-full bg-gray-200 rounded-full h-2 mb-4">
              <div
                className="h-full bg-purple-500 rounded-full transition-all duration-300 ease-out"
                style={{ width: `${progress}%` }}
              ></div>
            </div>
          </>
        )}

        {status === 'error' && (
          <>
            <div className="mx-auto flex h-20 w-20 items-center justify-center rounded-full bg-red-100 mb-6">
              <AlertTriangle className="h-10 w-10 text-red-600" />
            </div>
            <h2 className="text-xl font-semibold text-gray-900 mb-4">
              Erreur lors de la mise à jour
            </h2>
            <p className="text-red-600 mb-6">
              {error}
            </p>
            <button
              onClick={() => navigate('/login')}
              className="btn"
            >
              Retour à la connexion
            </button>
          </>
        )}

        {status === 'success' && (
          <>
            <div className="mx-auto flex h-20 w-20 items-center justify-center rounded-full bg-green-100 mb-6">
              <CheckCircle2 className="h-10 w-10 text-green-600" />
            </div>
            <h2 className="text-xl font-semibold text-gray-900 mb-2">
              Email mis à jour avec succès !
            </h2>
            <p className="text-gray-600 mb-4">
              Votre adresse email a été modifiée. Vous allez être redirigé vers la page de connexion...
            </p>
            <div className="w-full bg-gray-200 rounded-full h-2">
              <div 
                className="h-full bg-green-500 rounded-full transition-all duration-[3000ms] ease-linear w-0"
                style={{ animation: 'progress 3s linear forwards' }}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default EmailVerificationHandler;