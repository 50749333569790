import React from 'react';
import { CreditCard, Calendar, AlertCircle } from 'lucide-react';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { STRIPE_PRODUCTS } from '../lib/stripe';

interface BillingDetailsProps {
  subscription: {
    status: string;
    currentPeriodEnd?: string;
    canceledAt?: string;
  };
  plan: string;
}

const BillingDetails: React.FC<BillingDetailsProps> = ({ subscription, plan }) => {
  const planDetails = STRIPE_PRODUCTS[plan as keyof typeof STRIPE_PRODUCTS];
  const isFreePlan = plan === 'basic';
  const isGoldPlan = plan === 'gold';
  const isCanceled = subscription?.status === 'canceled';
  const nextBillingDate = subscription?.currentPeriodEnd 
    ? format(new Date(subscription.currentPeriodEnd), 'dd MMMM yyyy', { locale: fr })
    : null;

  if (isFreePlan) {
    return (
      <div className="bg-white rounded-lg shadow-sm border border-gray-200 overflow-hidden">
        <div className="px-6 py-5 border-b border-gray-200">
          <div className="flex items-center">
            <CreditCard className="h-5 w-5 text-gray-400 mr-3" />
            <h2 className="text-lg font-medium text-gray-900">
              Détails de facturation
            </h2>
          </div>
        </div>
        <div className="px-6 py-5">
          <p className="text-gray-600">
            Vous utilisez actuellement le plan Basic gratuit. Aucune facturation n'est en cours.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow-sm border border-gray-200 overflow-hidden">
      <div className="px-6 py-5 border-b border-gray-200">
        <div className="flex items-center">
          <CreditCard className="h-5 w-5 text-gray-400 mr-3" />
          <h2 className="text-lg font-medium text-gray-900">
            Détails de facturation
          </h2>
        </div>
      </div>

      <div className="px-6 py-5 space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div>
            <h3 className="text-sm font-medium text-gray-500">Plan actuel</h3>
            <p className="mt-1 text-lg font-medium text-gray-900">
              {planDetails.name}
            </p>
          </div>

          <div>
            <h3 className="text-sm font-medium text-gray-500">Montant</h3>
            <p className="mt-1 text-lg font-medium text-gray-900">
              {(planDetails.price / 100).toFixed(2)}€/mois
            </p>
          </div>

          <div>
            <h3 className="text-sm font-medium text-gray-500">Statut</h3>
            <div className="mt-1 flex items-center">
              {isCanceled ? (
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-yellow-100 text-yellow-800">
                  En cours d'annulation
                </span>
              ) : (
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800">
                  Actif
                </span>
              )}
            </div>
          </div>
        </div>

        {nextBillingDate && (
          <div className="flex items-center space-x-2 text-sm text-gray-600">
            <Calendar className="h-4 w-4" />
            <span>
              {isCanceled ? 'Fin de l\'abonnement' : 'Prochaine facturation'} le {nextBillingDate}
            </span>
          </div>
        )}

        {isCanceled && (
          <div className="flex items-start space-x-2 bg-yellow-50 p-4 rounded-lg">
            <AlertCircle className="h-5 w-5 text-yellow-400 flex-shrink-0 mt-0.5" />
            <div>
              <h4 className="text-sm font-medium text-yellow-800">
                Abonnement en cours d'annulation
              </h4>
              <p className="mt-1 text-sm text-yellow-700">
                Votre abonnement restera actif jusqu'au {nextBillingDate}. Après cette date, votre compte passera automatiquement au plan Basic.
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BillingDetails;