import React, { useState, useEffect } from 'react';
import { FileText, Search, Filter, ArrowUpDown } from 'lucide-react';
import { collection, query, orderBy, getDocs, limit, where } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { formatDateTime } from '../../utils/dateUtils';

interface ActivityLog {
  id: string;
  type: 'auth' | 'payment' | 'cuvee' | 'moderation' | 'security';
  action: string;
  userId: string;
  userName: string;
  details: string;
  createdAt: any;
  metadata?: {
    userName?: string;
    companyName?: string;
    cuveeName?: string;
    category?: string;
    words?: string[];
    email?: string;
    attempts?: number;
    duration?: string;
  };
}

const ITEMS_PER_PAGE_OPTIONS = [20, 50, 100];

const ServerLogs: React.FC = () => {
  const [logs, setLogs] = useState<ActivityLog[]>([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterType, setFilterType] = useState<'all' | 'auth' | 'payment' | 'cuvee' | 'moderation' | 'security'>('all');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalLogs, setTotalLogs] = useState(0);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchLogs = async () => {
      try {
        setLoading(true);
        setError(null);
        const logsRef = collection(db, 'activityLogs');
        
        // Construire la requête
        let baseQuery;
        if (filterType === 'all') {
          baseQuery = query(
            logsRef,
            orderBy('createdAt', sortOrder),
            limit(itemsPerPage)
          );
        } else {
          baseQuery = query(
            logsRef,
            where('type', '==', filterType),
            orderBy('createdAt', sortOrder),
            limit(itemsPerPage)
          );
        }

        const snapshot = await getDocs(baseQuery);
        const logsData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })) as ActivityLog[];

        setLogs(logsData);

        // Obtenir le nombre total de logs
        const totalSnapshot = await getDocs(collection(db, 'activityLogs'));
        setTotalLogs(totalSnapshot.size);
      } catch (err: any) {
        console.error('Error fetching logs:', err);
        setError('Une erreur est survenue lors du chargement des logs');
      } finally {
        setLoading(false);
      }
    };

    fetchLogs();
  }, [filterType, sortOrder, itemsPerPage, currentPage]);

  const getLogIcon = (type: string) => {
    switch (type) {
      case 'auth':
        return <div className="h-2 w-2 rounded-full bg-blue-500" />;
      case 'payment':
        return <div className="h-2 w-2 rounded-full bg-green-500" />;
      case 'cuvee':
        return <div className="h-2 w-2 rounded-full bg-purple-500" />;
      case 'security':
        return <div className="h-2 w-2 rounded-full bg-red-500" />;
      case 'moderation':
        return <div className="h-2 w-2 rounded-full bg-orange-500" />;
      default:
        return <div className="h-2 w-2 rounded-full bg-gray-500" />;
    }
  };

  const getLogColor = (type: string) => {
    switch (type) {
      case 'auth':
        return 'bg-blue-100 text-blue-800';
      case 'payment':
        return 'bg-green-100 text-green-800';
      case 'cuvee':
        return 'bg-purple-100 text-purple-800';
      case 'security':
        return 'bg-red-100 text-red-800';
      case 'moderation':
        return 'bg-orange-100 text-orange-800';
      default:
        return 'bg-gray-100 text-gray-800';
    }
  };

  const filteredLogs = logs.filter(log => {
    const searchLower = searchTerm.toLowerCase();
    return (
      log.userName.toLowerCase().includes(searchLower) ||
      log.details.toLowerCase().includes(searchLower) ||
      log.action.toLowerCase().includes(searchLower)
    );
  });

  const totalPages = Math.ceil(totalLogs / itemsPerPage);

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 space-y-8">
      <div>
        <h1 className="text-2xl font-bold text-gray-900">Logs serveur</h1>
        <p className="mt-1 text-sm text-gray-600">
          Historique des actions système
        </p>
      </div>

      <div className="bg-white rounded-lg shadow">
        <div className="p-4 border-b border-gray-200">
          <div className="flex flex-col sm:flex-row gap-4">
            <div className="flex-1 relative">
              <input
                type="text"
                placeholder="Rechercher dans les logs..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
              />
              <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
            </div>
            <div className="flex gap-2">
              <select
                value={filterType}
                onChange={(e) => setFilterType(e.target.value as any)}
                className="rounded-lg border border-gray-300 px-4 py-2 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
              >
                <option value="all">Tous les types</option>
                <option value="auth">Connexions</option>
                <option value="payment">Paiements</option>
                <option value="cuvee">Cuvées</option>
                <option value="moderation">Modération</option>
                <option value="security">Sécurité</option>
              </select>
              <button
                onClick={() => setSortOrder(order => order === 'asc' ? 'desc' : 'asc')}
                className="p-2 rounded-lg border border-gray-300 hover:bg-gray-50"
              >
                <ArrowUpDown className="h-5 w-5 text-gray-500" />
              </button>
              <select
                value={itemsPerPage}
                onChange={(e) => setItemsPerPage(Number(e.target.value))}
                className="rounded-lg border border-gray-300 px-4 py-2 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
              >
                {ITEMS_PER_PAGE_OPTIONS.map(option => (
                  <option key={option} value={option}>
                    {option} par page
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Type
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Utilisateur
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Action
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Détails
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Date
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {loading ? (
                <tr>
                  <td colSpan={5} className="px-6 py-4 text-center">
                    <div className="flex justify-center">
                      <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-purple-600"></div>
                    </div>
                  </td>
                </tr>
              ) : error ? (
                <tr>
                  <td colSpan={5} className="px-6 py-4 text-center text-red-600">
                    {error}
                  </td>
                </tr>
              ) : filteredLogs.length === 0 ? (
                <tr>
                  <td colSpan={5} className="text-center py-12">
                    <FileText className="h-12 w-12 text-gray-400 mx-auto mb-4" />
                    <p className="text-gray-500">Aucun log trouvé</p>
                  </td>
                </tr>
              ) : (
                filteredLogs.map((log) => (
                  <tr key={log.id} className="hover:bg-gray-50">
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className={`p-2 rounded-lg ${getLogColor(log.type)}`}>
                          {getLogIcon(log.type)}
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                        {log.userName}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">
                        {log.action}
                      </div>
                    </td>
                    <td className="px-6 py-4">
                      <div className="text-sm text-gray-500">
                        {log.details}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {formatDateTime(log.createdAt)}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>

        <div className="px-4 py-3 border-t border-gray-200">
          <div className="flex items-center justify-between">
            <div className="text-sm text-gray-700">
              Affichage de {(currentPage - 1) * itemsPerPage + 1} à{' '}
              {Math.min(currentPage * itemsPerPage, totalLogs)} sur {totalLogs} logs
            </div>
            <div className="flex space-x-2">
              <button
                onClick={() => setCurrentPage(p => Math.max(1, p - 1))}
                disabled={currentPage === 1}
                className="px-3 py-1 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                Précédent
              </button>
              <button
                onClick={() => setCurrentPage(p => Math.min(totalPages, p + 1))}
                disabled={currentPage === totalPages}
                className="px-3 py-1 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                Suivant
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServerLogs;