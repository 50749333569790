import React, { useState, useEffect } from 'react';
import { AlertTriangle, X, Lock, Eye, EyeOff, LogOut } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../contexts/AuthContext';
import { auth } from '../lib/firebase';
import { updatePassword, EmailAuthProvider, reauthenticateWithCredential } from 'firebase/auth';
import PasswordStrengthIndicator from './PasswordStrengthIndicator';

interface PasswordChangeModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (newPassword: string) => Promise<void>;
  isProcessing: boolean;
}

const PasswordChangeModal: React.FC<PasswordChangeModalProps> = ({
  isOpen,
  onClose,
  onConfirm,
  isProcessing
}) => {
  const navigate = useNavigate();
  const { user, logout } = useAuthContext();
  const [step, setStep] = useState<'info' | 'form' | 'logout'>('info');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isAnimating, setIsAnimating] = useState(false);
  const [countdown, setCountdown] = useState(5);
  const [progress, setProgress] = useState(100);

  useEffect(() => {
    if (!isOpen) {
      // Reset state when modal closes
      setStep('info');
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
      setError(null);
      setIsAnimating(false);
      setCountdown(5);
      setProgress(100);
    }
  }, [isOpen]);

  const handleContinue = () => {
    setIsAnimating(true);
    setTimeout(() => {
      setStep('form');
      setIsAnimating(false);
    }, 300);
  };

  const handleBack = () => {
    setIsAnimating(true);
    setTimeout(() => {
      setStep('info');
      setIsAnimating(false);
    }, 300);
  };

  const validatePassword = (password: string) => {
    let metCriteria = 0;
    if (password.length >= 8) metCriteria++;
    if (/[A-Z]/.test(password)) metCriteria++;
    if (/[a-z]/.test(password)) metCriteria++;
    if (/\d/.test(password)) metCriteria++;
    if (/[!@#$%^&*(),.?":{}|<>]/.test(password)) metCriteria++;
    return metCriteria >= 4;
  };

  const startLogoutCountdown = () => {
    const startTime = Date.now();
    const duration = 5000; // 5 secondes

    const timer = setInterval(() => {
      const elapsed = Date.now() - startTime;
      const remaining = Math.max(0, duration - elapsed);
      const newProgress = (remaining / duration) * 100;
      
      setCountdown(Math.ceil(remaining / 1000));
      setProgress(newProgress);

      if (remaining <= 0) {
        clearInterval(timer);
        handleLogout();
      }
    }, 100);

    return () => clearInterval(timer);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  const handleSubmit = async () => {
    try {
      setError(null);

      if (!validatePassword(newPassword)) {
        setError('Le mot de passe doit remplir au moins 4 critères de sécurité');
        return;
      }

      if (newPassword !== confirmPassword) {
        setError('Les mots de passe ne correspondent pas');
        return;
      }

      const user = auth.currentUser;
      if (!user?.email) {
        throw new Error('Utilisateur non connecté');
      }

      // Réauthentifier l'utilisateur
      const credential = EmailAuthProvider.credential(user.email, currentPassword);
      await reauthenticateWithCredential(user, credential);

      // Mettre à jour le mot de passe
      await updatePassword(user, newPassword);
      
      // Appeler onConfirm pour la mise à jour dans Firestore
      await onConfirm(newPassword);

      // Transition vers la déconnexion
      setIsAnimating(true);
      setTimeout(() => {
        setStep('logout');
        setIsAnimating(false);
        startLogoutCountdown();
      }, 300);
    } catch (err: any) {
      console.error('Error updating password:', err);
      if (err.code === 'auth/wrong-password' || err.code === 'auth/invalid-credential') {
        setError('Mot de passe actuel incorrect');
      } else {
        setError(err.message || 'Une erreur est survenue');
      }
    }
  };

  const handleClose = () => {
    // Empêcher la fermeture pendant la déconnexion
    if (step === 'logout') return;

    setIsAnimating(true);
    setTimeout(() => {
      setStep('info');
      setCurrentPassword('');
      setNewPassword('');
      setConfirmPassword('');
      setError(null);
      setIsAnimating(false);
      onClose();
    }, 300);
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex min-h-screen items-center justify-center p-4">
        <div
          className="fixed inset-0 bg-black bg-opacity-25 transition-opacity"
          onClick={step !== 'logout' ? handleClose : undefined}
        />

        <div className={`relative w-full max-w-lg transform overflow-hidden rounded-lg bg-white p-6 text-left shadow-xl transition-all duration-300 ${
          isAnimating ? 'opacity-0 scale-95' : 'opacity-100 scale-100'
        }`}>
          {step !== 'logout' && (
            <div className="absolute right-4 top-4">
              <button
                onClick={handleClose}
                className="rounded-full p-1 hover:bg-gray-100 transition-colors"
              >
                <X className="h-5 w-5 text-gray-500" />
              </button>
            </div>
          )}

          {step === 'info' && (
            <div className={`transition-opacity duration-300 ${isAnimating ? 'opacity-0' : 'opacity-100'}`}>
              <div className="flex items-center space-x-4 mb-6">
                <div className="flex-shrink-0">
                  <div className="h-10 w-10 rounded-full bg-yellow-100 flex items-center justify-center">
                    <AlertTriangle className="h-5 w-5 text-yellow-600" />
                  </div>
                </div>
                <div>
                  <h3 className="text-lg font-medium text-gray-900">
                    Modifier votre mot de passe
                  </h3>
                </div>
              </div>

              <div className="space-y-4">
                <p className="text-sm text-gray-600">
                  Vous êtes sur le point de modifier votre mot de passe. Voici ce qui se passera :
                </p>

                <ul className="list-disc list-inside space-y-2 text-sm text-gray-600 ml-4">
                  <li>Votre mot de passe sera immédiatement mis à jour</li>
                  <li>Vous serez automatiquement déconnecté</li>
                  <li>Vous devrez vous reconnecter avec votre nouveau mot de passe</li>
                </ul>

                <div className="bg-yellow-50 p-4 rounded-lg">
                  <p className="text-sm text-yellow-800">
                    <strong>Important :</strong> Assurez-vous de bien mémoriser ou noter votre nouveau mot de passe avant de confirmer.
                  </p>
                </div>
              </div>

              <div className="mt-6 flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={handleClose}
                  className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
                >
                  Annuler
                </button>
                <button
                  type="button"
                  onClick={handleContinue}
                  className="px-4 py-2 text-sm font-medium text-white bg-purple-600 rounded-lg hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                >
                  Continuer
                </button>
              </div>
            </div>
          )}

          {step === 'form' && (
            <div className={`transition-opacity duration-300 ${isAnimating ? 'opacity-0' : 'opacity-100'}`}>
              <div className="space-y-6">
                <div>
                  <label htmlFor="currentPassword" className="block text-sm font-medium text-gray-700">
                    Mot de passe actuel
                  </label>
                  <div className="mt-1 relative">
                    <input
                      type={showCurrentPassword ? "text" : "password"}
                      id="currentPassword"
                      value={currentPassword}
                      onChange={(e) => setCurrentPassword(e.target.value)}
                      className="block w-full rounded-lg border border-gray-300 px-3 py-2 shadow-sm focus:border-purple-500 focus:outline-none focus:ring-purple-500"
                      placeholder="••••••••"
                    />
                    <button
                      type="button"
                      className="absolute inset-y-0 right-0 pr-3 flex items-center"
                      onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                    >
                      {showCurrentPassword ? (
                        <EyeOff className="h-5 w-5 text-gray-400" />
                      ) : (
                        <Eye className="h-5 w-5 text-gray-400" />
                      )}
                    </button>
                  </div>
                </div>

                <div>
                  <label htmlFor="newPassword" className="block text-sm font-medium text-gray-700">
                    Nouveau mot de passe
                  </label>
                  <div className="mt-1 relative">
                    <input
                      type={showNewPassword ? "text" : "password"}
                      id="newPassword"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      className="block w-full rounded-lg border border-gray-300 px-3 py-2 shadow-sm focus:border-purple-500 focus:outline-none focus:ring-purple-500"
                      placeholder="••••••••"
                    />
                    <button
                      type="button"
                      className="absolute inset-y-0 right-0 pr-3 flex items-center"
                      onClick={() => setShowNewPassword(!showNewPassword)}
                    >
                      {showNewPassword ? (
                        <EyeOff className="h-5 w-5 text-gray-400" />
                      ) : (
                        <Eye className="h-5 w-5 text-gray-400" />
                      )}
                    </button>
                  </div>
                  {newPassword && <PasswordStrengthIndicator password={newPassword} />}
                </div>

                <div>
                  <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">
                    Confirmer le mot de passe
                  </label>
                  <div className="mt-1">
                    <input
                      type={showNewPassword ? "text" : "password"}
                      id="confirmPassword"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      className="block w-full rounded-lg border border-gray-300 px-3 py-2 shadow-sm focus:border-purple-500 focus:outline-none focus:ring-purple-500"
                      placeholder="••••••••"
                    />
                  </div>
                </div>

                {error && (
                  <div className="p-3 bg-red-50 border border-red-200 rounded-lg">
                    <p className="text-sm text-red-600">{error}</p>
                  </div>
                )}
              </div>

              <div className="mt-6 flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={handleBack}
                  className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
                  disabled={isProcessing}
                >
                  Retour
                </button>
                <button
                  type="button"
                  onClick={handleSubmit}
                  disabled={isProcessing || !currentPassword || !newPassword || !confirmPassword}
                  className="px-4 py-2 text-sm font-medium text-white bg-purple-600 rounded-lg hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 disabled:opacity-50"
                >
                  {isProcessing ? 'Modification en cours...' : 'Confirmer'}
                </button>
              </div>
            </div>
          )}

          {step === 'logout' && (
            <div className={`text-center py-6 transition-all duration-300 ${
              isAnimating ? 'opacity-0 scale-95' : 'opacity-100 scale-100'
            }`}>
              <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-purple-100">
                <LogOut className="h-6 w-6 text-purple-600" />
              </div>
              <h3 className="mt-4 text-lg font-medium text-gray-900">
                Déconnexion en cours...
              </h3>
              <p className="mt-2 text-sm text-gray-500">
                Pour votre sécurité, vous allez être déconnecté dans {countdown} secondes
              </p>
              <div className="mt-4 relative h-2 bg-gray-200 rounded-full overflow-hidden">
                <div 
                  className="absolute top-0 left-0 h-full bg-purple-600 transition-all duration-100 ease-linear"
                  style={{ width: `${progress}%` }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PasswordChangeModal;