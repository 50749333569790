import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { storage } from '../lib/firebase';
import { MediaUploadError } from './mediaUpload.error';
import { generateSlug } from '../lib/firebase';

interface UploadResult {
  url: string;
  path: string;
}

export class MediaUploadService {
  private static validateFile(file: File, maxSize: number = 5 * 1024 * 1024): void {
    if (file.size > maxSize) {
      throw new MediaUploadError(`Le fichier est trop volumineux (max ${maxSize / 1024 / 1024}MB)`);
    }

    if (!file.type.startsWith('image/')) {
      throw new MediaUploadError('Le fichier doit être une image');
    }

    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    const allowedExtensions = ['jpg', 'jpeg', 'png', 'gif', 'webp'];
    
    if (!fileExtension || !allowedExtensions.includes(fileExtension)) {
      throw new MediaUploadError('Format de fichier non supporté');
    }
  }

  private static async uploadFile(file: File, path: string): Promise<UploadResult> {
    try {
      const storageRef = ref(storage, path);
      const snapshot = await uploadBytes(storageRef, file);
      const url = await getDownloadURL(snapshot.ref);

      return {
        url,
        path: snapshot.ref.fullPath
      };
    } catch (error) {
      console.error('Upload error:', error);
      throw new MediaUploadError('Erreur lors du téléchargement');
    }
  }

  static async uploadCompanyLogo(companyName: string, file: File): Promise<UploadResult> {
    this.validateFile(file, 2 * 1024 * 1024); // 2MB limit for logos

    const companySlug = generateSlug(companyName);
    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    const path = `companies/${companySlug}/logo/${Date.now()}.${fileExtension}`;

    return this.uploadFile(file, path);
  }

  static async uploadWineImage(companyName: string, cuveeId: string, file: File): Promise<UploadResult> {
    this.validateFile(file, 5 * 1024 * 1024); // 5MB limit for wine images

    const companySlug = generateSlug(companyName);
    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    const path = `companies/${companySlug}/cuvees/${cuveeId}/${Date.now()}.${fileExtension}`;

    return this.uploadFile(file, path);
  }

  static async deleteFile(path: string): Promise<void> {
    try {
      const storageRef = ref(storage, path);
      await deleteObject(storageRef);
    } catch (error) {
      console.error('Delete error:', error);
      throw new MediaUploadError('Erreur lors de la suppression');
    }
  }
}