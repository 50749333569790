import { useState, useEffect } from 'react';
import { collection, query, where, onSnapshot, doc, updateDoc, orderBy, serverTimestamp, arrayUnion } from 'firebase/firestore';
import { db } from '../lib/firebase';
import type { Ticket } from '../types/ticket';

export const useTickets = (userId: string | undefined, isAdmin: boolean = false) => {
  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [loading, setLoading] = useState(true);
  const [unreadCount, setUnreadCount] = useState(0);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!userId && !isAdmin) {
      setLoading(false);
      return;
    }

    const ticketsRef = collection(db, 'tickets');
    const q = isAdmin 
      ? query(ticketsRef, orderBy('updatedAt', 'desc'))
      : query(ticketsRef, where('userId', '==', userId), orderBy('updatedAt', 'desc'));

    const unsubscribe = onSnapshot(q, 
      (snapshot) => {
        const ticketsData = snapshot.docs.map(doc => {
          const data = doc.data();
          const lastReadTimestamp = data.lastReadTimestamp || {};
          const readerKey = isAdmin ? 'admin' : userId;
          const lastRead = lastReadTimestamp[readerKey] || '1970-01-01T00:00:00.000Z';

          // Calculer les messages non lus
          const unreadMessages = data.messages.filter((msg: any) => {
            const messageDate = new Date(msg.createdAt);
            const lastReadDate = new Date(lastRead);
            return isAdmin 
              ? (!msg.isAdmin && messageDate > lastReadDate)
              : (msg.isAdmin && messageDate > lastReadDate);
          }).length;

          return {
            id: doc.id,
            ...data,
            unreadMessages
          };
        }) as Ticket[];

        // Calculer le total des messages non lus
        const totalUnread = ticketsData.reduce((acc, ticket) => 
          acc + (ticket.unreadMessages || 0), 0
        );

        setTickets(ticketsData);
        setUnreadCount(totalUnread);
        setError(null);
        setLoading(false);
      },
      (err) => {
        console.error('Error fetching tickets:', err);
        setError('Une erreur est survenue lors du chargement des tickets');
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [userId, isAdmin]);

  const markTicketAsRead = async (ticketId: string) => {
    if (!userId && !isAdmin) return;

    try {
      const ticketRef = doc(db, 'tickets', ticketId);
      const updateData = {
        [`lastReadTimestamp.${isAdmin ? 'admin' : userId}`]: new Date().toISOString()
      };
      await updateDoc(ticketRef, updateData);
    } catch (error) {
      console.error('Error marking ticket as read:', error);
      throw new Error('Erreur lors du marquage du ticket comme lu');
    }
  };

  const sendMessage = async (ticketId: string, content: string) => {
    if (!userId && !isAdmin) return;

    try {
      const ticketRef = doc(db, 'tickets', ticketId);
      const ticket = tickets.find(t => t.id === ticketId);
      
      if (!ticket) {
        throw new Error('Ticket non trouvé');
      }

      const message = {
        id: Date.now().toString(),
        content,
        createdAt: new Date().toISOString(),
        userId: isAdmin ? 'admin' : userId,
        userName: isAdmin ? 'Support' : ticket.userName,
        isAdmin
      };

      const updateData = {
        messages: arrayUnion(message),
        updatedAt: new Date().toISOString(),
        status: ticket.status === 'open' ? 'in_progress' : ticket.status,
        lastReadTimestamp: {
          ...(ticket.lastReadTimestamp || {}),
          [isAdmin ? 'admin' : userId]: new Date().toISOString()
        }
      };

      await updateDoc(ticketRef, updateData);
    } catch (error) {
      console.error('Error sending message:', error);
      throw error;
    }
  };

  return {
    tickets,
    loading,
    error,
    unreadCount,
    markTicketAsRead,
    sendMessage
  };
};