import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { X } from 'lucide-react';
import { PagePublisher } from '../services/pagePublisher';
import PublishedCuveePreview from '../components/PublishedCuveePreview';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from '../lib/firebase';

const CuveePreview = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [previewData, setPreviewData] = useState<any>(null);
  const [viewMode, setViewMode] = useState<'desktop' | 'mobile'>('desktop');

  useEffect(() => {
    const loadPreviewData = async () => {
      if (!id) return;

      try {
        setLoading(true);
        setError(null);

        const cuveeDoc = await getDoc(doc(db, 'cuvees', id));
        if (!cuveeDoc.exists()) {
          throw new Error('Cuvée non trouvée');
        }
        const cuvee = { id: cuveeDoc.id, ...cuveeDoc.data() };

        const design = await PagePublisher.getUserDesign(cuvee.userId);
        const companyInfo = await PagePublisher.getUserCompanyInfo(cuvee.userId);

        // Récupérer les URLs des images depuis Firebase Storage
        if (companyInfo.logoPath) {
          try {
            const logoRef = ref(storage, companyInfo.logoPath);
            const logoUrl = await getDownloadURL(logoRef);
            companyInfo.logo = logoUrl;
          } catch (err) {
            console.error('Error loading company logo:', err);
          }
        }

        if (cuvee.photoPath) {
          try {
            const photoRef = ref(storage, cuvee.photoPath);
            const photoUrl = await getDownloadURL(photoRef);
            cuvee.photo = photoUrl;
          } catch (err) {
            console.error('Error loading cuvee photo:', err);
          }
        }

        const pageSlug = PagePublisher.generatePageSlug(cuvee.name);
        const companySlug = PagePublisher.generatePageSlug(companyInfo.name);

        setPreviewData({
          fullUrl: `${companySlug}/${pageSlug}`,
          content: {
            cuvee,
            design,
            company: companyInfo
          }
        });
      } catch (err: any) {
        console.error('Error loading preview data:', err);
        setError(err.message || 'Erreur lors du chargement de l\'aperçu');
      } finally {
        setLoading(false);
      }
    };

    loadPreviewData();
  }, [id]);

  return (
    <div className="relative min-h-screen bg-gray-100">
      <button
        onClick={() => navigate(-1)}
        className="fixed top-4 right-4 z-50 rounded-full p-2 bg-white shadow-sm hover:bg-gray-50 transition-colors"
      >
        <X className="h-5 w-5 text-gray-500" />
      </button>

      <div className={`mx-auto ${viewMode === 'mobile' ? 'max-w-[390px]' : ''}`}>
        {loading ? (
          <div className="flex items-center justify-center min-h-screen">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-purple-600"></div>
          </div>
        ) : error ? (
          <div className="flex items-center justify-center min-h-screen">
            <div className="text-center max-w-md mx-auto px-4">
              <p className="text-red-600">{error}</p>
              <button
                onClick={() => navigate(-1)}
                className="mt-4 btn-secondary"
              >
                Retour
              </button>
            </div>
          </div>
        ) : previewData ? (
          <div className={viewMode === 'mobile' ? 'shadow-lg rounded-lg overflow-hidden my-4' : ''}>
            <PublishedCuveePreview 
              page={previewData} 
              showPublicLink={true}
              viewMode={viewMode}
              onViewModeChange={setViewMode}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default CuveePreview;