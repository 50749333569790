import React, { useState, useEffect } from 'react';
import { useAuthContext } from '../contexts/AuthContext';
import { doc, onSnapshot, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { 
  Palette, 
  Type, 
  Eye, 
  Clock, 
  Lock, 
  ArrowRight,
  Instagram,
  Facebook,
  Youtube,
  Share2
} from 'lucide-react';
import Toast, { ToastType } from '../components/Toast';
import { Link } from 'react-router-dom';

const fontOptions = [
  { value: 'Inter', label: 'Inter' },
  { value: 'Roboto', label: 'Roboto' },
  { value: 'Playfair Display', label: 'Playfair Display' },
  { value: 'Montserrat', label: 'Montserrat' },
  { value: 'Lora', label: 'Lora' }
];

const colorFields = [
  { key: 'primary', label: 'Primaire' },
  { key: 'secondary', label: 'Secondaire' },
  { key: 'text', label: 'Texte' },
  { key: 'background', label: 'Arrière-plan' },
  { key: 'boxText', label: 'Texte des boîtes' }
];

const displayFields = [
  { key: 'showCompanyName', label: 'Nom de l\'entreprise' },
  { key: 'showLogo', label: 'Logo' }
];

const Design: React.FC = () => {
  const { user } = useAuthContext();
  const [settings, setSettings] = useState({
    colors: {
      primary: '#7e22ce',
      secondary: '#9333ea',
      text: '#1f2937',
      background: '#ffffff',
      boxText: '#ffffff'
    },
    typography: {
      headingFont: 'Inter',
      bodyFont: 'Inter'
    },
    socials: {
      instagram: {
        enabled: false,
        url: ''
      },
      facebook: {
        enabled: false,
        url: ''
      },
      youtube: {
        enabled: false,
        url: ''
      },
      pinterest: {
        enabled: false,
        url: ''
      }
    },
    display: {
      showCompanyName: true,
      showLogo: true
    }
  });
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [toast, setToast] = useState<{ message: string; type: ToastType } | null>(null);
  const [userStatus, setUserStatus] = useState<string>('active');
  const [userPlan, setUserPlan] = useState<'basic' | 'standard' | 'premium'>('basic');

  useEffect(() => {
    const fetchUserData = async () => {
      if (!user?.uid) return;
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setUserStatus(userData.status || 'active');
        setUserPlan(userData.plan || 'basic');
      }
    };
    fetchUserData();
  }, [user]);

  useEffect(() => {
    if (!user?.uid) return;

    const defaultSettings = {
      colors: {
        primary: '#7e22ce',
        secondary: '#9333ea',
        text: '#1f2937',
        background: '#ffffff',
        boxText: '#ffffff'
      },
      typography: {
        headingFont: 'Inter',
        bodyFont: 'Inter'
      },
      socials: {
        instagram: {
          enabled: false,
          url: ''
        },
        facebook: {
          enabled: false,
          url: ''
        },
        youtube: {
          enabled: false,
          url: ''
        },
        pinterest: {
          enabled: false,
          url: ''
        }
      },
      display: {
        showFooter: true,
        showCompanyName: true,
        showLogo: true,
        showCertifications: true
      }
    };

    const unsubscribe = onSnapshot(doc(db, 'users', user.uid), (doc) => {
      if (doc.exists() && doc.data().design) {
        // Merge existing settings with default settings to ensure all properties exist
        setSettings({
          ...defaultSettings,
          ...doc.data().design,
          socials: {
            ...defaultSettings.socials,
            ...(doc.data().design.socials || {})
          }
        });
      } else {
        // If no design settings exist, use defaults
        setSettings(defaultSettings);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [user]);

  const showToast = (message: string, type: ToastType) => {
    setToast({ message, type });
  };

  const handleSave = async () => {
    if (!user?.uid) return;

    try {
      setSaving(true);
      await updateDoc(doc(db, 'users', user.uid), {
        design: settings,
        designUpdatedAt: new Date().toISOString()
      });
      showToast('Modifications enregistrées avec succès', 'success');
    } catch (err) {
      console.error('Error saving design settings:', err);
      showToast('Erreur lors de la sauvegarde des modifications', 'error');
    } finally {
      setSaving(false);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-purple-600"></div>
      </div>
    );
  }

  const isBasicPlan = userPlan === 'basic';

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 space-y-8">
      {toast && (
        <Toast
          message={toast.message}
          type={toast.type}
          onClose={() => setToast(null)}
        />
      )}

      <div>
        <h1 className="text-2xl font-bold text-gray-900">Design</h1>
        <p className="mt-1 text-sm text-gray-600">
          Personnalisez l'apparence de vos pages de cuvées
        </p>
      </div>

      {userStatus === 'pending_deletion' && (
        <div className="bg-red-50 border-l-4 border-red-400 p-4 rounded-lg">
          <div className="flex">
            <div className="flex-shrink-0">
              <Clock className="h-5 w-5 text-red-400" />
            </div>
            <div className="ml-3">
              <p className="text-sm text-red-700">
                Votre compte est en cours de suppression. La personnalisation du design est désactivée.
              </p>
            </div>
          </div>
        </div>
      )}

      {isBasicPlan && (
        <div className="bg-purple-50 border border-purple-100 rounded-lg p-6">
          <div className="flex items-start space-x-3">
            <div className="flex-shrink-0">
              <Lock className="h-5 w-5 text-purple-600" />
            </div>
            <div>
              <h4 className="text-sm font-medium text-gray-900">
                Personnalisation avancée
              </h4>
              <p className="mt-1 text-sm text-gray-500">
                Passez au plan Standard ou supérieur pour personnaliser l'apparence de vos pages de cuvées avec :
              </p>
              <ul className="mt-4 space-y-2">
                <li className="flex items-center text-sm text-gray-600">
                  <div className="h-2 w-2 rounded-full bg-purple-600 mr-2"></div>
                  Couleurs personnalisées
                </li>
                <li className="flex items-center text-sm text-gray-600">
                  <div className="h-2 w-2 rounded-full bg-purple-600 mr-2"></div>
                  Polices de caractères au choix
                </li>
                <li className="flex items-center text-sm text-gray-600">
                  <div className="h-2 w-2 rounded-full bg-purple-600 mr-2"></div>
                  Personnalisation du pied de page
                </li>
                <li className="flex items-center text-sm text-gray-600">
                  <div className="h-2 w-2 rounded-full bg-purple-600 mr-2"></div>
                  Options d'affichage avancées
                </li>
              </ul>
              <Link
                to="/subscription"
                className="mt-4 inline-flex items-center text-purple-600 hover:text-purple-700"
              >
                Passer au plan Standard
                <ArrowRight className="ml-2 h-4 w-4" />
              </Link>
            </div>
          </div>
        </div>
      )}

      <div className={`grid grid-cols-1 lg:grid-cols-2 gap-8 ${isBasicPlan ? 'opacity-50 pointer-events-none' : ''}`}>
        {/* Colors Section */}
        <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6">
          <div className="flex items-center space-x-2 mb-6">
            <Palette className="h-5 w-5 text-gray-400" />
            <h2 className="text-lg font-medium text-gray-900">Couleurs</h2>
          </div>

          <div className="space-y-4">
            {colorFields.map(({ key, label }) => (
              <div key={key}>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  {label}
                </label>
                <div className="flex items-center space-x-2">
                  <div className="relative flex-shrink-0">
                    <input
                      type="color"
                      value={settings.colors[key as keyof typeof settings.colors]}
                      onChange={(e) => setSettings({
                        ...settings,
                        colors: { ...settings.colors, [key]: e.target.value }
                      })}
                      className="sr-only"
                    />
                    <div 
                      className="w-10 h-10 rounded-lg border border-gray-300 cursor-pointer"
                      style={{ backgroundColor: settings.colors[key as keyof typeof settings.colors] }}
                      onClick={() => document.getElementById(`color-${key}`)?.click()}
                    />
                  </div>
                  <input
                    type="text"
                    value={settings.colors[key as keyof typeof settings.colors]}
                    onChange={(e) => setSettings({
                      ...settings,
                      colors: { ...settings.colors, [key]: e.target.value }
                    })}
                    className="mt-1 block w-full pl-11 pr-3 py-3 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Typography Section */}
        <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6">
          <div className="flex items-center space-x-2 mb-6">
            <Type className="h-5 w-5 text-gray-400" />
            <h2 className="text-lg font-medium text-gray-900">Typographie</h2>
          </div>

          <div className="space-y-4">
            {Object.entries(settings.typography).map(([key, value]) => (
              <div key={key}>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  {key === 'headingFont' ? 'Police des titres' : 'Police du texte'}
                </label>
                <select
                  value={value}
                  onChange={(e) => setSettings({
                    ...settings,
                    typography: { ...settings.typography, [key]: e.target.value }
                  })}
                  className="mt-1 block w-full pl-11 pr-3 py-3 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                >
                  {fontOptions.map((font) => (
                    <option key={font.value} value={font.value}>{font.label}</option>
                  ))}
                </select>
              </div>
            ))}
          </div>
        </div>

        {/* Display Options */}
        <div className="bg-white rounded-lg shadow-sm border border-gray-200 p-6">
          <div className="flex items-center space-x-2 mb-6">
            <Eye className="h-5 w-5 text-gray-400" />
            <h2 className="text-lg font-medium text-gray-900">Affichage</h2>
          </div>

          <div className="space-y-4">
            {displayFields.map(({ key, label }) => (
              <div key={key} className="flex items-center justify-between">
                <label className="text-sm font-medium text-gray-700">
                  {label}
                </label>
                <button
                  onClick={() => setSettings({
                    ...settings,
                    display: { ...settings.display, [key]: !settings.display[key as keyof typeof settings.display] }
                  })}
                  className={`relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2 ${
                    settings.display[key as keyof typeof settings.display] ? 'bg-purple-600' : 'bg-gray-200'
                  }`}
                >
                  <span
                    className={`pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out ${
                      settings.display[key as keyof typeof settings.display] ? 'translate-x-5' : 'translate-x-0'
                    }`}
                  />
                </button>
              </div>
            ))}
          </div>
        </div>

        {/* Footer Section */}
      </div>

      <div className="flex justify-end">
        <button
          onClick={handleSave}
          disabled={saving || isBasicPlan}
          className="btn"
        >
          {saving ? 'Enregistrement...' : 'Enregistrer les modifications'}
        </button>
      </div>
    </div>
  );
};

export default Design;