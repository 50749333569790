import { useEffect, useRef } from 'react';
import { doc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../lib/firebase';

export const useUserActivity = (userId: string | undefined) => {
  const timeoutRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    if (!userId) return;

    const updateLastSeen = async () => {
      try {
        const userRef = doc(db, 'users', userId);
        await updateDoc(userRef, {
          lastSeenAt: serverTimestamp()
        });
      } catch (error) {
        console.error('Error updating lastSeenAt:', error);
      }
    };

    const handleActivity = () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(updateLastSeen, 1000);
    };

    // Mettre à jour immédiatement au montage
    updateLastSeen();

    // Écouter les événements d'activité
    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);
    window.addEventListener('click', handleActivity);
    window.addEventListener('scroll', handleActivity);
    window.addEventListener('visibilitychange', () => {
      if (!document.hidden) {
        handleActivity();
      }
    });

    // Mettre à jour toutes les minutes pour maintenir le statut "en ligne"
    const interval = setInterval(updateLastSeen, 60000);

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      clearInterval(interval);
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
      window.removeEventListener('click', handleActivity);
      window.removeEventListener('scroll', handleActivity);
      window.removeEventListener('visibilitychange', handleActivity);
    };
  }, [userId]);
};