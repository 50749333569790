import React, { useState, useEffect } from 'react';
import { useAuthContext } from '../contexts/AuthContext';
import { doc, onSnapshot, updateDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { CreditCard } from 'lucide-react';
import { STRIPE_PRODUCTS, createCheckoutSession } from '../lib/stripe';
import Toast, { ToastType } from '../components/Toast';
import { Crown } from 'lucide-react';
import ChangePlanModal from '../components/ChangePlanModal';
import BillingDetails from '../components/BillingDetails';

const Subscription: React.FC = () => {
  const { user } = useAuthContext();
  const [userProfile, setUserProfile] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [showChangePlanModal, setShowChangePlanModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<string | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [toast, setToast] = useState<{ message: string; type: ToastType } | null>(null);

  useEffect(() => {
    if (!user?.uid) return;

    const unsubscribe = onSnapshot(doc(db, 'users', user.uid), (doc) => {
      if (doc.exists()) {
        setUserProfile(doc.data());
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [user]);

  const showToast = (message: string, type: ToastType) => {
    setToast({ message, type });
  };

  const handlePlanChange = (plan: string) => {
    if (plan === userProfile?.plan) return;
    setSelectedPlan(plan);
    setShowChangePlanModal(true);
  };

  const handleConfirmPlanChange = async () => {
    if (!user || !selectedPlan) return;

    try {
      setIsProcessing(true);
      const userRef = doc(db, 'users', user.uid);

      // Si on passe au plan Basic, on met à jour directement
      if (selectedPlan === 'basic') {
        await updateDoc(userRef, {
          plan: 'basic',
          subscription: {
            status: 'inactive',
            canceledAt: new Date().toISOString()
          }
        });
        showToast('Votre plan a été mis à jour avec succès', 'success');
        setShowChangePlanModal(false);
        return;
      }

      // Pour les plans payants, créer la session Stripe
      const { url } = await createCheckoutSession({
        plan: selectedPlan as 'standard' | 'premium',
        email: user.email || '',
        successUrl: `${window.location.origin}/subscription?success=true`,
        cancelUrl: `${window.location.origin}/subscription`,
        customerData: {
          firstName: userProfile.firstName,
          lastName: userProfile.lastName,
          companyName: userProfile.company?.name
        }
      });

      // Rediriger vers la page de paiement Stripe
      window.location.href = url;
    } catch (error) {
      console.error('Error upgrading plan:', error);
      showToast('Une erreur est survenue lors du changement de plan', 'error');
    } finally {
      setIsProcessing(false);
      setShowChangePlanModal(false);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-purple-600"></div>
      </div>
    );
  }

  const isGoldPlan = userProfile?.plan === 'gold';

  const renderGoldBanner = () => (
    <div className="bg-gradient-to-r from-amber-100 to-yellow-100 rounded-lg shadow-xl p-8 mb-8 relative overflow-hidden">
      <div className="absolute top-0 right-0 w-64 h-64 bg-gradient-to-br from-yellow-200 to-amber-300 rounded-full transform translate-x-32 -translate-y-32 opacity-50" />
      
      <div className="relative z-10">
        <div className="flex items-center space-x-3 mb-4">
          <Crown className="h-8 w-8 text-yellow-600" />
          <h2 className="text-2xl font-bold text-yellow-900">Plan Gold Partenaire</h2>
        </div>
        
        <div className="prose prose-yellow max-w-none">
          <p className="text-yellow-800">
            En tant que partenaire privilégié, vous bénéficiez de notre offre Gold exclusive jusqu'au 1er janvier 2035.
            Cette offre vous donne accès à l'ensemble de nos fonctionnalités premium :
          </p>
          
          <ul className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-3">
            <li className="flex items-center text-yellow-800">
              <span className="w-2 h-2 bg-yellow-500 rounded-full mr-2"></span>
              Cuvées illimitées
            </li>
            <li className="flex items-center text-yellow-800">
              <span className="w-2 h-2 bg-yellow-500 rounded-full mr-2"></span>
              Design personnalisé
            </li>
            <li className="flex items-center text-yellow-800">
              <span className="w-2 h-2 bg-yellow-500 rounded-full mr-2"></span>
              QR codes avancés
            </li>
            <li className="flex items-center text-yellow-800">
              <span className="w-2 h-2 bg-yellow-500 rounded-full mr-2"></span>
              Support prioritaire
            </li>
          </ul>

          <div className="mt-6 p-4 bg-yellow-50 rounded-lg border border-yellow-200">
            <p className="text-sm text-yellow-800">
              <strong>Note importante :</strong> À l'issue de cette période, vous devrez choisir l'une de nos offres 
              standard en fonction de vos besoins. Notre équipe vous contactera en amont pour vous accompagner 
              dans cette transition.
            </p>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 space-y-8">
      {toast && (
        <Toast
          message={toast.message}
          type={toast.type}
          onClose={() => setToast(null)}
        />
      )}

      <div>
        <h1 className="text-2xl font-bold text-gray-900">Abonnement</h1>
        <p className="mt-1 text-sm text-gray-600">
          Gérez votre abonnement et vos informations de facturation
        </p>
      </div>

      {isGoldPlan && renderGoldBanner()}

      {/* Plans */}
      {!isGoldPlan && <div className="grid grid-cols-1 gap-6 lg:grid-cols-3">
        {Object.entries(STRIPE_PRODUCTS)
          .filter(([_, plan]) => !plan.partnerOnly)
          .map(([key, plan]) => (
          <div
            key={key}
            className={`bg-white rounded-lg shadow-sm border-2 p-6 ${
              userProfile?.plan === key
                ? 'border-purple-500'
                : 'border-gray-200'
            }`}
          >
            <div className="flex justify-between items-start">
              <div>
                <h3 className="text-lg font-semibold text-gray-900">{plan.name}</h3>
                <p className="mt-4 text-2xl font-bold text-gray-900">
                  {key === 'basic' ? (
                    <>
                      Gratuit
                      <span className="block text-sm font-normal text-gray-500">
                        Frais de création uniques : 10€
                      </span>
                    </>
                  ) : (
                    `${key === 'standard' ? '348' : '648'}€/an`
                  )}
                </p>
              </div>
              {userProfile?.plan === key && (
                <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-purple-100 text-purple-800">
                  Plan actuel
                </span>
              )}
            </div>

            <p className="mt-2 text-sm text-gray-500">{plan.description}</p>

            <ul className="mt-6 space-y-4">
              {plan.features.map((feature, index) => (
                <li key={index} className="flex items-start">
                  <svg className="h-5 w-5 text-green-500 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                  </svg>
                  <span className="text-sm text-gray-600">{feature}</span>
                </li>
              ))}
            </ul>

            <button
              onClick={() => handlePlanChange(key)}
              disabled={userProfile?.plan === key}
              className={`mt-8 w-full btn ${
                userProfile?.plan === key
                  ? 'opacity-50 cursor-not-allowed'
                  : ''
              }`}
            >
              {userProfile?.plan === key ? 'Plan actuel' : 'Passer au plan ' + plan.name}
            </button>
          </div>
        ))}
      </div>}

      {/* Billing Details */}
      <BillingDetails 
        subscription={userProfile?.subscription || {}}
        plan={userProfile?.plan || 'basic'}
      />

      {/* Change Plan Modal */}
      <ChangePlanModal
        isOpen={showChangePlanModal}
        onClose={() => setShowChangePlanModal(false)}
        onConfirm={handleConfirmPlanChange}
        currentPlan={userProfile?.plan}
        newPlan={selectedPlan}
        subscription={userProfile?.subscription}
        isProcessing={isProcessing}
      />
    </div>
  );
};

export default Subscription;