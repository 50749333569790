import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { 
  Wine, 
  Mail, 
  Phone, 
  MapPin, 
  Lock, 
  Shield, 
  CheckCircle2,
  Globe,
  Building2,
  Trash2,
  Clock 
} from 'lucide-react';
import { useAuthContext } from '../contexts/AuthContext';
import { doc, onSnapshot, updateDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import PasswordChangeModal from '../components/PasswordChangeModal';
import EmailChangeModal from '../components/EmailChangeModal';
import TwoFactorActivationModal from '../components/TwoFactorActivationModal';
import EmailVerificationBanner from '../components/EmailVerificationBanner';
import MediaUpload from '../components/MediaUpload';
import { useMediaUpload } from '../hooks/useMediaUpload';
import Toast, { ToastType } from '../components/Toast';
import CancelDeletionBanner from '../components/CancelDeletionBanner';
import DeleteAccountModal from '../components/DeleteAccountModal';

const Settings: React.FC = () => {
  const navigate = useNavigate();
  const { user } = useAuthContext();
  const [userProfile, setUserProfile] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showTwoFactorModal, setShowTwoFactorModal] = useState(false);
  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [toast, setToast] = useState<{ message: string; type: ToastType } | null>(null);

  const { uploadCompanyLogo, isUploading, error: uploadError } = useMediaUpload();

  useEffect(() => {
    if (!user?.uid) return;

    const unsubscribe = onSnapshot(doc(db, 'users', user.uid), (doc) => {
      if (doc.exists()) {
        setUserProfile(doc.data());
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [user]);

  const showToast = (message: string, type: ToastType) => {
    setToast({ message, type });
  };

  const handleLogoChange = async (file: File) => {
    if (!user) return;

    try {
      const logoUrl = await uploadCompanyLogo(user.uid, file);
      
      const userDoc = doc(db, 'users', user.uid);
      await updateDoc(userDoc, {
        'company.logo': logoUrl
      });

      showToast('Logo mis à jour avec succès', 'success');
    } catch (err: any) {
      showToast(err.message || 'Erreur lors du téléchargement du logo', 'error');
    }
  };

  const handleLogoRemove = async () => {
    if (!user) return;

    try {
      const userDoc = doc(db, 'users', user.uid);
      await updateDoc(userDoc, {
        'company.logo': null
      });
      showToast('Logo supprimé avec succès', 'success');
    } catch (err) {
      showToast('Erreur lors de la suppression du logo', 'error');
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user?.uid || !userProfile) return;

    try {
      setIsProcessing(true);

      const formData = new FormData(e.target as HTMLFormElement);
      const updates: any = {
        firstName: formData.get('firstName'),
        lastName: formData.get('lastName'),
        phone: formData.get('phone'),
        address: {
          street: formData.get('address'),
          city: formData.get('city'),
          postalCode: formData.get('postalCode'),
          country: formData.get('country'),
        },
        company: {
          ...(userProfile.company || {}),
          website: formData.get('companyWebsite'),
          phone: formData.get('companyPhone'),
          siret: formData.get('companysiret'),
          address: {
            street: formData.get('companyAddress'),
            city: formData.get('companyCity'),
            postalCode: formData.get('companyPostalCode'),
            country: formData.get('companyCountry'),
          }
        }
      };

      await updateDoc(doc(db, 'users', user.uid), updates);
      showToast('Modifications enregistrées avec succès', 'success');
    } catch (err) {
      showToast('Erreur lors de la sauvegarde des modifications', 'error');
    } finally {
      setIsProcessing(false);
    }
  };

  const handleTwoFactorActivated = async () => {
    setShowTwoFactorModal(false);
    showToast('Authentification à deux facteurs activée avec succès', 'success');
  };

  const handleEmailChanged = () => {
    setShowEmailModal(false);
    showToast('Adresse email modifiée avec succès', 'success');
  };

  const handlePasswordChanged = async (newPassword: string) => {
    try {
      if (!user) return;
      
      await updateDoc(doc(db, 'users', user.uid), {
        passwordLastChanged: new Date().toISOString()
      });
    } catch (err) {
      showToast('Erreur lors du changement de mot de passe', 'error');
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-purple-600"></div>
      </div>
    );
  }

  if (!userProfile) {
    return (
      <div className="p-4 bg-red-50 text-red-700 rounded-lg">
        Erreur lors du chargement des données utilisateur
      </div>
    );
  }

  const isPendingDeletion = userProfile.status === 'pending_deletion';

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 space-y-8">
      {toast && (
        <Toast
          message={toast.message}
          type={toast.type}
          onClose={() => setToast(null)}
        />
      )}

      <div>
        <h1 className="text-2xl font-bold text-gray-900">Paramètres</h1>
        <p className="mt-1 text-sm text-gray-600">
          Gérez vos informations personnelles et les paramètres de votre compte
        </p>
      </div>

      {isPendingDeletion && (
        <CancelDeletionBanner
          scheduledDeletionAt={userProfile.scheduledDeletionAt}
          onCancelled={() => {
            showToast('La suppression de votre compte a été annulée', 'success');
          }}
        />
      )}

      {!userProfile.isEmailVerified && (
        <EmailVerificationBanner onVerificationSuccess={() => {}} />
      )}

      <form onSubmit={handleSubmit} className="space-y-8">
        {/* Personal Information */}
        <div className="bg-white rounded-lg shadow-sm border border-gray-200 overflow-hidden">
          <div className="px-6 py-5 border-b border-gray-200">
            <div className="flex items-center">
              <Mail className="h-5 w-5 text-gray-400 mr-3" />
              <h2 className="text-lg font-medium text-gray-900">
                Informations personnelles
              </h2>
            </div>
          </div>

          <div className="px-6 py-5 space-y-6">
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
              <div>
                <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">
                  Prénom
                </label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  defaultValue={userProfile.firstName}
                  className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 shadow-sm focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                />
              </div>

              <div>
                <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">
                  Nom
                </label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  defaultValue={userProfile.lastName}
                  className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 shadow-sm focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                />
              </div>

              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  defaultValue={userProfile.email}
                  disabled
                  className="mt-1 block w-full rounded-lg border border-gray-300 bg-gray-50 px-3 py-2 text-gray-500 shadow-sm"
                />
              </div>

              <div>
                <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                  Téléphone
                </label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  defaultValue={userProfile.phone}
                  className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 shadow-sm focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                />
              </div>
            </div>

            <div>
              <label htmlFor="address" className="block text-sm font-medium text-gray-700">
                Adresse
              </label>
              <input
                type="text"
                id="address"
                name="address"
                defaultValue={userProfile.address?.street}
                className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 shadow-sm focus:ring-2 focus:ring-purple-500 focus:border-transparent"
              />
            </div>

            <div className="grid grid-cols-1 gap-6 sm:grid-cols-3">
              <div>
                <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                  Ville
                </label>
                <input
                  type="text"
                  id="city"
                  name="city"
                  defaultValue={userProfile.address?.city}
                  className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 shadow-sm focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                />
              </div>

              <div>
                <label htmlFor="postalCode" className="block text-sm font-medium text-gray-700">
                  Code postal
                </label>
                <input
                  type="text"
                  id="postalCode"
                  name="postalCode"
                  defaultValue={userProfile.address?.postalCode}
                  className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 shadow-sm focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                />
              </div>

              <div>
                <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                  Pays
                </label>
                <input
                  type="text"
                  id="country"
                  name="country"
                  defaultValue={userProfile.address?.country}
                  className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 shadow-sm focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                />
              </div>
            </div>
          </div>
        </div>

        {/* Company Information */}
        <div className="bg-white rounded-lg shadow-sm border border-gray-200 overflow-hidden">
          <div className="px-6 py-5 border-b border-gray-200">
            <div className="flex items-center">
              <Building2 className="h-5 w-5 text-gray-400 mr-3" />
              <h2 className="text-lg font-medium text-gray-900">
                Informations entreprise
              </h2>
            </div>
          </div>

          <div className="px-6 py-5 space-y-6">
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
              <div>
                <label htmlFor="companyName" className="block text-sm font-medium text-gray-700">
                  Nom de l'entreprise
                </label>
                <input
                  type="text"
                  id="companyName"
                  name="companyName"
                  readOnly
                  value={userProfile.company?.name || ''}
                  className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 bg-gray-50 text-gray-900 shadow-sm focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                />
                <p className="mt-1 text-sm text-gray-500">
                  Pour modifier le nom de votre entreprise, veuillez contacter le support
                </p>
              </div>

              <div>
                <label htmlFor="companySlug" className="block text-sm font-medium text-gray-700">
                  Slug
                </label>
                <input
                  type="text"
                  id="companySlug"
                  name="companySlug"
                  readOnly
                  value={userProfile.company?.slug || ''}
                  className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 bg-gray-50 text-gray-900 shadow-sm focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                />
                <p className="mt-1 text-sm text-gray-500">
                  Identifiant unique de votre entreprise
                </p>
              </div>

              <div>
                <label htmlFor="companyWebsite" className="block text-sm font-medium text-gray-700">
                  Site internet
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Globe className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="url"
                    id="companyWebsite"
                    name="companyWebsite"
                    defaultValue={userProfile.company?.website}
                    placeholder="https://www.example.com"
                    className="mt-1 block w-full rounded-lg border border-gray-300 pl-10 px-3 py-2 shadow-sm focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="companyPhone" className="block text-sm font-medium text-gray-700">
                  Téléphone
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <Phone className="h-5 w-5 text-gray-400" />
                  </div>
                  <input
                    type="tel"
                    id="companyPhone"
                    name="companyPhone"
                    defaultValue={userProfile.company?.phone}
                    placeholder="+33 6 12 34 56 78"
                    className="mt-1 block w-full rounded-lg border border-gray-300 pl-10 px-3 py-2 shadow-sm focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                  />
                </div>
              </div>

              <div>
                <label htmlFor="companysiret" className="block text-sm font-medium text-gray-700">
                  Numéro SIRET
                </label>
                <input
                  type="text"
                  id="companysiret"
                  name="companysiret"
                  defaultValue={userProfile.company?.siret}
                  className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 shadow-sm focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                />
              </div>

              <div className="sm:col-span-2">
                <MediaUpload
                  label="Logo de l'entreprise"
                  onChange={handleLogoChange}
                  onRemove={handleLogoRemove}
                  preview={userProfile.company?.logo}
                  isUploading={isUploading}
                  error={uploadError}
                  maxSize={2 * 1024 * 1024}
                  description="PNG, JPG, WEBP jusqu'à 2MB"
                />
              </div>
            </div>

            <div>
              <label htmlFor="companyAddress" className="block text-sm font-medium text-gray-700">
                Adresse
              </label>
              <input
                type="text"
                id="companyAddress"
                name="companyAddress"
                defaultValue={userProfile.company?.address?.street}
                className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 shadow-sm focus:ring-2 focus:ring-purple-500 focus:border-transparent"
              />
            </div>

            <div className="grid grid-cols-1 gap-6 sm:grid-cols-3">
              <div>
                <label htmlFor="companyCity" className="block text-sm font-medium text-gray-700">
                  Ville
                </label>
                <input
                  type="text"
                  id="companyCity"
                  name="companyCity"
                  defaultValue={userProfile.company?.address?.city}
                  className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 shadow-sm focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                />
              </div>

              <div>
                <label htmlFor="companyPostalCode" className="block text-sm font-medium text-gray-700">
                  Code postal
                </label>
                <input
                  type="text"
                  id="companyPostalCode"
                  name="companyPostalCode"
                  defaultValue={userProfile.company?.address?.postalCode}
                  className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 shadow-sm focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                />
              </div>

              <div>
                <label htmlFor="companyCountry" className="block text-sm font-medium text-gray-700">
                  Pays
                </label>
                <input
                  type="text"
                  id="companyCountry"
                  name="companyCountry"
                  defaultValue={userProfile.company?.address?.country}
                  className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 shadow-sm focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                />
              </div>
            </div>
          </div>
        </div>

        {/* Security Section */}
        <div className="bg-white rounded-lg shadow-sm border border-gray-200 overflow-hidden">
          <div className="px-6 py-5 border-b border-gray-200">
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <Lock className="h-5 w-5 text-gray-400 mr-3" />
                <h2 className="text-lg font-medium text-gray-900">Sécurité</h2>
              </div>
              {userProfile.isEmailVerified && (
                <div className="flex items-center">
                  <CheckCircle2 className="h-5 w-5 text-green-500 mr-2" />
                  <span className="text-sm font-medium text-green-700">Compte vérifié</span>
                </div>
              )}
            </div>
          </div>

          <div className="px-6 py-5 space-y-6">
            <div>
              <p className="text-sm text-gray-600 mb-4">
                Protégez votre compte en utilisant un mot de passe fort et en activant l'authentification à deux facteurs.
              </p>
              
              <div className="space-y-4">
                {/* Email Change Section */}
                <div className="flex items-center justify-between">
                  <div>
                    <h3 className="text-sm font-medium text-gray-900">Adresse email</h3>
                    <p className="text-sm text-gray-500">{userProfile.email}</p>
                  </div>
                  <button
                    type="button"
                    onClick={() => setShowEmailModal(true)}
                    className="btn-secondary"
                  >
                    Modifier l'email
                  </button>
                </div>

                {/* Password Change Section */}
                <div className="flex items-center justify-between">
                  <div>
                    <h3 className="text-sm font-medium text-gray-900">Mot de passe</h3>
                    <p className="text-sm text-gray-500">
                      Dernière modification : {userProfile.passwordLastChanged ? 
                        new Date(userProfile.passwordLastChanged).toLocaleDateString('fr-FR') : 
                        'Jamais'}
                    </p>
                  </div>
                  <button
                    type="button"
                    onClick={() => setShowPasswordModal(true)}
                    className="btn-secondary"
                  >
                    Modifier le mot de passe
                  </button>
                </div>

                {/* 2FA Section */}
                <div className="border-t border-gray-100 pt-4">
                  <div className="flex items-center justify-between">
                    <div>
                      <h3 className="text-sm font-medium text-gray-900">Authentification à deux facteurs</h3>
                      <p className="text-sm text-gray-500">
                        {userProfile.twoFactorEnabled
                          ? 'L\'authentification à deux facteurs est activée'
                          : 'Ajoutez une couche de sécurité supplémentaire à votre compte'}
                      </p>
                    </div>
                    <div className="flex items-center">
                      {userProfile.twoFactorEnabled ? (
                        <div className="flex items-center text-green-700">
                          <CheckCircle2 className="h-5 w-5 mr-2" />
                          <span className="text-sm font-medium">Activée</span>
                        </div>
                      ) : (
                        <button
                          type="button"
                          onClick={() => setShowTwoFactorModal(true)}
                          disabled={!userProfile.isEmailVerified}
                          className="btn flex items-center space-x-2 disabled:opacity-50"
                        >
                          <Shield className="h-4 w-4" />
                          <span>Activer</span>
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-end">
          <button
            type="submit"
            disabled={isProcessing}
            className="btn"
          >
            {isProcessing ? 'Enregistrement...' : 'Enregistrer les modifications'}
          </button>
        </div>
      </form>

      {/* Account Deletion Section - Only show if not pending deletion */}
      {!isPendingDeletion && (
        <div className="mt-8 border-t border-gray-200 pt-8">
          <div className="flex items-center justify-between">
            <div>
              <h3 className="text-sm font-medium text-gray-900">Suppression du compte</h3>
              <p className="text-sm text-gray-500">
                Supprimer définitivement votre compte et toutes les données associées
              </p>
            </div>
            <button
              onClick={() => setShowDeleteAccountModal(true)}
              className="text-sm text-red-600 hover:text-red-700 flex items-center space-x-2"
            >
              <Trash2 className="h-4 w-4" />
              <span>Supprimer mon compte</span>
            </button>
          </div>
        </div>
      )}

      <PasswordChangeModal
        isOpen={showPasswordModal}
        onClose={() => setShowPasswordModal(false)}
        onConfirm={handlePasswordChanged}
        isProcessing={isProcessing}
      />

      <EmailChangeModal
        isOpen={showEmailModal}
        onClose={() => setShowEmailModal(false)}
        onConfirm={handleEmailChanged}
      />

      {user && (
        <TwoFactorActivationModal
          isOpen={showTwoFactorModal}
          onClose={() => setShowTwoFactorModal(false)}
          onActivated={handleTwoFactorActivated}
          userId={user.uid}
        />
      )}

      <DeleteAccountModal
        isOpen={showDeleteAccountModal}
        onClose={() => setShowDeleteAccountModal(false)}
      />
    </div>
  );
};

export default Settings;