import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { AuthProvider, useAuthContext } from './contexts/AuthContext';
import { doc, getDoc } from 'firebase/firestore';
import { db } from './lib/firebase';
import { useUserActivity } from './hooks/useUserActivity';
import Sidebar from './components/Sidebar';
import Navbar from './components/Navbar';
import UserDashboard from './pages/UserDashboard';
import CuveeList from './pages/Cuvees/CuveeList';
import CuveePreview from './pages/CuveePreview';
import QRCodeGenerator from './pages/QRCodes/QRCodeGenerator';
import Design from './pages/Design';
import Login from './pages/Login';
import Signup from './pages/Signup';
import ForgotPassword from './pages/ForgotPassword';
import VerifyEmail from './pages/VerifyEmail';
import Settings from './pages/Settings';
import Support from './pages/Support';
import Subscription from './pages/Subscription';
import PublishedCuvee from './pages/PublishedCuvee';
import Users from './pages/admin/Users';
import UserDetails from './pages/admin/UserDetails';
import AdminDashboard from './pages/admin/Dashboard';
import AdminCuvees from './pages/admin/Cuvees';
import AdminSupport from './pages/admin/Support';
import ServerLogs from './pages/admin/ServerLogs';
import ActivityLogs from './pages/ActivityLogs';
import Notifications from './pages/admin/Notifications';
import TermsOfService from './pages/Legal/TermsOfService';
import PrivacyPolicy from './pages/Legal/PrivacyPolicy';
import EmailVerificationHandler from './pages/EmailVerificationHandler';
import Upsells from './pages/Upsells';

const PrivateRoute: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { user, loading: authLoading } = useAuthContext();
  const [userPlan, setUserPlan] = useState<string>('basic');
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  
  // Utiliser le hook de suivi d'activité
  useUserActivity(user?.uid);

  useEffect(() => {
    const checkUserPlan = async () => {
      if (!user?.uid) {
        setLoading(false);
        return;
      }

      try {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          const plan = userDoc.data().plan || 'basic';
          setUserPlan(plan);
          
          // Rediriger les utilisateurs non-premium du dashboard vers /cuvees
          if (location.pathname === '/dashboard' && plan !== 'premium') {
            navigate('/cuvees');
          }
        }
      } catch (error) {
        console.error('Error checking user plan:', error);
      } finally {
        setLoading(false);
      }
    };

    checkUserPlan();
  }, [user, location.pathname, navigate]);

  if (authLoading || loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-purple-600"></div>
      </div>
    );
  }

  if (!user) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="flex h-screen bg-gray-50">
      <Sidebar />
      <div className="flex-1 flex flex-col overflow-hidden">
        <Navbar />
        <main className="flex-1 overflow-y-auto bg-gray-50 p-6">
          {children}
        </main>
      </div>
    </div>
  );
};

const DefaultRedirect: React.FC = () => {
  const { user } = useAuthContext();
  const [isAdmin, setIsAdmin] = useState<boolean | null>(null);
  const [userPlan, setUserPlan] = useState<string>('basic');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkUserStatus = async () => {
      if (!user?.uid) {
        setIsAdmin(false);
        setLoading(false);
        return;
      }

      try {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          setIsAdmin(userDoc.data().role === 'admin');
          setUserPlan(userDoc.data().plan || 'basic');
        } else {
          setIsAdmin(false);
        }
      } catch (error) {
        console.error('Error checking user status:', error);
        setIsAdmin(false);
      }
      setLoading(false);
    };

    checkUserStatus();
  }, [user]);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-purple-600"></div>
      </div>
    );
  }

  if (isAdmin) {
    return <Navigate to="/admin/dashboard" />;
  }

  // Rediriger les utilisateurs Basic vers "Mes Cuvées"
  if (userPlan === 'basic') {
    return <Navigate to="/cuvees" />;
  }

  return <Navigate to="/dashboard" />;
};

const App: React.FC = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          {/* Auth Action Handler - IMPORTANT: This must be before other routes */}
          <Route path="/__/auth/action" element={<EmailVerificationHandler />} />

          {/* Public routes */}
          <Route path="/login" element={<Login />} />
          <Route path="/signup/*" element={<Signup />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/:companySlug/:pageSlug" element={<PublishedCuvee />} />
          <Route path="/terms" element={<TermsOfService />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />

          {/* Protected routes */}
          <Route path="/" element={
            <PrivateRoute>
              <DefaultRedirect />
            </PrivateRoute>
          } />
          <Route path="/dashboard" element={
            <PrivateRoute>
              <UserDashboard />
            </PrivateRoute>
          } />
          <Route path="/activity" element={
            <PrivateRoute>
              <ActivityLogs />
            </PrivateRoute>
          } />
          <Route path="/admin/dashboard" element={
            <PrivateRoute>
              <AdminDashboard />
            </PrivateRoute>
          } />
          <Route path="/admin/cuvees" element={
            <PrivateRoute>
              <AdminCuvees />
            </PrivateRoute>
          } />
          <Route path="/admin/support" element={
            <PrivateRoute>
              <AdminSupport />
            </PrivateRoute>
          } />
          <Route path="/admin/notifications" element={
            <PrivateRoute>
              <Notifications />
            </PrivateRoute>
          } />
          <Route path="/admin/logs" element={
            <PrivateRoute>
              <ServerLogs />
            </PrivateRoute>
          } />
          <Route path="/users" element={
            <PrivateRoute>
              <Users />
            </PrivateRoute>
          } />
          <Route path="/users/:userId" element={
            <PrivateRoute>
              <UserDetails />
            </PrivateRoute>
          } />
          <Route path="/cuvees" element={
            <PrivateRoute>
              <CuveeList />
            </PrivateRoute>
          } />
          <Route path="/preview/:id" element={
            <PrivateRoute>
              <CuveePreview />
            </PrivateRoute>
          } />
          <Route path="/qr-codes" element={
            <PrivateRoute>
              <QRCodeGenerator />
            </PrivateRoute>
          } />
          <Route path="/design" element={
            <PrivateRoute>
              <Design />
            </PrivateRoute>
          } />
          <Route path="/support" element={
            <PrivateRoute>
              <Support />
            </PrivateRoute>
          } />
          <Route path="/settings" element={
            <PrivateRoute>
              <Settings />
            </PrivateRoute>
          } />
          <Route path="/subscription" element={
            <PrivateRoute>
              <Subscription />
            </PrivateRoute>
          } />
          <Route path="/upsells" element={
            <PrivateRoute>
              <Upsells />
            </PrivateRoute>
          } />

          {/* Default redirect */}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;