import React, { useState, useEffect } from 'react';
import { Wine, Mail, AlertTriangle, Loader2, CheckCircle2, Lock } from 'lucide-react';
import { useNavigate, Link } from 'react-router-dom';
import { sendPasswordResetEmail, verifyResetCode } from '../lib/firebase';
import ReCaptcha from '../components/ReCaptcha';

type Step = 'email' | 'verification' | 'success';

const ForgotPassword: React.FC = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [step, setStep] = useState<Step>('email');
  const [verificationCode, setVerificationCode] = useState(['', '', '', '', '', '']);
  const [isSendingCode, setIsSendingCode] = useState(false);
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (step === 'success') {
      timer = setTimeout(() => {
        navigate('/login');
      }, 5000);
    }
    return () => clearTimeout(timer);
  }, [step, navigate]);

  const handleEmailSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!captchaToken) {
      setError('Veuillez valider le captcha');
      return;
    }

    try {
      setLoading(true);
      setError(null);
      
      await sendPasswordResetEmail(email);
      setStep('verification');
      setTimeout(() => {
        const firstInput = document.getElementById('reset-code-0');
        if (firstInput) firstInput.focus();
      }, 100);
    } catch (err: any) {
      setError(err.message || 'Une erreur est survenue');
    } finally {
      setLoading(false);
    }
  };

  const handleCodeChange = (index: number, value: string) => {
    if (!/^\d*$/.test(value)) return;

    const newCode = [...verificationCode];
    newCode[index] = value;
    setVerificationCode(newCode);

    if (value && index < 5) {
      const nextInput = document.getElementById(`reset-code-${index + 1}`);
      if (nextInput) nextInput.focus();
    }

    if (value && index === 5) {
      verifyCode(newCode.join(''));
    }
  };

  const handleKeyDown = (index: number, e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace' && !verificationCode[index] && index > 0) {
      const prevInput = document.getElementById(`reset-code-${index - 1}`);
      if (prevInput) {
        prevInput.focus();
        const newCode = [...verificationCode];
        newCode[index - 1] = '';
        setVerificationCode(newCode);
      }
    }
  };

  const handlePaste = (e: React.ClipboardEvent) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData('text');
    const numbers = pastedData.replace(/\D/g, '').split('').slice(0, 6);
    
    const newCode = [...verificationCode];
    numbers.forEach((num, index) => {
      if (index < 6) newCode[index] = num;
    });
    setVerificationCode(newCode);

    if (numbers.length === 6) {
      verifyCode(numbers.join(''));
    }
  };

  const verifyCode = async (code: string) => {
    try {
      setLoading(true);
      setError(null);

      const result = await verifyResetCode(code);
      
      if (result.success) {
        await sendPasswordResetEmail(email);
        setStep('success');
      } else {
        setError('Code invalide');
        setVerificationCode(['', '', '', '', '', '']);
        const firstInput = document.getElementById('reset-code-0');
        if (firstInput) firstInput.focus();
      }
    } catch (err: any) {
      setError(err.message || 'Une erreur est survenue');
    } finally {
      setLoading(false);
    }
  };

  const handleResendCode = async () => {
    try {
      setIsSendingCode(true);
      setError(null);
      await sendPasswordResetEmail(email);
    } catch (err: any) {
      setError(err.message || 'Une erreur est survenue');
    } finally {
      setIsSendingCode(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-700 to-purple-900 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full bg-white rounded-xl shadow-2xl p-8">
        <div className="text-center">
          <div className="flex justify-center">
            <Wine className="h-12 w-12 text-purple-700" />
          </div>
          <h2 className="mt-4 text-3xl font-extrabold text-gray-900">
            Mot de passe oublié
          </h2>
          <p className="mt-2 text-sm text-gray-600">
            {step === 'email' && "Entrez votre email pour réinitialiser votre mot de passe"}
            {step === 'verification' && "Entrez le code de vérification reçu par email"}
            {step === 'success' && "Instructions envoyées !"}
          </p>
        </div>

        {error && (
          <div className="mt-4 p-4 bg-red-50 border border-red-200 text-red-700 rounded-lg text-sm">
            {error}
          </div>
        )}

        {step === 'email' && (
          <form className="mt-8 space-y-6" onSubmit={handleEmailSubmit}>
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-2">
                Adresse email
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Mail className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  id="email"
                  name="email"
                  type="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="appearance-none block w-full pl-11 pr-3 py-3 border border-gray-300 rounded-lg placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                  placeholder="vous@exemple.fr"
                />
              </div>
            </div>

            <ReCaptcha
              onChange={setCaptchaToken}
              className="flex justify-center"
            />

            <div className="space-y-4">
              <button
                type="submit"
                disabled={loading || !captchaToken}
                className="w-full flex justify-center py-3 px-4 border border-transparent text-sm font-medium rounded-lg text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {loading ? 'Envoi en cours...' : 'Envoyer les instructions'}
              </button>

              <Link
                to="/login"
                className="block text-center text-sm text-gray-600 hover:text-gray-900"
              >
                Retour à la connexion
              </Link>
            </div>
          </form>
        )}

        {step === 'verification' && (
          <div className="mt-8 space-y-6">
            <div className="text-center">
              <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-purple-100">
                <Lock className="h-6 w-6 text-purple-600" />
              </div>
              <h3 className="mt-4 text-lg font-medium text-gray-900">
                Vérification requise
              </h3>
              <p className="mt-2 text-sm text-gray-500">
                Entrez le code à 6 chiffres envoyé à {email}
              </p>
            </div>

            <div className="flex justify-center space-x-2">
              {verificationCode.map((digit, index) => (
                <input
                  key={index}
                  id={`reset-code-${index}`}
                  type="text"
                  maxLength={1}
                  value={digit}
                  onChange={(e) => handleCodeChange(index, e.target.value)}
                  onKeyDown={(e) => handleKeyDown(index, e)}
                  onPaste={handlePaste}
                  className="w-12 h-12 text-center text-xl font-semibold border-2 border-gray-300 rounded-lg focus:border-purple-500 focus:ring-purple-500"
                  disabled={loading}
                />
              ))}
            </div>

            <div className="flex justify-center">
              <button
                onClick={handleResendCode}
                disabled={isSendingCode || loading}
                className="text-sm text-purple-600 hover:text-purple-500"
              >
                {isSendingCode ? 'Envoi en cours...' : 'Renvoyer le code'}
              </button>
            </div>
          </div>
        )}

        {step === 'success' && (
          <div className="mt-8 text-center">
            <div className="mx-auto flex h-20 w-20 items-center justify-center rounded-full bg-green-100">
              <CheckCircle2 className="h-12 w-12 text-green-500" />
            </div>
            
            <h3 className="mt-6 text-xl font-medium text-gray-900">
              Instructions envoyées !
            </h3>
            
            <div className="mt-4 space-y-4">
              <p className="text-sm text-gray-600">
                Un email contenant les instructions pour réinitialiser votre mot de passe a été envoyé à {email}.
              </p>

              <div className="inline-flex items-center justify-center px-4 py-2 bg-green-50 rounded-full">
                <span className="text-sm text-green-700">
                  Redirection dans {5} secondes...
                </span>
              </div>

              <div className="relative h-2 bg-gray-200 rounded-full overflow-hidden">
                <div 
                  className="absolute top-0 left-0 h-full bg-green-500 transition-all duration-[5000ms] ease-linear w-0"
                  style={{ animation: 'progress 5s linear forwards' }}
                />
              </div>

              <div>
                <Link
                  to="/login"
                  className="text-sm font-medium text-purple-600 hover:text-purple-500 transition duration-150 ease-in-out"
                >
                  Se connecter maintenant
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;