import React, { forwardRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { RECAPTCHA_SITE_KEY } from '../lib/config';

interface ReCaptchaProps {
  onChange: (token: string | null) => void;
  className?: string;
}

const ReCaptcha = forwardRef<ReCAPTCHA, ReCaptchaProps>(({ onChange, className = '' }, ref) => {
  return (
    <div className={className}>
      <ReCAPTCHA
        ref={ref}
        sitekey={RECAPTCHA_SITE_KEY}
        onChange={onChange}
        theme="light"
        size="normal"
      />
    </div>
  );
});

ReCaptcha.displayName = 'ReCaptcha';

export default ReCaptcha;