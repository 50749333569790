import React, { useState, useEffect } from 'react';
import { Camera, HelpCircle } from 'lucide-react';
import type { CuveeFormData } from '../CuveeForm';
import { useMediaUpload } from '../../hooks/useMediaUpload';
import MediaUpload from '../MediaUpload';
import Toast, { ToastType } from '../../components/Toast';
import { checkCuveeNameExists } from '../../hooks/useCuvees';
import {
  AOC_BY_REGION,
  IGP_LIST,
  WINE_TYPES,
  WINE_CATEGORIES,
  BOTTLE_COLORS,
  QUALITY_LABELS
} from '../../constants/wineData';

interface CuveeWineInfoProps {
  data: CuveeFormData;
  onChange: (updates: Partial<CuveeFormData>) => void;
  userId?: string;
  onNameError: (error: string | null) => void;
}

const CuveeWineInfo: React.FC<CuveeWineInfoProps> = ({ 
  data, 
  onChange, 
  userId,
  onNameError
}) => {
  const [selectedRegion, setSelectedRegion] = useState(data.region || '');
  const [selectedAppellationType, setSelectedAppellationType] = useState('');
  const [selectedAOC, setSelectedAOC] = useState(data.appellation || '');
  const [selectedIGP, setSelectedIGP] = useState(data.appellation || '');
  const [vdpName, setVdpName] = useState(data.appellation || '');
  const [customAppellation, setCustomAppellation] = useState(data.appellation || '');
  const [toast, setToast] = useState<{ message: string; type: ToastType } | null>(null);
  const [isCheckingName, setIsCheckingName] = useState(false);
  const [nameError, setNameError] = useState<string | null>(null);

  const { uploadWineImage, isUploading, error: uploadError } = useMediaUpload();

  const showToast = (message: string, type: ToastType) => {
    setToast({ message, type });
  };

  const handleNameBlur = async (e: React.FocusEvent<HTMLInputElement>) => {
    const name = e.target.value.trim();
    if (!userId || data.id || !name) return;

    setIsCheckingName(true);
    try {
      const exists = await checkCuveeNameExists(userId, name);
      if (exists) {
        const error = 'Une cuvée avec ce nom existe déjà';
        setNameError(error);
        onNameError(error);
      } else {
        setNameError(null);
        onNameError(null);
      }
    } catch (err) {
      const error = 'Erreur lors de la vérification du nom';
      setNameError(error);
      onNameError(error);
    } finally {
      setIsCheckingName(false);
    }
  };

  const handleImageChange = async (file: File) => {
    if (!userId) {
      showToast('Erreur : utilisateur non authentifié', 'error');
      return;
    }

    try {
      const tempId = data.id || `temp-${Date.now()}`;
      const imageUrl = await uploadWineImage(userId, tempId, file);
      onChange({ photo: imageUrl });
      showToast('Image téléchargée avec succès', 'success');
    } catch (err: any) {
      showToast(err.message || 'Erreur lors du téléchargement de l\'image', 'error');
    }
  };

  const handleImageRemove = () => {
    onChange({ photo: undefined });
  };

  return (
    <div className="space-y-6">
      {toast && (
        <Toast
          message={toast.message}
          type={toast.type}
          onClose={() => setToast(null)}
        />
      )}

      <MediaUpload
        label="Photo de votre cuvée"
        onChange={handleImageChange}
        onRemove={handleImageRemove}
        preview={typeof data.photo === 'string' ? data.photo : undefined}
        isUploading={isUploading}
        error={uploadError}
        maxSize={5 * 1024 * 1024}
        description="PNG, JPG, WEBP jusqu'à 5MB"
      />

      <div className="bg-green-50 p-6 rounded-lg space-y-4">
        <h3 className="text-lg font-medium text-gray-900">
          Région viticole / type de vin
        </h3>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Région viticole
          </label>
          <select
            value={selectedRegion}
            onChange={(e) => {
              setSelectedRegion(e.target.value);
              onChange({ region: e.target.value });
              setSelectedAOC('');
            }}
            className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 shadow-sm focus:border-purple-500 focus:outline-none focus:ring-purple-500"
          >
            <option value="">Sélectionner une région</option>
            {Object.keys(AOC_BY_REGION).map((region) => (
              <option key={region} value={region}>
                {region}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Type d'Appellation
          </label>
          <div className="mt-2 flex flex-wrap gap-4">
            <label className="inline-flex items-center">
              <input
                type="radio"
                value="AOC"
                checked={selectedAppellationType === 'AOC'}
                onChange={(e) => setSelectedAppellationType(e.target.value)}
                className="form-radio text-purple-600"
              />
              <span className="ml-2">AOC</span>
            </label>
            <label className="inline-flex items-center">
              <input
                type="radio"
                value="IGP"
                checked={selectedAppellationType === 'IGP'}
                onChange={(e) => setSelectedAppellationType(e.target.value)}
                className="form-radio text-purple-600"
              />
              <span className="ml-2">IGP (liste)</span>
            </label>
            <label className="inline-flex items-center">
              <input
                type="radio"
                value="VDP"
                checked={selectedAppellationType === 'VDP'}
                onChange={(e) => setSelectedAppellationType(e.target.value)}
                className="form-radio text-purple-600"
              />
              <span className="ml-2">VDP</span>
            </label>
            <label className="inline-flex items-center">
              <input
                type="radio"
                value="Sans"
                checked={selectedAppellationType === 'Sans'}
                onChange={(e) => setSelectedAppellationType(e.target.value)}
                className="form-radio text-purple-600"
              />
              <span className="ml-2">Sans</span>
            </label>
          </div>
        </div>

        {selectedAppellationType === 'AOC' && selectedRegion && (
          <div>
            <label className="block text-sm font-medium text-gray-700">
              AOC
            </label>
            <select
              value={selectedAOC}
              onChange={(e) => {
                setSelectedAOC(e.target.value);
                onChange({ appellation: e.target.value });
              }}
              className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 shadow-sm focus:border-purple-500 focus:outline-none focus:ring-purple-500"
            >
              <option value="">Sélectionnez une AOC</option>
              {AOC_BY_REGION[selectedRegion as keyof typeof AOC_BY_REGION]?.map((aoc) => (
                <option key={aoc} value={aoc}>
                  {aoc}
                </option>
              ))}
            </select>
          </div>
        )}

        {selectedAppellationType === 'IGP' && (
          <div>
            <label className="block text-sm font-medium text-gray-700">
              IGP
            </label>
            <select
              value={selectedIGP}
              onChange={(e) => {
                setSelectedIGP(e.target.value);
                onChange({ appellation: e.target.value });
              }}
              className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 shadow-sm focus:border-purple-500 focus:outline-none focus:ring-purple-500"
            >
              <option value="">Sélectionnez une IGP</option>
              {IGP_LIST.map((igp) => (
                <option key={igp} value={igp}>
                  {igp}
                </option>
              ))}
            </select>
          </div>
        )}

        {selectedAppellationType === 'VDP' && (
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Nom du VDP
            </label>
            <input
              type="text"
              value={vdpName}
              onChange={(e) => {
                setVdpName(e.target.value);
                onChange({ appellation: e.target.value });
              }}
              className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 shadow-sm focus:border-purple-500 focus:outline-none focus:ring-purple-500"
              placeholder="Entrez le nom du VDP"
            />
          </div>
        )}

        {selectedAppellationType === 'Sans' && (
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Appellation
            </label>
            <input
              type="text"
              value={customAppellation}
              onChange={(e) => {
                setCustomAppellation(e.target.value);
                onChange({ appellation: e.target.value });
              }}
              className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 shadow-sm focus:border-purple-500 focus:outline-none focus:ring-purple-500"
              placeholder="Entrez l'appellation"
            />
          </div>
        )}

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Type de vin
          </label>
          <select
            value={data.type}
            onChange={(e) => onChange({ type: e.target.value })}
            className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 shadow-sm focus:border-purple-500 focus:outline-none focus:ring-purple-500"
          >
            <option value="">Sélectionnez un type</option>
            {WINE_TYPES.map((type) => (
              <option key={type} value={type}>
                {type}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">
            Catégorie (calcul énergie)
          </label>
          <select
            value={data.category}
            onChange={(e) => onChange({ category: e.target.value })}
            className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 shadow-sm focus:border-purple-500 focus:outline-none focus:ring-purple-500"
          >
            <option value="">Sélectionnez une catégorie</option>
            {WINE_CATEGORIES.map((category) => (
              <option key={category.id} value={category.id}>
                {category.label}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
        <div>
          <label htmlFor="name" className="block text-sm font-medium text-gray-700">
            Nom de la cuvée *
            {data.id && (
              <span className="ml-2 text-xs text-gray-500">
                (Le nom ne peut pas être modifié une fois la cuvée créée)
              </span>
            )}
          </label>
          <div className="relative">
            <input
              type="text"
              id="name"
              required
              value={data.name || ''}
              onChange={(e) => onChange({ name: e.target.value })}
              onBlur={handleNameBlur}
              className={`mt-1 block w-full rounded-lg border px-3 py-2 shadow-sm ${
                data.id
                  ? 'border-gray-200 bg-gray-50 text-gray-700 cursor-not-allowed'
                  : nameError
                  ? 'border-red-300 focus:border-red-500 focus:ring-red-500'
                  : data.name && !isCheckingName && !nameError
                  ? 'border-green-300 focus:border-green-500 focus:ring-green-500'
                  : 'border-gray-300 focus:border-purple-500 focus:ring-purple-500'
              }`}
              disabled={!!data.id}
            />
            {isCheckingName && (
              <div className="absolute right-3 top-3">
                <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-purple-600"></div>
              </div>
            )}
          </div>
          {!data.id && data.name && !isCheckingName && (
            <p className={`mt-2 text-sm ${nameError ? 'text-red-600' : 'text-green-600'}`}>
              {nameError || 'Ce nom de cuvée est disponible'}
            </p>
          )}
        </div>

        <div>
          <label htmlFor="blend" className="block text-sm font-medium text-gray-700">
            Assemblage / Composition
          </label>
          <textarea
            id="blend"
            value={data.blend || ''}
            onChange={(e) => onChange({ blend: e.target.value })}
            rows={3}
            className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 shadow-sm focus:border-purple-500 focus:outline-none focus:ring-purple-500"
          />
        </div>

        <div>
          <label htmlFor="volume" className="block text-sm font-medium text-gray-700">
            Volume (ml)
          </label>
          <input
            type="number"
            id="volume"
            value={data.volume || 750}
            onChange={(e) => onChange({ volume: parseInt(e.target.value) })}
            step="1"
            min="0"
            className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 shadow-sm focus:border-purple-500 focus:outline-none focus:ring-purple-500"
          />
        </div>

        <div>
          <label htmlFor="alcoholContent" className="block text-sm font-medium text-gray-700">
            Volume d'alcool %/Vol *
          </label>
          <input
            type="number"
            id="alcoholContent"
            required
            value={data.alcoholContent || ''}
            onChange={(e) => onChange({ alcoholContent: parseFloat(e.target.value) })}
            step="0.1"
            min="0"
            max="100"
            className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 shadow-sm focus:border-purple-500 focus:outline-none focus:ring-purple-500"
          />
        </div>

        <div>
          <label htmlFor="lotNumber" className="block text-sm font-medium text-gray-700">
            N° de lot
          </label>
          <input
            type="text"
            id="lotNumber"
            value={data.lotNumber || ''}
            onChange={(e) => onChange({ lotNumber: e.target.value })}
            className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 shadow-sm focus:border-purple-500 focus:outline-none focus:ring-purple-500"
          />
        </div>

        <div>
          <label htmlFor="vintage" className="block text-sm font-medium text-gray-700">
            Millésime *
          </label>
          <input
            type="number"
            id="vintage"
            required
            value={data.vintage || ''}
            onChange={(e) => onChange({ vintage: parseInt(e.target.value) })}
            min="1900"
            max={new Date().getFullYear() + 2}
            className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 shadow-sm focus:border-purple-500 focus:outline-none focus:ring-purple-500"
          />
        </div>

        <div>
          <label htmlFor="bottlingDate" className="block text-sm font-medium text-gray-700">
            Date de mise en bouteille
          </label>
          <input
            type="date"
            id="bottlingDate"
            value={data.bottlingDate || ''}
            onChange={(e) => onChange({ bottlingDate: e.target.value })}
            className="mt-1 block w-full rounded-lg border border-gray-300 px-3 py-2 shadow-sm focus:border-purple-500 focus:outline-none focus:ring-purple-500"
          />
        </div>
      </div>
    </div>
  );
};

export default CuveeWineInfo;