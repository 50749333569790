import { format, isValid, parseISO } from 'date-fns';
import { fr } from 'date-fns/locale';

export const formatDateTime = (dateInput: any): string => {
  try {
    let date: Date;

    // Handle Firestore Timestamp
    if (dateInput && typeof dateInput === 'object' && 'seconds' in dateInput) {
      date = new Date(dateInput.seconds * 1000);
    }
    // Handle ISO string
    else if (typeof dateInput === 'string') {
      date = parseISO(dateInput);
    }
    // Handle Date object
    else if (dateInput instanceof Date) {
      date = dateInput;
    }
    // Handle invalid input
    else {
      return 'Date invalide';
    }

    // Verify date is valid before formatting
    if (!isValid(date)) {
      return 'Date invalide';
    }

    return format(date, 'dd/MM/yyyy HH:mm', { locale: fr });
  } catch (error) {
    console.error('Error formatting date:', error);
    return 'Date invalide';
  }
};

export const formatTime = (dateInput: any): string => {
  try {
    let date: Date;

    // Handle Firestore Timestamp
    if (dateInput && typeof dateInput === 'object' && 'seconds' in dateInput) {
      date = new Date(dateInput.seconds * 1000);
    }
    // Handle ISO string
    else if (typeof dateInput === 'string') {
      date = parseISO(dateInput);
    }
    // Handle Date object
    else if (dateInput instanceof Date) {
      date = dateInput;
    }
    // Handle invalid input
    else {
      return '--:--';
    }

    // Verify date is valid before formatting
    if (!isValid(date)) {
      return '--:--';
    }

    return format(date, 'HH:mm', { locale: fr });
  } catch (error) {
    console.error('Error formatting time:', error);
    return '--:--';
  }
};