import React, { useState, useEffect, useRef } from 'react';
import { Wine, Mail, Lock, Eye, EyeOff, AlertCircle, Clock } from 'lucide-react';
import { useAuthContext } from '../contexts/AuthContext';
import { useNavigate, Link } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db, sendTwoFactorCode } from '../lib/firebase';
import { logAntispamBlock } from '../lib/serverLogs';
import TwoFactorAuthModal from '../components/TwoFactorAuthModal';
import BlockedUserModal from '../components/BlockedUserModal';
import ReCaptcha from '../components/ReCaptcha';
import ReCAPTCHA from 'react-google-recaptcha';

const ANTISPAM_ENABLED = import.meta.env.VITE_ANTISPAM_ENABLE === 'true';

const Login: React.FC = () => {
  const navigate = useNavigate();
  const { login } = useAuthContext();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showTwoFactorAuth, setShowTwoFactorAuth] = useState(false);
  const [showBlockedModal, setShowBlockedModal] = useState(false);
  const [tempUserCredentials, setTempUserCredentials] = useState<any>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [loginAttempts, setLoginAttempts] = useState(0);
  const [lastAttemptTime, setLastAttemptTime] = useState(0);
  const [cooldownEndTime, setCooldownEndTime] = useState(0);
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);
  const [remainingTime, setRemainingTime] = useState<string>('');
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const MAX_ATTEMPTS = 5;
  const COOLDOWN_DURATION = 10 * 60 * 1000; // 10 minutes

  useEffect(() => {
    const storedCooldown = localStorage.getItem('loginCooldownEndTime');
    if (storedCooldown) {
      const endTime = parseInt(storedCooldown);
      if (endTime > Date.now()) {
        setCooldownEndTime(endTime);
      } else {
        localStorage.removeItem('loginCooldownEndTime');
      }
    }
  }, []);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (cooldownEndTime > Date.now()) {
      timer = setInterval(() => {
        const remaining = cooldownEndTime - Date.now();
        if (remaining <= 0) {
          setCooldownEndTime(0);
          localStorage.removeItem('loginCooldownEndTime');
          setLoginAttempts(0);
          setRemainingTime('');
        } else {
          const minutes = Math.floor(remaining / 60000);
          const seconds = Math.floor((remaining % 60000) / 1000);
          setRemainingTime(`${minutes}:${seconds.toString().padStart(2, '0')}`);
        }
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [cooldownEndTime]);

  const resetCaptcha = () => {
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
      setCaptchaToken(null);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (ANTISPAM_ENABLED && cooldownEndTime > Date.now()) {
      return;
    }

    if (!captchaToken && loginAttempts > 0) {
      setError('Veuillez valider le captcha');
      return;
    }

    try {
      setIsLoading(true);
      setError(null);

      const userCredential = await login(email, password);
      const userDoc = await getDoc(doc(db, 'users', userCredential.user.uid));
      
      if (!userDoc.exists()) {
        throw new Error('Utilisateur non trouvé');
      }

      const userData = userDoc.data();

      if (userData.status === 'suspended') {
        setShowBlockedModal(true);
        return;
      }

      // Réinitialiser les tentatives après une connexion réussie
      setLoginAttempts(0);
      setLastAttemptTime(0);
      localStorage.removeItem('loginCooldownEndTime');

      if (userData.twoFactorEnabled) {
        const result = await sendTwoFactorCode(userCredential.user.uid);
        if (!result.success) {
          throw new Error('Erreur lors de l\'envoi du code 2FA');
        }

        setTempUserCredentials({ userId: userCredential.user.uid });
        setShowTwoFactorAuth(true);
      } else {
        navigate('/');
      }
    } catch (err: any) {
      // Gérer les tentatives de connexion uniquement si l'antispam est activé
      if (ANTISPAM_ENABLED) {
        const newAttempts = loginAttempts + 1;
        setLoginAttempts(newAttempts);
        setLastAttemptTime(Date.now());

        if (newAttempts >= MAX_ATTEMPTS) {
          const endTime = Date.now() + COOLDOWN_DURATION;
          setCooldownEndTime(endTime);
          localStorage.setItem('loginCooldownEndTime', endTime.toString());
          setError(`Trop de tentatives. Compte temporairement bloqué pour 10 minutes.`);
          
          // Log the antispam block
          await logAntispamBlock(email, newAttempts);
        }
      }

      setError(err.message === 'Firebase: Error (auth/invalid-credential).' ? 
        'Email ou mot de passe incorrect' : 
        err.message || 'Une erreur est survenue lors de la connexion'
      );

      resetCaptcha();
    } finally {
      setIsLoading(false);
    }
  };

  const handleTwoFactorVerified = () => {
    navigate('/');
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-purple-700 to-purple-900 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full bg-white rounded-xl shadow-2xl p-8">
        <div className="text-center">
          <div className="flex justify-center">
            <Wine className="h-12 w-12 text-purple-700" />
          </div>
          <h2 className="mt-4 text-3xl font-extrabold text-gray-900">
            Bienvenue sur Qvee
          </h2>
          <p className="mt-2 text-sm text-gray-600">
            Connectez-vous pour gérer vos cuvées
          </p>
        </div>

        <form onSubmit={handleSubmit} className="mt-8 space-y-6">
          {error && (
            <div className="flex items-center p-4 text-sm text-red-800 rounded-lg bg-red-50 border border-red-200" role="alert">
              <AlertCircle className="flex-shrink-0 w-4 h-4 mr-2" />
              <span className="font-medium">{error}</span>
            </div>
          )}

          <div className="space-y-4">
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-2">
                Adresse email
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Mail className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  id="email"
                  name="email"
                  type="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="appearance-none block w-full pl-11 pr-3 py-3 border border-gray-300 rounded-lg placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                  placeholder="vous@exemple.fr"
                  disabled={cooldownEndTime > Date.now()}
                />
              </div>
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-2">
                Mot de passe
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Lock className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  id="password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="appearance-none block w-full pl-11 pr-12 py-3 border border-gray-300 rounded-lg placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                  placeholder="••••••••"
                  disabled={cooldownEndTime > Date.now()}
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute inset-y-0 right-0 pr-3 flex items-center"
                  disabled={cooldownEndTime > Date.now()}
                >
                  {showPassword ? (
                    <EyeOff className="h-5 w-5 text-gray-400 hover:text-gray-600" />
                  ) : (
                    <Eye className="h-5 w-5 text-gray-400 hover:text-gray-600" />
                  )}
                </button>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <input
                id="remember-me"
                name="remember-me"
                type="checkbox"
                checked={rememberMe}
                onChange={(e) => setRememberMe(e.target.checked)}
                className="h-4 w-4 text-purple-600 focus:ring-purple-500 border-gray-300 rounded"
                disabled={cooldownEndTime > Date.now()}
              />
              <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-700">
                Se souvenir de moi
              </label>
            </div>

            <Link 
              to="/forgot-password"
              className="text-sm font-medium text-purple-600 hover:text-purple-500"
            >
              Mot de passe oublié ?
            </Link>
          </div>

          {cooldownEndTime > Date.now() ? (
            <div className="bg-red-50 border border-red-200 rounded-lg p-4 text-center">
              <Clock className="h-6 w-6 text-red-500 mx-auto mb-2" />
              <p className="text-red-700 font-medium">Compte temporairement bloqué</p>
              <p className="text-sm text-red-600 mt-1">
                Trop de tentatives de connexion. Réessayez dans {remainingTime}
              </p>
            </div>
          ) : (
            <>
              {loginAttempts > 0 && (
                <ReCaptcha
                  onChange={setCaptchaToken}
                  className="flex justify-center"
                  ref={recaptchaRef}
                />
              )}

              <button
                type="submit"
                disabled={isLoading || (loginAttempts > 0 && !captchaToken)}
                className="w-full flex justify-center py-3 px-4 border border-transparent text-sm font-medium rounded-lg text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {isLoading ? 'Connexion...' : 'Se connecter'}
              </button>
            </>
          )}

          <div className="text-center space-y-4">
            <p className="text-sm text-gray-600">
              Pas encore de compte ?{' '}
              <Link 
                to="/signup"
                className="font-medium text-purple-600 hover:text-purple-500"
              >
                S'inscrire
              </Link>
            </p>

            <div className="text-xs text-gray-500 space-x-4">
              <Link 
                to="/terms"
                className="hover:text-purple-600"
              >
                Conditions Générales d'Utilisation
              </Link>
              <span>•</span>
              <Link 
                to="/privacy"
                className="hover:text-purple-600"
              >
                Politique de Confidentialité & Remboursement
              </Link>
            </div>
          </div>
        </form>

        {tempUserCredentials?.userId && (
          <TwoFactorAuthModal
            isOpen={showTwoFactorAuth}
            userId={tempUserCredentials.userId}
            onVerified={handleTwoFactorVerified}
            onCancel={() => setShowTwoFactorAuth(false)}
            skipInitialCodeSend={true}
          />
        )}

        <BlockedUserModal
          isOpen={showBlockedModal}
          onClose={() => setShowBlockedModal(false)}
        />
      </div>
    </div>
  );
};

export default Login;