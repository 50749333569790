import React from 'react';
import { Settings, CreditCard, MessageCircle, AlertTriangle } from 'lucide-react';

interface TicketCategoryIconProps {
  category: string;
  className?: string;
}

export const TicketCategoryIcon: React.FC<TicketCategoryIconProps> = ({ category, className = "h-5 w-5" }) => {
  switch (category) {
    case 'technical':
      return <Settings className={`${className} text-amber-600`} />;
    case 'billing':
      return <CreditCard className={`${className} text-blue-600`} />;
    case 'general':
      return <MessageCircle className={`${className} text-gray-600`} />;
    case 'bug':
      return <AlertTriangle className={`${className} text-orange-600`} />;
    default:
      return <MessageCircle className={`${className} text-gray-600`} />;
  }
};

export const getCategoryLabel = (category: string): string => {
  switch (category) {
    case 'technical':
      return 'Technique';
    case 'billing':
      return 'Facturation';
    case 'general':
      return 'Général';
    case 'bug':
      return 'Bug';
    default:
      return 'Général';
  }
};