import { useState, useEffect } from 'react';
import { 
  User,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  reload
} from 'firebase/auth';
import { doc, setDoc, getDoc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { auth, db } from '../lib/firebase';
import { logAuthentication } from '../lib/serverLogs';

export interface AuthState {
  user: User | null;
  loading: boolean;
}

export const useAuth = () => {
  const [authState, setAuthState] = useState<AuthState>({
    user: null,
    loading: true,
  });

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setAuthState((prev) => ({
        ...prev,
        user,
        loading: false,
      }));
    });

    return () => unsubscribe();
  }, []);

  const refreshUser = async () => {
    if (auth.currentUser) {
      await reload(auth.currentUser);
      setAuthState(prev => ({
        ...prev,
        user: auth.currentUser
      }));
    }
  };

  const login = async (email: string, password: string) => {
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      
      const userDoc = await getDoc(doc(db, 'users', userCredential.user.uid));
      if (!userDoc.exists()) {
        await signOut(auth);
        throw new Error('Compte utilisateur non trouvé');
      }

      const userData = userDoc.data();
      if (userData.email.toLowerCase() !== email.toLowerCase()) {
        await signOut(auth);
        throw new Error('Email ou mot de passe incorrect');
      }

      // Mettre à jour les timestamps d'activité
      await updateDoc(doc(db, 'users', userCredential.user.uid), {
        lastLoginAt: serverTimestamp(),
        lastSeenAt: serverTimestamp(),
        isNewUser: false
      });

      await logAuthentication(
        userCredential.user.uid,
        email,
        'Connexion'
      );

      setAuthState((prev) => ({
        ...prev,
        user: userCredential.user,
        loading: false,
      }));

      return userCredential;
    } catch (error) {
      console.error('Error during login:', error);
      throw error;
    }
  };

  const logout = async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        const userData = userDoc.data();
        await logAuthentication(
          user.uid,
          userData?.email,
          'Déconnexion'
        );

        // Mettre à jour lastSeenAt à null lors de la déconnexion
        await updateDoc(doc(db, 'users', user.uid), {
          lastSeenAt: null
        });
      }

      await signOut(auth);
      setAuthState({ user: null, loading: false });
    } catch (error) {
      console.error('Error during logout:', error);
      throw error;
    }
  };

  return {
    user: authState.user,
    loading: authState.loading,
    login,
    logout,
    refreshUser
  };
};