import React, { useState, useEffect } from 'react';
import { 
  Wine, 
  Calendar, 
  Star, 
  Building2,
  Globe,
  MapPin,
  Phone,
  AlertTriangle,
  ArrowLeft,
  CreditCard,
  Ban,
  Unlock,
  Clock,
  Mail,
  CheckCircle2,
  QrCode,
  BarChart3,
  Trash2,
  Shield,
  KeyRound,
  Activity
} from 'lucide-react';
import { useParams, useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc, collection, query, where, getDocs, deleteDoc } from 'firebase/firestore';
import { db, storage } from '../../lib/firebase';
import { format, parseISO } from 'date-fns';
import { fr } from 'date-fns/locale';
import { ref, listAll, deleteObject } from 'firebase/storage';
import Toast, { ToastType } from '../../components/Toast';
import ModifySubscriptionModal from '../../components/admin/ModifySubscriptionModal';
import SuspendUserModal from '../../components/admin/SuspendUserModal';
import UnblockUserModal from '../../components/admin/UnblockUserModal';
import DeleteUserModal from '../../components/admin/DeleteUserModal';
import { STRIPE_PRODUCTS } from '../../lib/stripe';

const getUserStatus = (lastLoginAt?: string | { seconds: number }, lastSeenAt?: string | { seconds: number }) => {
  if (!lastLoginAt && !lastSeenAt) return 'offline';
  
  const now = new Date();
  let lastSeenDate: Date | null = null;
  let lastLoginDate: Date | null = null;

  // Handle lastSeenAt
  if (lastSeenAt) {
    if (typeof lastSeenAt === 'object' && 'seconds' in lastSeenAt) {
      lastSeenDate = new Date(lastSeenAt.seconds * 1000);
    } else {
      lastSeenDate = new Date(lastSeenAt);
    }
  }

  // Handle lastLoginAt
  if (lastLoginAt) {
    if (typeof lastLoginAt === 'object' && 'seconds' in lastLoginAt) {
      lastLoginDate = new Date(lastLoginAt.seconds * 1000);
    } else {
      lastLoginDate = new Date(lastLoginAt);
    }
  }
  
  const mostRecentActivity = lastSeenDate || lastLoginDate;
  if (!mostRecentActivity || isNaN(mostRecentActivity.getTime())) return 'offline';

  const minutesSinceActivity = Math.floor((now.getTime() - mostRecentActivity.getTime()) / (1000 * 60));
  
  if (minutesSinceActivity <= 5) return 'online';
  if (minutesSinceActivity <= 60) return 'away';
  return 'offline';
};

const StatusIndicator: React.FC<{ status: string }> = ({ status }) => {
  let bgColor = '';
  let title = '';

  switch (status) {
    case 'online':
      bgColor = 'bg-green-500';
      title = 'En ligne';
      break;
    case 'away':
      bgColor = 'bg-orange-500';
      title = 'Inactif depuis moins d\'1h';
      break;
    case 'offline':
      bgColor = 'bg-red-500';
      title = 'Hors ligne';
      break;
  }

  return (
    <div 
      className={`h-3 w-3 rounded-full ${bgColor} ring-2 ring-white`}
      title={title}
    />
  );
};

interface UserData {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  status: string;
  isEmailVerified: boolean;
  twoFactorEnabled: boolean;
  company?: {
    name: string;
    website?: string;
    phone?: string;
    address?: {
      street?: string;
      city?: string;
      postalCode?: string;
      country?: string;
    };
  };
  plan: string;
  subscription?: {
    status: string;
    currentPeriodEnd?: string;
  };
  createdAt: string;
  lastLoginAt: string | { seconds: number };
  lastSeenAt?: string | { seconds: number };
  scheduledDeletionAt?: string;
}

const UserDetails: React.FC = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState<UserData | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [showSuspendModal, setShowSuspendModal] = useState(false);
  const [showUnblockModal, setShowUnblockModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDisable2FAConfirm, setShowDisable2FAConfirm] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [toast, setToast] = useState<{ message: string; type: ToastType } | null>(null);
  const [stats, setStats] = useState({
    totalCuvees: 0,
    totalQRCodes: 0
  });

  useEffect(() => {
    const fetchUserData = async () => {
      if (!userId) {
        setError('ID utilisateur manquant');
        setLoading(false);
        return;
      }

      try {
        setLoading(true);
        setError(null);

        const userDoc = await getDoc(doc(db, 'users', userId));
        
        if (!userDoc.exists()) {
          setError('Utilisateur non trouvé');
          return;
        }

        // Récupérer les statistiques des cuvées
        const cuveesRef = collection(db, 'cuvees');
        const cuveesQuery = query(cuveesRef, where('userId', '==', userId));
        const cuveesSnapshot = await getDocs(cuveesQuery);

        // Récupérer les statistiques des QR codes
        const qrCodesRef = collection(db, 'qrCodes');
        const qrCodesQuery = query(qrCodesRef, where('userId', '==', userId));
        const qrCodesSnapshot = await getDocs(qrCodesQuery);

        setStats({
          totalCuvees: cuveesSnapshot.size,
          totalQRCodes: qrCodesSnapshot.size
        });

        const userData = userDoc.data();
        setUser({ 
          id: userDoc.id, 
          firstName: userData.firstName || '',
          lastName: userData.lastName || '',
          email: userData.email || '',
          status: userData.status || 'active',
          isEmailVerified: userData.isEmailVerified || false,
          twoFactorEnabled: userData.twoFactorEnabled || false,
          company: userData.company || {},
          plan: userData.plan || 'basic',
          subscription: userData.subscription || {},
          createdAt: userData.createdAt || new Date().toISOString(),
          lastLoginAt: userData.lastLoginAt || new Date().toISOString(),
          lastSeenAt: userData.lastSeenAt,
          scheduledDeletionAt: userData.scheduledDeletionAt
        });
      } catch (err) {
        console.error('Error fetching user:', err);
        setError('Erreur lors du chargement des données');
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [userId]);

  const showToast = (message: string, type: ToastType) => {
    setToast({ message, type });
  };

  const handleDisable2FA = async () => {
    if (!user) return;

    try {
      setIsProcessing(true);
      const userRef = doc(db, 'users', user.id);
      await updateDoc(userRef, {
        twoFactorEnabled: false,
        twoFactorDisabledAt: new Date().toISOString()
      });

      setUser(prev => prev ? {
        ...prev,
        twoFactorEnabled: false
      } : null);

      showToast('Authentification à deux facteurs désactivée avec succès', 'success');
      setShowDisable2FAConfirm(false);
    } catch (error) {
      console.error('Error disabling 2FA:', error);
      showToast('Erreur lors de la désactivation de la 2FA', 'error');
    } finally {
      setIsProcessing(false);
    }
  };

  const handleSubscriptionChange = async (newPlan: string) => {
    if (!user) return;

    try {
      setIsProcessing(true);
      
      await updateDoc(doc(db, 'users', user.id), {
        plan: newPlan,
        planUpdatedAt: new Date().toISOString()
      });

      setUser(prev => prev ? { ...prev, plan: newPlan } : null);
      showToast('Abonnement modifié avec succès', 'success');
      setShowSubscriptionModal(false);
    } catch (error) {
      console.error('Error updating subscription:', error);
      showToast('Erreur lors de la modification de l\'abonnement', 'error');
    } finally {
      setIsProcessing(false);
    }
  };

  const handleSuspend = async () => {
    if (!user) return;

    try {
      setIsProcessing(true);
      
      await updateDoc(doc(db, 'users', user.id), {
        status: 'suspended',
        suspendedAt: new Date().toISOString()
      });

      setUser(prev => prev ? { ...prev, status: 'suspended' } : null);
      showToast('Utilisateur bloqué avec succès', 'success');
      setShowSuspendModal(false);
    } catch (error) {
      console.error('Error suspending user:', error);
      showToast('Erreur lors du blocage de l\'utilisateur', 'error');
    } finally {
      setIsProcessing(false);
    }
  };

  const handleUnblock = async () => {
    if (!user) return;

    try {
      setIsProcessing(true);
      
      await updateDoc(doc(db, 'users', user.id), {
        status: 'active',
        suspendedAt: null
      });

      setUser(prev => prev ? { ...prev, status: 'active' } : null);
      showToast('Utilisateur débloqué avec succès', 'success');
      setShowUnblockModal(false);
    } catch (error) {
      console.error('Error unblocking user:', error);
      showToast('Erreur lors du déblocage de l\'utilisateur', 'error');
    } finally {
      setIsProcessing(false);
    }
  };

  const deleteStorageFolder = async (path: string) => {
    try {
      const folderRef = ref(storage, path);
      const filesList = await listAll(folderRef);
      
      // Supprimer tous les fichiers dans le dossier
      const deletePromises = filesList.items.map(fileRef => deleteObject(fileRef));
      await Promise.all(deletePromises);
      
      // Supprimer récursivement les sous-dossiers
      const subFolderPromises = filesList.prefixes.map(prefix => deleteStorageFolder(prefix.fullPath));
      await Promise.all(subFolderPromises);
    } catch (error) {
      console.error(`Error deleting storage folder ${path}:`, error);
      // Continue with deletion even if some files fail
    }
  };

  const handleDelete = async () => {
    if (!user) return;

    try {
      setIsProcessing(true);

      // 1. Supprimer les fichiers de stockage
      if (user.company?.name) {
        const companySlug = user.company.name.toLowerCase().replace(/[^a-z0-9]+/g, '-');
        await deleteStorageFolder(`companies/${companySlug}`);
      }

      // 2. Supprimer les cuvées et leurs pages publiées
      const cuveesRef = collection(db, 'cuvees');
      const cuveesQuery = query(cuveesRef, where('userId', '==', user.id));
      const cuveesSnapshot = await getDocs(cuveesQuery);
      
      // Supprimer les pages publiées associées aux cuvées
      const pagesRef = collection(db, 'publishedPages');
      const pagesQuery = query(pagesRef, where('userId', '==', user.id));
      const pagesSnapshot = await getDocs(pagesQuery);
      const deletePages = pagesSnapshot.docs.map(doc => deleteDoc(doc.ref));
      await Promise.all(deletePages);

      // Supprimer les cuvées
      const deleteCuvees = cuveesSnapshot.docs.map(doc => deleteDoc(doc.ref));
      await Promise.all(deleteCuvees);

      // 3. Supprimer les QR codes
      const qrCodesRef = collection(db, 'qrCodes');
      const qrCodesQuery = query(qrCodesRef, where('userId', '==', user.id));
      const qrCodesSnapshot = await getDocs(qrCodesQuery);
      const deleteQRCodes = qrCodesSnapshot.docs.map(doc => deleteDoc(doc.ref));
      await Promise.all(deleteQRCodes);

      // 4. Supprimer les logs d'activité
      const activityLogsRef = collection(db, 'activityLogs');
      const activityLogsQuery = query(activityLogsRef, where('userId', '==', user.id));
      const activityLogsSnapshot = await getDocs(activityLogsQuery);
      const deleteActivityLogs = activityLogsSnapshot.docs.map(doc => deleteDoc(doc.ref));
      await Promise.all(deleteActivityLogs);

      // 5. Supprimer les notifications
      const notificationsRef = collection(db, 'notifications');
      const notificationsQuery = query(notificationsRef, where('targetUsers', 'array-contains', user.id));
      const notificationsSnapshot = await getDocs(notificationsQuery);
      const deleteNotifications = notificationsSnapshot.docs.map(doc => deleteDoc(doc.ref));
      await Promise.all(deleteNotifications);

      // 6. Finalement, supprimer l'utilisateur
      await deleteDoc(doc(db, 'users', user.id));

      showToast('Utilisateur et toutes ses données supprimés avec succès', 'success');
      setShowDeleteModal(false);
      navigate('/users');
    } catch (error) {
      console.error('Error deleting user:', error);
      showToast('Erreur lors de la suppression de l\'utilisateur', 'error');
    } finally {
      setIsProcessing(false);
    }
  };

  const formatDate = (dateString: string | null | undefined | { seconds: number }) => {
    if (!dateString) return 'N/A';
    try {
      // Handle Firestore timestamps
      if (typeof dateString === 'object' && 'seconds' in dateString) {
        return format(new Date(dateString.seconds * 1000), 'dd MMMM yyyy', { locale: fr });
      }
      
      // Handle ISO dates
      const date = parseISO(dateString);
      if (isNaN(date.getTime())) {
        return 'N/A';
      }
      return format(date, 'dd MMMM yyyy', { locale: fr });
    } catch (error) {
      console.error('Error formatting date:', error);
      return 'N/A';
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-purple-600"></div>
      </div>
    );
  }

  if (error || !user) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-red-50 border-l-4 border-red-400 p-4 rounded-lg">
          <div className="flex">
            <div className="flex-shrink-0">
              <AlertTriangle className="h-5 w-5 text-red-400" />
            </div>
            <div className="ml-3">
              <p className="text-sm text-red-700">{error || 'Utilisateur non trouvé'}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const userStatus = getUserStatus(user.lastLoginAt, user.lastSeenAt);

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 space-y-8">
      {toast && (
        <Toast
          message={toast.message}
          type={toast.type}
          onClose={() => setToast(null)}
        />
      )}

      <div className="flex items-center justify-between">
        <button
          onClick={() => navigate('/users')}
          className="flex items-center text-gray-600 hover:text-gray-900"
        >
          <ArrowLeft className="h-5 w-5 mr-2" />
          Retour à la liste
        </button>

        <div className="flex items-center space-x-4">
          <button
            onClick={() => setShowSubscriptionModal(true)}
            className="btn-secondary flex items-center space-x-2"
            disabled={user.status === 'suspended'}
          >
            <CreditCard className="h-4 w-4" />
            <span>Modifier l'abonnement</span>
          </button>

          {user.status === 'suspended' ? (
            <button
              onClick={() => setShowUnblockModal(true)}
              className="btn bg-green-600 hover:bg-green-700 flex items-center space-x-2"
            >
              <Unlock className="h-4 w-4" />
              <span>Débloquer l'utilisateur</span>
            </button>
          ) : (
            <button
              onClick={() => setShowSuspendModal(true)}
              className="btn bg-red-600 hover:bg-red-700 flex items-center space-x-2"
            >
              <Ban className="h-4 w-4" />
              <span>Bloquer l'utilisateur</span>
            </button>
          )}

          <button
            onClick={() => setShowDeleteModal(true)}
            className="btn bg-red-600 hover:bg-red-700 flex items-center space-x-2"
          >
            <Trash2 className="h-4 w-4" />
            <span>Supprimer le compte</span>
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
        {/* User Info */}
        <div className="lg:col-span-2 space-y-6">
          <div className="bg-white rounded-lg shadow-sm p-6">
            <div className="flex items-center justify-between mb-6">
              <div>
                <h2 className="text-2xl font-bold text-gray-900">
                  {user.firstName} {user.lastName}
                </h2>
                <div className="flex items-center mt-2 space-x-4">
                  <div className="flex items-center">
                    <Mail className="h-4 w-4 text-gray-400 mr-2" />
                    <span className="text-gray-600">{user.email}</span>
                  </div>
                  {user.twoFactorEnabled && (
                    <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-green-100 text-green-800">
                      <KeyRound className="h-3 w-3 mr-1" />
                      2FA Activée
                    </span>
                  )}
                </div>
              </div>
              <div>
                {user.status === 'suspended' ? (
                  <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-gray-100 text-gray-800">
                    <Shield className="h-4 w-4 mr-1" />
                    Suspendu
                  </span>
                ) : user.status === 'pending_deletion' ? (
                  <div className="space-y-1">
                    <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-red-100 text-red-800">
                      <Clock className="h-4 w-4 mr-1" />
                      En suppression
                    </span>
                    {user.scheduledDeletionAt && (
                      <div className="text-xs text-red-600 bg-red-50 rounded-md px-2 py-1">
                        Suppression prévue le {formatDate(user.scheduledDeletionAt)}
                      </div>
                    )}
                  </div>
                ) : !user.isEmailVerified ? (
                  <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-orange-100 text-orange-800">
                    <AlertTriangle className="h-4 w-4 mr-1" />
                    Non vérifié
                  </span>
                ) : (
                  <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-green-100 text-green-800">
                    <CheckCircle2 className="h-4 w-4 mr-1" />
                    Vérifié
                  </span>
                )}
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <h3 className="text-lg font-medium text-gray-900 mb-4">
                  Informations personnelles
                </h3>
                <div className="space-y-4">
                  {user.phone && (
                    <div className="flex items-center">
                      <Phone className="h-5 w-5 text-gray-400 mr-3" />
                      <span>{user.phone}</span>
                    </div>
                  )}
                  {user.company?.address && (
                    <div className="flex items-start">
                      <MapPin className="h-5 w-5 text-gray-400 mr-3 mt-1" />
                      <div>
                        {user.company.address.street && <div>{user.company.address.street}</div>}
                        <div>
                          {user.company.address.postalCode} {user.company.address.city}
                        </div>
                        {user.company.address.country && <div>{user.company.address.country}</div>}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div>
                <h3 className="text-lg font-medium text-gray-900 mb-4">
                  Informations entreprise
                </h3>
                <div className="space-y-4">
                  <div className="flex items-center">
                    <Building2 className="h-5 w-5 text-gray-400 mr-3" />
                    <span>{user.company?.name}</span>
                  </div>
                  {user.company?.website && (
                    <div className="flex items-center">
                      <Globe className="h-5 w-5 text-gray-400 mr-3" />
                      <a 
                        href={user.company.website}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-purple-600 hover:text-purple-700"
                      >
                        {user.company.website}
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* Section Sécurité */}
            <div className="mt-6 pt-6 border-t border-gray-200">
              <h3 className="text-lg font-medium text-gray-900 mb-4">
                Sécurité
              </h3>
              <div className="space-y-4">
                <div className="flex items-center justify-between">
                  <div>
                    <p className="font-medium text-gray-900">Authentification à deux facteurs</p>
                    <p className="text-sm text-gray-500">
                      {user.twoFactorEnabled 
                        ? 'Activée - Authentification par email' 
                        : 'Désactivée'}
                    </p>
                  </div>
                  {user.twoFactorEnabled && (
                    <button
                      onClick={() => setShowDisable2FAConfirm(true)}
                      className="px-3 py-1.5 text-sm font-medium text-red-600 bg-red-50 rounded-lg hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    >
                      Désactiver
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>

          {/* Account Activity */}
          <div className="bg-white rounded-lg shadow-sm p-6">
            <div className="flex items-center space-x-3 mb-4">
              <StatusIndicator status={userStatus} />
              <h3 className="text-lg font-medium text-gray-900">
                Activité du compte
              </h3>
            </div>
            <div className="space-y-4">
              <div className="flex items-center justify-between py-3 border-b border-gray-100">
                <div className="flex items-center">
                  <Calendar className="h-5 w-5 text-gray-400 mr-3" />
                  <span className="text-gray-600">Inscription</span>
                </div>
                <span className="text-gray-900">{formatDate(user.createdAt)}</span>
              </div>
              <div className="flex items-center justify-between py-3 border-b border-gray-100">
                <div className="flex items-center">
                  <Calendar className="h-5 w-5 text-gray-400 mr-3" />
                  <span className="text-gray-600">Dernière connexion</span>
                </div>
                <span className="text-gray-900">{formatDate(user.lastLoginAt)}</span>
              </div>
            </div>
          </div>

          {/* User Stats */}
          <div className="bg-white rounded-lg shadow-sm p-6">
            <h3 className="text-lg font-medium text-gray-900 mb-4">
              Statistiques
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="bg-purple-50 rounded-lg p-4">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <Wine className="h-5 w-5 text-purple-600 mr-2" />
                    <span className="text-purple-900 font-medium">Total Cuvées</span>
                  </div>
                  <span className="text-2xl font-bold text-purple-900">{stats.totalCuvees}</span>
                </div>
              </div>
              <div className="bg-purple-50 rounded-lg p-4">
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <QrCode className="h-5 w-5 text-purple-600 mr-2" />
                    <span className="text-purple-900 font-medium">Total QR Codes</span>
                  </div>
                  <span className="text-2xl font-bold text-purple-900">{stats.totalQRCodes}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Subscription Info */}
        <div className="space-y-6">
          <div className="bg-white rounded-lg shadow-sm p-6">
            <h3 className="text-lg font-medium text-gray-900 mb-4">
              Abonnement
            </h3>
            <div className="space-y-4">
              <div className="p-4 rounded-lg bg-purple-50 border border-purple-100">
                <div className="flex items-center justify-between mb-2">
                  <span className="font-medium text-purple-900">
                    Plan {STRIPE_PRODUCTS[user.plan]?.name || 'Basic'}
                  </span>
                  <span className="text-purple-600 font-medium">
                    {user.plan === 'basic' 
                      ? 'Gratuit' 
                      : `${(STRIPE_PRODUCTS[user.plan]?.price / 100).toFixed(2)}€/mois`}
                  </span>
                </div>
                <p className="text-sm text-purple-700">
                  {STRIPE_PRODUCTS[user.plan]?.description}
                </p>
              </div>

              {user.subscription && (
                <div className="space-y-2">
                  <div className="flex items-center justify-between text-sm">
                    <span className="text-gray-600">Statut</span>
                    <span className="font-medium">
                      {user.subscription.status === 'active' ? 'Actif' : 'Inactif'}
                    </span>
                  </div>
                  {user.subscription.currentPeriodEnd && (
                    <div className="flex items-center justify-between text-sm">
                      <span className="text-gray-600">Prochaine facturation</span>
                      <span className="font-medium">
                        {formatDate(user.subscription.currentPeriodEnd)}
                      </span>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>

          {user.status === 'suspended' && (
            <div className="bg-red-50 border border-red-100 rounded-lg p-4">
              <div className="flex items-center space-x-2 text-red-800 mb-2">
                <Shield className="h-5 w-5" />
                <h4 className="font-medium">Compte suspendu</h4>
              </div>
              <p className="text-sm text-red- 700">
                Ce compte a été suspendu le {formatDate(user.suspendedAt)}
              </p>
            </div>
          )}
        </div>
      </div>

      <ModifySubscriptionModal
        isOpen={showSubscriptionModal}
        onClose={() => setShowSubscriptionModal(false)}
        onConfirm={handleSubscriptionChange}
        currentPlan={user.plan}
        userName={`${user.firstName} ${user.lastName}`}
        isProcessing={isProcessing}
      />

      <SuspendUserModal
        isOpen={showSuspendModal}
        onClose={() => setShowSuspendModal(false)}
        onConfirm={handleSuspend}
        userName={`${user.firstName} ${user.lastName}`}
        isProcessing={isProcessing}
        userId={user.id}
      />

      <UnblockUserModal
        isOpen={showUnblockModal}
        onClose={() => setShowUnblockModal(false)}
        onConfirm={handleUnblock}
        userName={`${user.firstName} ${user.lastName}`}
        isProcessing={isProcessing}
      />

      <DeleteUserModal
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onConfirm={handleDelete}
        userName={`${user.firstName} ${user.lastName}`}
        isProcessing={isProcessing}
      />

      {/* Modal de confirmation pour désactiver la 2FA */}
      {showDisable2FAConfirm && (
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex min-h-screen items-center justify-center p-4">
            <div
              className="fixed inset-0 bg-black bg-opacity-25 transition-opacity"
              onClick={() => setShowDisable2FAConfirm(false)}
            />

            <div className="relative w-full max-w-md transform overflow-hidden rounded-lg bg-white p-6 text-left shadow-xl transition-all">
              <div className="mb-4">
                <h3 className="text-lg font-medium text-gray-900">
                  Désactiver l'authentification à deux facteurs
                </h3>
                <p className="mt-2 text-sm text-gray-500">
                  Êtes-vous sûr de vouloir désactiver la 2FA pour cet utilisateur ? Cette action réduira la sécurité de son compte.
                </p>
              </div>

              <div className="mt-6 flex justify-end space-x-3">
                <button
                  onClick={() => setShowDisable2FAConfirm(false)}
                  className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-lg hover:bg-gray-50"
                >
                  Annuler
                </button>
                <button
                  onClick={handleDisable2FA}
                  disabled={isProcessing}
                  className="px-4 py-2 text-sm font-medium text-white bg-red-600 rounded-lg hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                >
                  {isProcessing ? ' Désactivation...' : 'Désactiver la 2FA'}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserDetails;