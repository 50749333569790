import { useState } from 'react';
import { MediaUploadService } from '../services/mediaUpload';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';

interface UseMediaUploadResult {
  uploadCompanyLogo: (userId: string, file: File) => Promise<string>;
  uploadWineImage: (userId: string, cuveeId: string, file: File) => Promise<string>;
  isUploading: boolean;
  error: string | null;
  progress: number;
}

export const useMediaUpload = (): UseMediaUploadResult => {
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [progress, setProgress] = useState(0);

  const uploadCompanyLogo = async (userId: string, file: File): Promise<string> => {
    try {
      setIsUploading(true);
      setError(null);
      setProgress(0);

      // Get company info
      const userDoc = await getDoc(doc(db, 'users', userId));
      if (!userDoc.exists()) {
        throw new Error('Utilisateur non trouvé');
      }

      const companyName = userDoc.data().company?.name;
      if (!companyName) {
        throw new Error('Nom d\'entreprise non trouvé');
      }

      // Delete old logo if exists
      const oldLogoPath = userDoc.data().company?.logoPath;
      if (oldLogoPath) {
        try {
          await MediaUploadService.deleteFile(oldLogoPath);
        } catch (err) {
          console.warn('Failed to delete old logo:', err);
          // Continue with upload even if delete fails
        }
      }

      // Simulate upload progress
      const progressInterval = setInterval(() => {
        setProgress(prev => Math.min(prev + 10, 90));
      }, 200);

      // Upload new logo
      const result = await MediaUploadService.uploadCompanyLogo(companyName, file);
      
      clearInterval(progressInterval);
      setProgress(100);

      // Update user document with new logo URL and path
      await updateDoc(doc(db, 'users', userId), {
        'company.logo': result.url,
        'company.logoPath': result.path
      });
      
      return result.url;
    } catch (err: any) {
      setError(err.message || 'Erreur lors du téléchargement du logo');
      throw err;
    } finally {
      setIsUploading(false);
      // Reset progress after a short delay
      setTimeout(() => setProgress(0), 500);
    }
  };

  const uploadWineImage = async (userId: string, cuveeId: string, file: File): Promise<string> => {
    try {
      setIsUploading(true);
      setError(null);
      setProgress(0);

      // Get company info
      const userDoc = await getDoc(doc(db, 'users', userId));
      if (!userDoc.exists()) {
        throw new Error('Utilisateur non trouvé');
      }

      const companyName = userDoc.data().company?.name;
      if (!companyName) {
        throw new Error('Nom d\'entreprise non trouvé');
      }

      // Get cuvee info and check for existing image
      const cuveeDoc = await getDoc(doc(db, 'cuvees', cuveeId));
      if (cuveeDoc.exists()) {
        const oldImagePath = cuveeDoc.data().photoPath;
        if (oldImagePath) {
          try {
            await MediaUploadService.deleteFile(oldImagePath);
          } catch (err) {
            console.warn('Failed to delete old image:', err);
            // Continue with upload even if delete fails
          }
        }
      }

      // Simulate upload progress
      const progressInterval = setInterval(() => {
        setProgress(prev => Math.min(prev + 10, 90));
      }, 200);

      // Upload new image
      const result = await MediaUploadService.uploadWineImage(companyName, cuveeId, file);
      
      clearInterval(progressInterval);
      setProgress(100);

      // Update cuvee document with new image URL and path
      if (cuveeDoc.exists()) {
        await updateDoc(doc(db, 'cuvees', cuveeId), {
          photo: result.url,
          photoPath: result.path
        });
      }
      
      return result.url;
    } catch (err: any) {
      setError(err.message || 'Erreur lors du téléchargement de l\'image');
      throw err;
    } finally {
      setIsUploading(false);
      // Reset progress after a short delay
      setTimeout(() => setProgress(0), 500);
    }
  };

  return {
    uploadCompanyLogo,
    uploadWineImage,
    isUploading,
    error,
    progress
  };
};