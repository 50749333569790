import React, { useState } from 'react';
import { ChevronLeft, ChevronRight, Globe, AlertTriangle } from 'lucide-react';
import CuveeWineInfo from './CuveeFormSteps/CuveeWineInfo';
import CuveeQualityLabels from './CuveeFormSteps/CuveeQualityLabels';
import CuveeConsumerInfo from './CuveeFormSteps/CuveeConsumerInfo';
import CuveeNutritionalInfo from './CuveeFormSteps/CuveeNutritionalInfo';
import { CuveeStatus } from '../types/cuvee';

interface CuveeFormProps {
  initialData?: any;
  onSubmit: (data: any) => Promise<void>;
  onCancel: () => void;
  isSubmitting: boolean;
  userId?: string;
}

const CuveeForm: React.FC<CuveeFormProps> = ({
  initialData = { status: CuveeStatus.DRAFT },
  onSubmit,
  onCancel,
  isSubmitting,
  userId
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState<any>(initialData);
  const [nameError, setNameError] = useState<string | null>(null);

  const steps = [
    {
      title: 'Informations générales',
      component: CuveeWineInfo,
      props: { 
        data: formData, 
        onChange: handleChange, 
        userId,
        onNameError: setNameError
      }
    },
    {
      title: 'Labels qualité',
      component: CuveeQualityLabels,
      props: { data: formData, onChange: handleChange }
    },
    {
      title: 'Informations consommateur',
      component: CuveeConsumerInfo,
      props: { data: formData, onChange: handleChange }
    },
    {
      title: 'Valeurs nutritionnelles',
      component: CuveeNutritionalInfo,
      props: { data: formData, onChange: handleChange }
    }
  ];

  function handleChange(updates: any) {
    setFormData(prev => ({ ...prev, ...updates }));
  }

  const handleSubmitForm = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
      return;
    }

    await onSubmit(formData);
  };

  const CurrentStepComponent = steps[currentStep].component;
  const isBlocked = formData.status === CuveeStatus.BLOCKED;
  const canProceed = currentStep === 0 ? !nameError && formData.name : true;

  if (isBlocked) {
    return (
      <div className="bg-red-50 border-l-4 border-red-400 p-4 rounded-lg">
        <div className="flex">
          <div className="flex-shrink-0">
            <AlertTriangle className="h-5 w-5 text-red-400" />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">
              Cuvée bloquée
            </h3>
            <p className="mt-2 text-sm text-red-700">
              Cette cuvée a été bloquée par un administrateur. Vous ne pouvez plus la modifier ou la supprimer.
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmitForm} className="space-y-8">
      <div className="border-b border-gray-200 pb-4">
        <nav className="flex justify-between">
          {steps.map((step, index) => (
            <button
              key={step.title}
              type="button"
              onClick={() => setCurrentStep(index)}
              disabled={index > currentStep || (index > 0 && nameError)}
              className={`flex items-center ${
                index <= currentStep
                  ? nameError && index > 0 
                    ? 'text-gray-400 cursor-not-allowed'
                    : 'text-purple-600'
                  : 'text-gray-400'
              }`}
            >
              <span className={`w-8 h-8 rounded-full flex items-center justify-center border-2 ${
                index <= currentStep
                  ? nameError && index > 0
                    ? 'border-gray-300 bg-gray-50'
                    : 'border-purple-600 bg-purple-50'
                  : 'border-gray-300'
              }`}>
                {index + 1}
              </span>
              <span className="ml-2 text-sm font-medium">{step.title}</span>
            </button>
          ))}
        </nav>
      </div>

      <div className="py-4">
        <CurrentStepComponent {...steps[currentStep].props} />
      </div>

      {currentStep === 0 && nameError && (
        <div className="bg-red-50 border-l-4 border-red-400 p-4 rounded-lg animate-fadeIn">
          <div className="flex">
            <div className="flex-shrink-0">
              <AlertTriangle className="h-5 w-5 text-red-400" />
            </div>
            <div className="ml-3">
              <h3 className="text-sm font-medium text-red-800">
                Impossible de continuer
              </h3>
              <p className="mt-1 text-sm text-red-700">
                {nameError}. Veuillez choisir un autre nom pour votre cuvée avant de continuer.
              </p>
            </div>
          </div>
        </div>
      )}

      <div className="flex justify-between pt-4 border-t border-gray-200">
        <button
          type="button"
          onClick={currentStep === 0 ? onCancel : () => setCurrentStep(currentStep - 1)}
          className="btn-secondary flex items-center space-x-2"
          disabled={isSubmitting}
        >
          <ChevronLeft className="h-4 w-4" />
          <span>{currentStep === 0 ? 'Annuler' : 'Retour'}</span>
        </button>

        <div className="flex items-center space-x-4">
          {currentStep === steps.length - 1 && formData.status === CuveeStatus.DRAFT && (
            <label className="flex items-center space-x-2 cursor-pointer">
              <input
                type="checkbox"
                checked={formData.status === CuveeStatus.PUBLISHED}
                onChange={(e) => handleChange({ status: e.target.checked ? CuveeStatus.PUBLISHED : CuveeStatus.DRAFT })}
                className="h-4 w-4 text-purple-600 focus:ring-purple-500 border-gray-300 rounded"
              />
              <span className="text-sm text-gray-700 flex items-center">
                <Globe className="h-4 w-4 mr-1" />
                Publier après l'enregistrement
              </span>
            </label>
          )}

          <button
            type="submit"
            disabled={isSubmitting || !canProceed}
            className={`btn flex items-center space-x-2 ${
              !canProceed ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            title={!canProceed ? 'Veuillez corriger les erreurs avant de continuer' : ''}
          >
            <span>
              {isSubmitting
                ? 'Enregistrement...'
                : currentStep === steps.length - 1
                ? formData.status === CuveeStatus.PUBLISHED ? 'Enregistrer et publier' : 'Enregistrer'
                : 'Suivant'}
            </span>
            {currentStep < steps.length - 1 && <ChevronRight className="h-4 w-4" />}
          </button>
        </div>
      </div>
    </form>
  );
};

export default CuveeForm;