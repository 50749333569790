import { storage } from '../lib/firebase';
import { ref, getDownloadURL } from 'firebase/storage';

// Cache expiration time
const CACHE_EXPIRATION = 60 * 60 * 1000; // 1 hour

interface CacheEntry {
  url: string;
  timestamp: number;
}

const cache = new Map<string, CacheEntry>();

// Map of certification IDs to their actual filenames in storage
const CERTIFICATION_FILES: Record<string, string> = {
  'ab': 'AB-EU.png',
  'avn': 'AVN.png',
  'bio-coherence': 'Bio-Coherence.png',
  'biodyvin': 'Biodyvin.png',
  'demeter-certifie': 'Demeter-certifie.png',
  'demeter': 'Demeter.png',
  'hve': 'HVE.png',
  'nature-progres': 'Nature-et-Progres.png',
  'terra-vitis': 'Terra-Vitis.png',
  'vigneron-independant': 'Vigneron-independant.png',
  'sains': 'vin-SAINS.png',
  'viticulture-durable': 'viticulture-durable-champagne.png'
};

export const getCertificationImageUrl = async (certificationId: string): Promise<string> => {
  const now = Date.now();
  const cached = cache.get(certificationId);

  // Return cached URL if valid
  if (cached && (now - cached.timestamp) < CACHE_EXPIRATION) {
    return cached.url;
  }

  try {
    const filename = CERTIFICATION_FILES[certificationId];
    if (!filename) {
      throw new Error(`No file mapping for certification: ${certificationId}`);
    }

    // Get image from Firebase Storage
    const imageRef = ref(storage, `certifications/${filename}`);
    const url = await getDownloadURL(imageRef);
    
    // Cache the successful result
    cache.set(certificationId, { url, timestamp: now });
    return url;
  } catch (error) {
    console.error(`Error loading certification image: ${certificationId}`, error);
    throw error;
  }
};

// Liste des IDs de certification disponibles
export const CERTIFICATION_IDS = [
  'vigneron-independant',
  'hve',
  'terra-vitis',
  'viticulture-durable',
  'biodyvin',
  'demeter',
  'demeter-certifie',
  'avn',
  'sains',
  'nature-progres',
  'bio-coherence',
  'ab'
];