import React, { useState, useEffect } from 'react';
import { ChevronLeft, CreditCard, Lock } from 'lucide-react';
import { Elements } from '@stripe/react-stripe-js';
import { SignupData } from '..';
import { STRIPE_PRODUCTS, stripePromise } from '../../../lib/stripe';
import StripePaymentElement from '../../../components/StripePaymentElement';
import ReCaptcha from '../../../components/ReCaptcha';
import { createUserAccount } from '../../../lib/firebase';

interface PaymentProps {
  data: SignupData;
  onComplete: (data: Partial<SignupData>) => void;
  onBack: () => void;
  paymentCompleted: boolean;
}

const PAYMENT_ENABLED = import.meta.env.VITE_PAYMENT_ENABLE === 'true';
const DEV_PASSWORD = import.meta.env.VITE_PASSWORD;

const Payment: React.FC<PaymentProps> = ({ 
  data, 
  onComplete, 
  onBack,
  paymentCompleted 
}) => {
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);
  const [password, setPassword] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);

  const productInfo = STRIPE_PRODUCTS[data.plan];

  useEffect(() => {
    if (!paymentCompleted && PAYMENT_ENABLED) {
      createPaymentIntent();
    }
  }, []);

  const createPaymentIntent = async () => {
    try {
      const response = await fetch('/api/stripe/create-payment-intent', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          amount: productInfo.price,
          email: data.email,
          metadata: {
            plan: data.plan,
            firstName: data.firstName,
            lastName: data.lastName,
            companyName: data.companyName
          }
        }),
      });

      if (!response.ok) {
        throw new Error('Erreur lors de la création de l\'intention de paiement');
      }

      const { clientSecret } = await response.json();
      setClientSecret(clientSecret);
    } catch (err: any) {
      console.error('Payment intent error:', err);
      setError(err.message || 'Erreur lors de l\'initialisation du paiement');
    }
  };

  const handlePaymentSuccess = () => {
    if (!captchaToken) {
      setError('Veuillez valider le captcha');
      return;
    }
    onComplete({});
  };

  const handleDevSignup = async () => {
    if (!captchaToken) {
      setError('Veuillez valider le captcha');
      return;
    }

    if (password !== DEV_PASSWORD) {
      setError('Mot de passe de développement incorrect');
      return;
    }

    try {
      setIsProcessing(true);
      setError(null);

      // Créer le compte utilisateur directement en mode dev
      await createUserAccount(data.email, data.password, {
        firstName: data.firstName,
        lastName: data.lastName,
        company: {
          name: data.companyName,
          slug: data.companyName.toLowerCase().replace(/[^a-z0-9]+/g, '-')
        },
        plan: data.plan,
        isEmailVerified: false,
        status: 'active',
        twoFactorEnabled: false
      });

      // Passer à l'étape suivante
      onComplete({});
    } catch (err: any) {
      console.error('Error during dev signup:', err);
      setError(err.message || 'Une erreur est survenue lors de la création du compte');
    } finally {
      setIsProcessing(false);
    }
  };

  const handlePaymentError = (error: string) => {
    setError(error);
  };

  return (
    <div className="space-y-6">
      <div className="bg-gray-50 p-6 rounded-lg">
        <h3 className="text-lg font-medium text-gray-900 mb-4">
          Récapitulatif de la commande
        </h3>
        <div className="flex justify-between items-center">
          <div>
            <p className="text-sm font-medium text-gray-900">
              Plan {productInfo.name}
            </p>
            <p className="text-sm text-gray-500">
              {productInfo.type === 'subscription' ? 'Facturation mensuelle' : 'Frais de création de compte'}
            </p>
          </div>
          <p className="text-lg font-medium text-gray-900">
            {(productInfo.price / 100).toFixed(2)}€{productInfo.type === 'subscription' ? '/mois' : ''}
          </p>
        </div>
      </div>

      {PAYMENT_ENABLED ? (
        clientSecret ? (
          <>
            <Elements 
              stripe={stripePromise} 
              options={{
                clientSecret,
                appearance: {
                  theme: 'stripe',
                  variables: {
                    colorPrimary: '#7e22ce',
                    colorBackground: '#ffffff',
                    colorText: '#1f2937',
                  },
                },
              }}
            >
              <StripePaymentElement
                clientSecret={clientSecret}
                onSuccess={handlePaymentSuccess}
                onError={handlePaymentError}
                amount={productInfo.price}
                returnUrl={`${window.location.origin}/signup?step=success`}
              />
            </Elements>

            <ReCaptcha
              onChange={setCaptchaToken}
              className="flex justify-center"
            />
          </>
        ) : error ? (
          <div className="p-4 bg-red-50 border border-red-200 rounded-lg text-red-700 text-sm">
            {error}
          </div>
        ) : (
          <div className="flex justify-center py-12">
            <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-purple-600"></div>
          </div>
        )
      ) : (
        <div className="space-y-6">
          <div className="bg-yellow-50 border border-yellow-100 rounded-lg p-4">
            <p className="text-sm text-yellow-800">
              <strong>Mode développement :</strong> Le système de paiement est désactivé. 
              Veuillez entrer le mot de passe de développement pour continuer.
            </p>
          </div>

          <div>
            <label htmlFor="devPassword" className="block text-sm font-medium text-gray-700 mb-2">
              Mot de passe développeur
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Lock className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="password"
                id="devPassword"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="block w-full pl-11 pr-3 py-3 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-purple-500 focus:border-transparent"
                placeholder="••••••••"
              />
            </div>
          </div>

          <ReCaptcha
            onChange={setCaptchaToken}
            className="flex justify-center"
          />

          {error && (
            <div className="p-4 bg-red-50 border border-red-200 rounded-lg text-red-700 text-sm">
              {error}
            </div>
          )}

          <button
            onClick={handleDevSignup}
            disabled={isProcessing || !password || !captchaToken}
            className="w-full btn flex justify-center items-center space-x-2"
          >
            <CreditCard className="h-4 w-4" />
            <span>{isProcessing ? 'Traitement...' : 'Valider l\'inscription'}</span>
          </button>
        </div>
      )}

      <div className="flex justify-between pt-4">
        <button
          type="button"
          onClick={onBack}
          className="btn-secondary flex items-center space-x-2"
          disabled={isProcessing}
        >
          <ChevronLeft className="h-4 w-4" />
          <span>Retour</span>
        </button>
      </div>
    </div>
  );
};

export default Payment;