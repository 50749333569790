import { normalize } from './stringUtils';

// Catégories de mots interdits
export const BANWORD_CATEGORIES = {
  VULGAR: 'Vulgarités et Grossièretés',
  DISCRIMINATORY: 'Langage Discriminatoire', 
  EXPLICIT: 'Contenus Explicites et Vulgarités Sexuelles',
  VIOLENCE: 'Violence et Menaces',
  DRUGS: 'Drogues et Substances Illicites',
  SPAM: 'Langage de Spams et de Fraudes',
  HATE: 'Incitations à la Haine',
  RELIGIOUS: 'Expressions Religieuses Extrémistes',
  ILLEGAL: 'Publicités pour Contenus Interdits',
  HARASSMENT: 'Langage Proche du Harcèlement'
};

// Liste des mots interdits par catégorie
export const BANWORDS: Record<string, string[]> = {
  [BANWORD_CATEGORIES.VULGAR]: [
    'merde', 'con', 'connard', 'salopard', 'foutre', 'pute', 'salope',
    'ordure', 'trouduc', 'bâtard', 'abruti', 'déchet', 'fdp', 'putain',
    'caca', 'crottes'
  ],
  [BANWORD_CATEGORIES.DISCRIMINATORY]: [
    'négro', 'negro', 'bougnoule', 'youpin', 'pédé', 'tapette', 'gouine',
    'mongol', 'attardé', 'handicapé', 'sale juif', 'sale arabe', 'sale noir'
  ],
  [BANWORD_CATEGORIES.EXPLICIT]: [
    'sexe', 'porn', 'porno', 'sodomie', 'pénétration', 'orgie', 'chatte',
    'bukkake', 'gangbang', 'xxx', 'ejac', 'éjaculation', 'bite', 'zizi',
    'beurette'
  ],
  [BANWORD_CATEGORIES.VIOLENCE]: [
    'meurtre', 'viol', 'assassiner', 'tuer', 'massacre', 'sanglant',
    'écraser', 'éviscérer', 'battre', 'décapiter', 'torturer'
  ],
  [BANWORD_CATEGORIES.DRUGS]: [
    'coke', 'cocaïne', 'héroïne', 'ecstasy', 'lsd', 'méthamphétamine',
    'joint', 'herbe', 'dealer', 'toxicomane', 'crack', 'cannabis', 'shit'
  ],
  [BANWORD_CATEGORIES.SPAM]: [
    'arnaque', 'scam', 'phishing', 'fraude', 'argent facile', 'qvee',
    'gagner rapidement', 'pyramide', 'mlm', 'enrichissement rapide'
  ],
  [BANWORD_CATEGORIES.HATE]: [
    'nazi', 'fasciste', 'race inférieure', 'suprématie', 'purification',
    'extermination', 'génocide', 'épuration', 'sous-race', 'hitler'
  ],
  [BANWORD_CATEGORIES.RELIGIOUS]: [
    'fanatique', 'extrémiste', 'djihad', 'fatwa', 'croisé',
    'infidèle', 'mécréant', 'hérétique', 'sectaire'
  ],
  [BANWORD_CATEGORIES.ILLEGAL]: [
    'vente arme', 'achat arme', 'marché noir', 'contrebande', 'drogue',
    'substances illégales', 'trafic', 'contrefaçon', 'pistolet'
  ],
  [BANWORD_CATEGORIES.HARASSMENT]: [
    'stalking', 'harcèlement', 'menace', 'intimidation', 'doxxing',
    'cyber-harcèlement', 'revenge porn', 'chantage'
  ]
};

export interface ContentViolation {
  found: boolean;
  category?: string;
  words?: string[];
}

export const checkForBanwords = (text: string): ContentViolation => {
  if (!text) return { found: false };
  
  const normalizedText = normalize(text);
  
  for (const [category, banwords] of Object.entries(BANWORDS)) {
    const foundWords = banwords.filter(word => {
      const normalizedWord = normalize(word);
      // Check if the word exists as a whole word
      const regex = new RegExp(`\\b${normalizedWord}\\b`, 'i');
      return regex.test(normalizedText);
    });
    
    if (foundWords.length > 0) {
      return {
        found: true,
        category,
        words: foundWords
      };
    }
  }
  
  return { found: false };
};