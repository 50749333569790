import React, { useState } from 'react';
import { LifeBuoy, Search } from 'lucide-react';
import type { Ticket } from '../../types/ticket';
import Toast, { ToastType } from '../../components/Toast';
import { TicketCategoryIcon, getCategoryLabel } from '../../components/TicketCategoryIcon';
import ChatDrawer from '../../components/ChatDrawer';
import { useTickets } from '../../hooks/useTickets';
import { formatDateTime } from '../../utils/dateUtils';

const AdminSupport: React.FC = () => {
  const { tickets, loading, markTicketAsRead } = useTickets(undefined, true);
  const [selectedTicket, setSelectedTicket] = useState<Ticket | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [toast, setToast] = useState<{ message: string; type: ToastType } | null>(null);

  // Filtrer les tickets en fonction de la recherche
  const filteredTickets = tickets.filter(ticket => {
    const searchLower = searchTerm.toLowerCase();
    return (
      (ticket.subject?.toLowerCase() || '').includes(searchLower) ||
      (ticket.userName?.toLowerCase() || '').includes(searchLower) ||
      (getCategoryLabel(ticket.category)?.toLowerCase() || '').includes(searchLower)
    );
  });

  const activeTickets = filteredTickets.filter(ticket => 
    ticket.status !== 'resolved' && ticket.status !== 'closed'
  );

  const archivedTickets = filteredTickets.filter(ticket => 
    ticket.status === 'resolved' || ticket.status === 'closed'
  );

  const handleTicketSelect = async (ticket: Ticket) => {
    setSelectedTicket(ticket);
    if (ticket.unreadMessages) {
      await markTicketAsRead(ticket.id);
    }
  };

  const getStatusIcon = (status: string) => {
    switch (status) {
      case 'open':
        return <div className="h-2 w-2 rounded-full bg-blue-500" />;
      case 'in_progress':
        return <div className="h-2 w-2 rounded-full bg-yellow-500" />;
      case 'resolved':
        return <div className="h-2 w-2 rounded-full bg-green-500" />;
      case 'closed':
        return <div className="h-2 w-2 rounded-full bg-gray-500" />;
      default:
        return null;
    }
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'open':
        return 'bg-blue-100 text-blue-800';
      case 'in_progress':
        return 'bg-yellow-100 text-yellow-800';
      case 'resolved':
        return 'bg-green-100 text-green-800';
      case 'closed':
        return 'bg-gray-100 text-gray-800';
      default:
        return '';
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-purple-600"></div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 space-y-8">
      {toast && (
        <Toast
          message={toast.message}
          type={toast.type}
          onClose={() => setToast(null)}
        />
      )}

      <div>
        <h1 className="text-2xl font-bold text-gray-900">Support</h1>
        <p className="mt-1 text-sm text-gray-600">
          Gérez les tickets de support des utilisateurs
        </p>
      </div>

      <div className="space-y-4">
        <div className="flex space-x-2">
          <div className="flex-1 relative">
            <input
              type="text"
              placeholder="Rechercher un ticket..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
            />
            <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
          </div>
        </div>

        {activeTickets.length === 0 ? (
          <div className="text-center py-12 bg-white rounded-lg shadow">
            <LifeBuoy className="mx-auto h-12 w-12 text-gray-400" />
            <h3 className="mt-2 text-sm font-medium text-gray-900">Aucun ticket</h3>
          </div>
        ) : (
          activeTickets.map((ticket) => (
            <div
              key={ticket.id}
              onClick={() => handleTicketSelect(ticket)}
              className={`p-4 bg-white rounded-lg shadow cursor-pointer transition-colors ${
                selectedTicket?.id === ticket.id
                  ? 'border-2 border-purple-500'
                  : 'border border-gray-200 hover:border-purple-200'
              }`}
            >
              <div className="flex items-center justify-between mb-2">
                <div className="flex items-center space-x-3">
                  <TicketCategoryIcon category={ticket.category} />
                  <div>
                    <h3 className="font-medium text-gray-900">
                      {ticket.subject}
                      {ticket.unreadMessages > 0 && (
                        <span className="ml-2 inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                          {ticket.unreadMessages}
                        </span>
                      )}
                    </h3>
                    <p className="text-sm text-gray-500">{ticket.userName}</p>
                  </div>
                </div>
                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusColor(ticket.status)}`}>
                  {getStatusIcon(ticket.status)}
                  <span className="ml-1">
                    {ticket.status === 'open' ? 'Ouvert' :
                     ticket.status === 'in_progress' ? 'En cours' :
                     ticket.status === 'resolved' ? 'Résolu' : 'Fermé'}
                  </span>
                </span>
              </div>
              <p className="text-sm text-gray-500">
                Dernière mise à jour : {formatDateTime(ticket.updatedAt)}
              </p>
            </div>
          ))
        )}
      </div>

      {archivedTickets.length > 0 && (
        <div className="mt-8">
          <h2 className="text-lg font-medium text-gray-900 mb-4">Tickets archivés</h2>
          <div className="bg-white rounded-lg shadow overflow-hidden">
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Sujet
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Utilisateur
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Catégorie
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Statut
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Dernière mise à jour
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {archivedTickets.map((ticket) => (
                    <tr 
                      key={ticket.id}
                      onClick={() => handleTicketSelect(ticket)}
                      className="hover:bg-gray-50 cursor-pointer"
                    >
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <TicketCategoryIcon category={ticket.category} className="mr-2" />
                          <span className="text-sm font-medium text-gray-900">
                            {ticket.subject}
                            {ticket.unreadMessages > 0 && (
                              <span className="ml-2 inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                                {ticket.unreadMessages}
                              </span>
                            )}
                          </span>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className="text-sm text-gray-500">
                          {ticket.userName}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className="text-sm text-gray-500">
                          {getCategoryLabel(ticket.category)}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusColor(ticket.status)}`}>
                          {getStatusIcon(ticket.status)}
                          <span className="ml-1">
                            {ticket.status === 'resolved' ? 'Résolu' : 'Fermé'}
                          </span>
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {formatDateTime(ticket.updatedAt)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}

      {selectedTicket && (
        <ChatDrawer
          ticket={selectedTicket}
          onClose={() => setSelectedTicket(null)}
          isAdmin={true}
        />
      )}
    </div>
  );
};

export default AdminSupport;