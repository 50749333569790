import React, { useState, useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { SignupData } from '..';
import { Mail, User, Building2, Lock, ChevronRight, Eye, EyeOff, X } from 'lucide-react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../../lib/firebase';
import { useNavigate } from 'react-router-dom';
import PasswordStrengthIndicator from '../../../components/PasswordStrengthIndicator';
import CancelSignupModal from '../../../components/CancelSignupModal';

interface PersonalInfoProps {
  data: SignupData;
  onComplete: (data: Partial<SignupData>) => void;
}

const PersonalInfo: React.FC<PersonalInfoProps> = ({ data, onComplete }) => {
  const navigate = useNavigate();
  const [isCheckingEmail, setIsCheckingEmail] = useState(false);
  const [isCheckingCompany, setIsCheckingCompany] = useState(false);
  const [emailError, setEmailError] = useState<string | null>(null);
  const [companyError, setCompanyError] = useState<string | null>(null);
  const [showPasswordStrength, setShowPasswordStrength] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [checkEmailTimeout, setCheckEmailTimeout] = useState<NodeJS.Timeout | null>(null);
  const [checkCompanyTimeout, setCheckCompanyTimeout] = useState<NodeJS.Timeout | null>(null);
  const [showCancelModal, setShowCancelModal] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setError,
    clearErrors,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      email: data.email,
      password: data.password,
      confirmPassword: '',
      firstName: data.firstName,
      lastName: data.lastName,
      companyName: data.companyName,
    }
  });

  const email = watch('email');
  const password = watch('password');
  const confirmPassword = watch('confirmPassword');
  const companyName = watch('companyName');

  const validatePasswordStrength = (value: string) => {
    let metCriteria = 0;
    if (value.length >= 8) metCriteria++;
    if (/[A-Z]/.test(value)) metCriteria++;
    if (/[a-z]/.test(value)) metCriteria++;
    if (/\d/.test(value)) metCriteria++;
    if (/[!@#$%^&*(),.?":{}|<>]/.test(value)) metCriteria++;

    if (metCriteria < 4) {
      return 'Le mot de passe doit remplir au moins 4 des critères de sécurité';
    }
    return true;
  };

  const generateSlug = (text: string): string => {
    return text
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/(^-|-$)/g, '');
  };

  const checkCompanyExists = useCallback(async (companyNameToCheck: string): Promise<boolean> => {
    if (!companyNameToCheck || errors.companyName) return true;

    try {
      setIsCheckingCompany(true);
      setCompanyError(null);
      clearErrors('companyName');

      const companySlug = generateSlug(companyNameToCheck);
      const usersRef = collection(db, 'users');
      const q = query(usersRef, where('company.slug', '==', companySlug));
      const snapshot = await getDocs(q);

      if (!snapshot.empty) {
        const errorMessage = 'Ce nom d\'entreprise est déjà utilisé';
        setCompanyError(errorMessage);
        setError('companyName', { type: 'manual', message: errorMessage });
        return true;
      }

      return false;
    } catch (err: any) {
      const errorMessage = 'Erreur lors de la vérification du nom d\'entreprise';
      setError('companyName', { type: 'manual', message: errorMessage });
      setCompanyError(errorMessage);
      return true;
    } finally {
      setIsCheckingCompany(false);
    }
  }, [errors.companyName, clearErrors, setError]);

  const checkEmailExists = useCallback(async (emailToCheck: string): Promise<boolean> => {
    if (!emailToCheck || errors.email) return true;

    try {
      setIsCheckingEmail(true);
      setEmailError(null);
      clearErrors('email');

      const usersRef = collection(db, 'users');
      const q = query(usersRef, where('email', '==', emailToCheck.toLowerCase()));
      const snapshot = await getDocs(q);

      if (!snapshot.empty) {
        const errorMessage = 'Cet email est déjà utilisé';
        setEmailError(errorMessage);
        setError('email', { type: 'manual', message: errorMessage });
        return true;
      }

      return false;
    } catch (err: any) {
      const errorMessage = 'Erreur lors de la vérification de l\'email';
      setError('email', { type: 'manual', message: errorMessage });
      setEmailError(errorMessage);
      return true;
    } finally {
      setIsCheckingEmail(false);
    }
  }, [errors.email, clearErrors, setError]);

  useEffect(() => {
    if (checkEmailTimeout) {
      clearTimeout(checkEmailTimeout);
    }

    if (email && !errors.email && email.includes('@')) {
      const timeout = setTimeout(() => {
        checkEmailExists(email);
      }, 500);
      setCheckEmailTimeout(timeout);
    } else {
      setEmailError(null);
    }

    return () => {
      if (checkEmailTimeout) {
        clearTimeout(checkEmailTimeout);
      }
    };
  }, [email, errors.email, checkEmailExists]);

  useEffect(() => {
    if (checkCompanyTimeout) {
      clearTimeout(checkCompanyTimeout);
    }

    if (companyName && !errors.companyName && companyName.length >= 2) {
      const timeout = setTimeout(() => {
        checkCompanyExists(companyName);
      }, 500);
      setCheckCompanyTimeout(timeout);
    } else {
      setCompanyError(null);
    }

    return () => {
      if (checkCompanyTimeout) {
        clearTimeout(checkCompanyTimeout);
      }
    };
  }, [companyName, errors.companyName, checkCompanyExists]);

  useEffect(() => {
    if (confirmPassword) {
      if (password !== confirmPassword) {
        setError('confirmPassword', {
          type: 'manual',
          message: 'Les mots de passe ne correspondent pas'
        });
      } else {
        clearErrors('confirmPassword');
      }
    }
  }, [password, confirmPassword, setError, clearErrors]);

  const onSubmit = async (formData: any) => {
    const emailExists = await checkEmailExists(formData.email);
    if (emailExists) return;

    const companyExists = await checkCompanyExists(formData.companyName);
    if (companyExists) return;

    if (!formData.firstName || !formData.lastName || !formData.companyName || 
        !formData.email || !formData.password || !formData.confirmPassword) {
      return;
    }

    const { confirmPassword, ...userData } = formData;
    onComplete(userData);
  };

  const handleCancel = () => {
    setShowCancelModal(true);
  };

  const handleConfirmCancel = () => {
    navigate('/login');
  };

  const isSubmitDisabled = isCheckingEmail || isCheckingCompany || !!emailError || !!companyError || Object.keys(errors).length > 0;

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
          <div>
            <label htmlFor="firstName" className="block text-sm font-medium text-gray-700 mb-2">
              Prénom
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
                <User className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="text"
                id="firstName"
                {...register('firstName', { required: 'Le prénom est requis' })}
                className="pl-11 pr-4 py-3 block w-full rounded-lg border border-gray-300 bg-gray-50 text-gray-900 shadow-sm focus:ring-2 focus:ring-purple-500 focus:border-transparent transition duration-150 ease-in-out"
                placeholder="John"
              />
            </div>
            {errors.firstName && (
              <p className="mt-2 text-sm text-red-600">{errors.firstName.message}</p>
            )}
          </div>

          <div>
            <label htmlFor="lastName" className="block text-sm font-medium text-gray-700 mb-2">
              Nom
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
                <User className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="text"
                id="lastName"
                {...register('lastName', { required: 'Le nom est requis' })}
                className="pl-11 pr-4 py-3 block w-full rounded-lg border border-gray-300 bg-gray-50 text-gray-900 shadow-sm focus:ring-2 focus:ring-purple-500 focus:border-transparent transition duration-150 ease-in-out"
                placeholder="Doe"
              />
            </div>
            {errors.lastName && (
              <p className="mt-2 text-sm text-red-600">{errors.lastName.message}</p>
            )}
          </div>

          <div className="sm:col-span-2">
            <label htmlFor="companyName" className="block text-sm font-medium text-gray-700 mb-2">
              Nom de l'entreprise
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
                <Building2 className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="text"
                id="companyName"
                {...register('companyName', { required: 'Le nom de l\'entreprise est requis' })}
                className={`pl-11 pr-10 py-3 block w-full rounded-lg border ${
                  errors.companyName || companyError ? 'border-red-300 bg-red-50' : 
                  !companyError && companyName ? 'border-green-300 bg-green-50' : 
                  'border-gray-300 bg-gray-50'
                } text-gray-900 shadow-sm focus:ring-2 focus:ring-purple-500 focus:border-transparent transition duration-150 ease-in-out`}
                placeholder="Votre entreprise"
              />
              {isCheckingCompany && (
                <div className="absolute right-3 top-3">
                  <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-purple-600"></div>
                </div>
              )}
            </div>
            {(errors.companyName || companyError) && (
              <p className="mt-2 text-sm text-red-600">{errors.companyName?.message || companyError}</p>
            )}
            {!errors.companyName && !companyError && companyName && !isCheckingCompany && (
              <p className="mt-2 text-sm text-green-600">Ce nom d'entreprise est disponible</p>
            )}
          </div>

          <div className="sm:col-span-2">
            <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-2">
              Email
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
                <Mail className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="email"
                id="email"
                {...register('email', {
                  required: 'L\'email est requis',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Adresse email invalide',
                  }
                })}
                className={`pl-11 pr-10 py-3 block w-full rounded-lg border ${
                  errors.email || emailError ? 'border-red-300 bg-red-50' : 
                  !emailError && email ? 'border-green-300 bg-green-50' : 
                  'border-gray-300 bg-gray-50'
                } text-gray-900 shadow-sm focus:ring-2 focus:ring-purple-500 focus:border-transparent transition duration-150 ease-in-out`}
                placeholder="john.doe@example.com"
              />
              {isCheckingEmail && (
                <div className="absolute right-3 top-3">
                  <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-purple-600"></div>
                </div>
              )}
            </div>
            {(errors.email || emailError) && (
              <p className="mt-2 text-sm text-red-600">{errors.email?.message || emailError}</p>
            )}
            {!errors.email && !emailError && email && !isCheckingEmail && (
              <p className="mt-2 text-sm text-green-600">Cette adresse email est disponible</p>
            )}
          </div>

          <div className="sm:col-span-2">
            <label htmlFor="password" className="block text-sm font-medium text-gray-700 mb-2">
              Mot de passe
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
                <Lock className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type={showPassword ? "text" : "password"}
                id="password"
                {...register('password', {
                  required: 'Le mot de passe est requis',
                  validate: validatePasswordStrength
                })}
                className="pl-11 pr-12 py-3 block w-full rounded-lg border border-gray-300 bg-gray-50 text-gray-900 shadow-sm focus:ring-2 focus:ring-purple-500 focus:border-transparent transition duration-150 ease-in-out"
                placeholder="••••••••"
                onFocus={() => setShowPasswordStrength(true)}
              />
              <button
                type="button"
                className="absolute inset-y-0 right-0 pr-3 flex items-center"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <EyeOff className="h-5 w-5 text-gray-400 hover:text-gray-600" />
                ) : (
                  <Eye className="h-5 w-5 text-gray-400 hover:text-gray-600" />
                )}
              </button>
            </div>
            {errors.password && (
              <p className="mt-2 text-sm text-red-600">{errors.password.message}</p>
            )}
            {showPasswordStrength && <PasswordStrengthIndicator password={password || ''} />}
          </div>

          <div className="sm:col-span-2">
            <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700 mb-2">
              Confirmer le mot de passe
            </label>
            <div className="relative">
              <div className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none">
                <Lock className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type={showConfirmPassword ? "text" : "password"}
                id="confirmPassword"
                {...register('confirmPassword', {
                  required: 'Veuillez confirmer votre mot de passe',
                  validate: value => value === password || 'Les mots de passe ne correspondent pas'
                })}
                className="pl-11 pr-12 py-3 block w-full rounded-lg border border-gray-300 bg-gray-50 text-gray-900 shadow-sm focus:ring-2 focus:ring-purple-500 focus:border-transparent transition duration-150 ease-in-out"
                placeholder="••••••••"
              />
              <button
                type="button"
                className="absolute inset-y-0 right-0 pr-3 flex items-center"
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                {showConfirmPassword ? (
                  <EyeOff className="h-5 w-5 text-gray-400 hover:text-gray-600" />
                ) : (
                  <Eye className="h-5 w-5 text-gray-400 hover:text-gray-600" />
                )}
              </button>
            </div>
            {errors.confirmPassword && (
              <p className="mt-2 text-sm text-red-600">{errors.confirmPassword.message}</p>
            )}
          </div>
        </div>

        <div className="flex justify-between pt-4">
          <button
            type="button"
            onClick={handleCancel}
            className="btn-secondary flex items-center space-x-2 text-red-600 hover:text-red-700 hover:bg-red-50"
          >
            <X className="h-4 w-4" />
            <span>Annuler</span>
          </button>

          <button
            type="submit"
            disabled={isSubmitDisabled}
            className="btn flex items-center space-x-2 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <span>Suivant</span>
            <ChevronRight className="h-4 w-4" />
          </button>
        </div>
      </form>

      <CancelSignupModal
        isOpen={showCancelModal}
        onClose={() => setShowCancelModal(false)}
        onConfirm={handleConfirmCancel}
      />
    </>
  );
};

export default PersonalInfo;