import React, { useState, useEffect } from 'react';
import { Bot, AlertTriangle, CheckCircle2 } from 'lucide-react';
import { checkForBanwords } from '../utils/banwords';
import { logModeration } from '../lib/serverLogs';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';

interface CuveeModerationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onComplete: (success: boolean) => void;
  isUpdate: boolean;
  cuveeData?: any;
}

const CuveeModerationModal: React.FC<CuveeModerationModalProps> = ({
  isOpen,
  onClose,
  onComplete,
  isUpdate,
  cuveeData
}) => {
  const [progress, setProgress] = useState(0);
  const [message, setMessage] = useState('Initialisation de la modération...');
  const [status, setStatus] = useState<'checking' | 'success' | 'blocked'>('checking');

  useEffect(() => {
    if (!isOpen || !cuveeData) {
      setProgress(0);
      setMessage('Initialisation de la modération...');
      setStatus('checking');
      return;
    }

    const moderateContent = async () => {
      // Vérification du contenu textuel
      const textFieldsToCheck = [
        cuveeData.name,
        cuveeData.description,
        cuveeData.blend,
        cuveeData.vinification,
        cuveeData.storageAdvice,
        cuveeData.tastingNotes
      ].filter(Boolean).join(' ');

      // Vérification des ingrédients
      const ingredientsToCheck = cuveeData.ingredients ? Object.values(cuveeData.ingredients)
        .flat()
        .filter(Boolean)
        .join(' ') : '';

      const contentToCheck = `${textFieldsToCheck} ${ingredientsToCheck}`;
      const violation = checkForBanwords(contentToCheck);

      // Récupérer les informations de l'utilisateur pour le logging
      const userDoc = await getDoc(doc(db, 'users', cuveeData.userId));
      const userData = userDoc.exists() ? userDoc.data() : null;
      const userName = userData ? `${userData.firstName} ${userData.lastName}` : 'Utilisateur inconnu';
      const companyName = userData?.company?.name || 'Entreprise inconnue';

      // Simulation de la progression
      const TOTAL_DURATION = 3000; // 3 secondes
      const UPDATE_INTERVAL = 50;
      const STEPS = TOTAL_DURATION / UPDATE_INTERVAL;
      const INCREMENT = 100 / STEPS;

      let currentProgress = 0;
      const timer = setInterval(() => {
        currentProgress += INCREMENT;
        
        if (currentProgress <= 100) {
          setProgress(currentProgress);

          if (currentProgress < 30) {
            setMessage('Analyse du contenu textuel...');
          } else if (currentProgress < 50) {
            setMessage('Vérification des ingrédients...');
          } else if (currentProgress < 70) {
            setMessage('Vérification des règles de modération...');
          } else if (currentProgress < 90) {
            setMessage('Application des filtres de contenu...');
          } else {
            setMessage('Finalisation de la modération...');
          }
        }

        if (currentProgress >= 100) {
          clearInterval(timer);
          
          if (violation.found) {
            setStatus('blocked');
            setMessage(`Contenu inapproprié détecté dans la catégorie : ${violation.category}`);
            // Log uniquement en cas de blocage
            logModeration(
              cuveeData.userId,
              userName,
              cuveeData.name,
              companyName,
              violation.category,
              violation.words || []
            );
            setTimeout(() => {
              onComplete(false);
              onClose();
            }, 2000);
          } else {
            setStatus('success');
            setTimeout(() => {
              onComplete(true);
              onClose();
            }, 1000);
          }
        }
      }, UPDATE_INTERVAL);

      return () => {
        clearInterval(timer);
      };
    };

    moderateContent();
  }, [isOpen, onClose, onComplete, cuveeData]);

  const getStatusIcon = () => {
    switch (status) {
      case 'checking':
        return <Bot className="h-12 w-12 text-purple-500 animate-pulse" />;
      case 'success':
        return <CheckCircle2 className="h-12 w-12 text-green-500" />;
      case 'blocked':
        return <AlertTriangle className="h-12 w-12 text-red-500" />;
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-[9999] overflow-y-auto">
      <div className="fixed inset-0 bg-black bg-opacity-75 backdrop-blur-sm transition-opacity" />
      
      <div className="flex min-h-screen items-center justify-center p-4">
        <div className="relative w-full max-w-md transform overflow-hidden rounded-lg bg-white p-8 text-center shadow-xl transition-all">
          <div className="mx-auto h-24 w-24 relative mb-6">
            <div className="absolute inset-0 rounded-full border-4 border-purple-100"></div>
            {status === 'checking' && (
              <div
                className="absolute inset-0 rounded-full border-4 border-purple-500 animate-spin"
                style={{ 
                  borderRightColor: 'transparent', 
                  borderTopColor: 'transparent',
                  animationDuration: '2s'
                }}
              ></div>
            )}
            <div className="absolute inset-0 flex items-center justify-center">
              {getStatusIcon()}
            </div>
          </div>
          
          <h3 className="text-xl font-medium text-gray-900 mb-2">
            {status === 'blocked' ? 'Contenu inapproprié détecté' : 'Modération IA'}
          </h3>
          
          <p className="text-sm text-gray-600 mb-6">
            {message}
          </p>

          {status === 'checking' && (
            <>
              <div className="w-full bg-gray-200 rounded-full h-2 mb-2">
                <div
                  className="h-full bg-purple-500 rounded-full transition-all duration-300 ease-out"
                  style={{ width: `${progress}%` }}
                ></div>
              </div>
              
              <p className="text-sm text-gray-500">
                {Math.round(progress)}%
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CuveeModerationModal;