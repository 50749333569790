import { useState, useEffect } from 'react';
import { collection, query, where, getDocs, updateDoc, doc, getDoc, onSnapshot } from 'firebase/firestore';
import { db } from '../lib/firebase';

export interface Notification {
  id: string;
  title: string;
  message: string;
  type: 'info' | 'warning' | 'success' | 'error';
  createdAt: string;
  targetUsers: 'all' | 'premium' | 'standard' | 'basic';
  readBy?: string[];
}

export const useNotifications = (userId: string | undefined, userPlan: string = 'basic') => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!userId) {
      setLoading(false);
      return;
    }

    // Create a query for notifications
    const notificationsRef = collection(db, 'notifications');
    const q = query(notificationsRef, 
      where('targetUsers', 'in', ['all', userPlan])
    );

    // Set up real-time listener
    const unsubscribe = onSnapshot(q, 
      (snapshot) => {
        const notificationsData = snapshot.docs
          .map(doc => ({
            id: doc.id,
            ...doc.data()
          }) as Notification)
          .filter(notification => {
            // Filter out notifications already read by this user
            const readBy = notification.readBy || [];
            return !readBy.includes(userId);
          })
          .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());

        setNotifications(notificationsData);
        setLoading(false);
        setError(null);
      },
      (err) => {
        console.error('Error fetching notifications:', err);
        setError('Une erreur est survenue lors du chargement des notifications');
        setNotifications([]); // Set empty notifications array to prevent UI issues
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [userId, userPlan]);

  const markAsRead = async (notificationId: string) => {
    if (!userId) return;

    try {
      const notificationRef = doc(db, 'notifications', notificationId);
      const notificationDoc = await getDoc(notificationRef);
      
      if (notificationDoc.exists()) {
        const currentReadBy = notificationDoc.data().readBy || [];
        if (!currentReadBy.includes(userId)) {
          await updateDoc(notificationRef, {
            readBy: [...currentReadBy, userId]
          });

          // Update local state
          setNotifications(prev => prev.filter(n => n.id !== notificationId));
        }
      }
    } catch (err) {
      console.error('Error marking notification as read:', err);
      setError('Une erreur est survenue lors du marquage de la notification comme lue');
    }
  };

  return {
    notifications,
    loading,
    error,
    markAsRead,
    refreshNotifications: () => {}, // No need to manually refresh with real-time updates
    unreadCount: notifications.length
  };
};