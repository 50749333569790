import React, { useState } from 'react';
import { LifeBuoy, Plus } from 'lucide-react';
import { useAuthContext } from '../contexts/AuthContext';
import { useTickets } from '../hooks/useTickets';
import type { Ticket } from '../types/ticket';
import Toast, { ToastType } from '../components/Toast';
import { TicketCategoryIcon, getCategoryLabel } from '../components/TicketCategoryIcon';
import ChatDrawer from '../components/ChatDrawer';
import NewTicketModal from '../components/NewTicketModal';
import { formatDateTime } from '../utils/dateUtils';

const Support: React.FC = () => {
  const { user } = useAuthContext();
  const { tickets, loading, markTicketAsRead } = useTickets(user?.uid);
  const [selectedTicket, setSelectedTicket] = useState<Ticket | null>(null);
  const [showNewTicketForm, setShowNewTicketForm] = useState(false);
  const [toast, setToast] = useState<{ message: string; type: ToastType } | null>(null);

  const activeTickets = tickets.filter(ticket => 
    ticket.status !== 'resolved' && ticket.status !== 'closed'
  );

  const archivedTickets = tickets.filter(ticket => 
    ticket.status === 'resolved' || ticket.status === 'closed'
  );

  const handleTicketSelect = async (ticket: Ticket) => {
    setSelectedTicket(ticket);
    if (ticket.unreadMessages) {
      await markTicketAsRead(ticket.id);
    }
  };

  const getStatusIcon = (status: string) => {
    switch (status) {
      case 'open':
        return <div className="h-2 w-2 rounded-full bg-blue-500" />;
      case 'in_progress':
        return <div className="h-2 w-2 rounded-full bg-yellow-500" />;
      case 'resolved':
        return <div className="h-2 w-2 rounded-full bg-green-500" />;
      case 'closed':
        return <div className="h-2 w-2 rounded-full bg-gray-500" />;
      default:
        return null;
    }
  };

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'open':
        return 'bg-blue-100 text-blue-800';
      case 'in_progress':
        return 'bg-yellow-100 text-yellow-800';
      case 'resolved':
        return 'bg-green-100 text-green-800';
      case 'closed':
        return 'bg-gray-100 text-gray-800';
      default:
        return '';
    }
  };

  const showToast = (message: string, type: ToastType) => {
    setToast({ message, type });
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-purple-600"></div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 space-y-8">
      {toast && (
        <Toast
          message={toast.message}
          type={toast.type}
          onClose={() => setToast(null)}
        />
      )}

      <div className="flex justify-between items-center">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">Support</h1>
          <p className="mt-1 text-sm text-gray-600">
            Besoin d'aide ? Notre équipe est là pour vous aider
          </p>
        </div>
        <button
          onClick={() => setShowNewTicketForm(true)}
          disabled={activeTickets.length >= 3}
          className={`btn flex items-center space-x-2 ${
            activeTickets.length >= 3 ? 'opacity-50 cursor-not-allowed' : ''
          }`}
          title={activeTickets.length >= 3 ? 'Limite de 3 tickets actifs atteinte' : ''}
        >
          <Plus className="h-4 w-4" />
          <span>Nouveau ticket</span>
        </button>
      </div>

      {activeTickets.length >= 3 && (
        <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 rounded-lg">
          <div className="flex">
            <div className="flex-shrink-0">
              <LifeBuoy className="h-5 w-5 text-yellow-400" />
            </div>
            <div className="ml-3">
              <p className="text-sm text-yellow-700">
                Vous avez atteint la limite de 3 tickets actifs simultanés. 
                Veuillez attendre qu'un de vos tickets soit résolu avant d'en créer un nouveau.
              </p>
            </div>
          </div>
        </div>
      )}

      <div className="space-y-4">
        {activeTickets.length === 0 ? (
          <div className="text-center py-12 bg-white rounded-lg shadow">
            <LifeBuoy className="mx-auto h-12 w-12 text-gray-400" />
            <h3 className="mt-2 text-sm font-medium text-gray-900">Aucun ticket</h3>
            <p className="mt-1 text-sm text-gray-500">
              Créez votre premier ticket de support
            </p>
          </div>
        ) : (
          activeTickets.map((ticket) => (
            <div
              key={ticket.id}
              onClick={() => handleTicketSelect(ticket)}
              className={`p-4 bg-white rounded-lg shadow cursor-pointer transition-colors ${
                selectedTicket?.id === ticket.id
                  ? 'border-2 border-purple-500'
                  : 'border border-gray-200 hover:border-purple-200'
              }`}
            >
              <div className="flex items-center justify-between mb-2">
                <div className="flex items-center space-x-3">
                  <TicketCategoryIcon category={ticket.category} />
                  <div>
                    <h3 className="font-medium text-gray-900">
                      {ticket.subject}
                      {ticket.unreadMessages > 0 && (
                        <span className="ml-2 inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                          {ticket.unreadMessages}
                        </span>
                      )}
                    </h3>
                    <p className="text-sm text-gray-500">{getCategoryLabel(ticket.category)}</p>
                  </div>
                </div>
                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusColor(ticket.status)}`}>
                  {getStatusIcon(ticket.status)}
                  <span className="ml-1">
                    {ticket.status === 'open' ? 'Ouvert' :
                     ticket.status === 'in_progress' ? 'En cours' :
                     ticket.status === 'resolved' ? 'Résolu' : 'Fermé'}
                  </span>
                </span>
              </div>
              <p className="text-sm text-gray-500">
                Dernière mise à jour : {formatDateTime(ticket.updatedAt)}
              </p>
            </div>
          ))
        )}
      </div>

      {archivedTickets.length > 0 && (
        <div className="mt-8">
          <h2 className="text-lg font-medium text-gray-900 mb-4">Tickets archivés</h2>
          <div className="bg-white rounded-lg shadow overflow-hidden">
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Sujet
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Catégorie
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Statut
                    </th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Dernière mise à jour
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {archivedTickets.map((ticket) => (
                    <tr 
                      key={ticket.id}
                      onClick={() => handleTicketSelect(ticket)}
                      className="hover:bg-gray-50 cursor-pointer"
                    >
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="flex items-center">
                          <TicketCategoryIcon category={ticket.category} className="mr-2" />
                          <span className="text-sm font-medium text-gray-900">
                            {ticket.subject}
                            {ticket.unreadMessages > 0 && (
                              <span className="ml-2 inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                                {ticket.unreadMessages}
                              </span>
                            )}
                          </span>
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className="text-sm text-gray-500">
                          {getCategoryLabel(ticket.category)}
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusColor(ticket.status)}`}>
                          {getStatusIcon(ticket.status)}
                          <span className="ml-1">
                            {ticket.status === 'resolved' ? 'Résolu' : 'Fermé'}
                          </span>
                        </span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                        {formatDateTime(ticket.updatedAt)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}

      <NewTicketModal
        isOpen={showNewTicketForm}
        onClose={() => setShowNewTicketForm(false)}
        userId={user?.uid || ''}
        onSuccess={() => {
          setShowNewTicketForm(false);
          showToast('Ticket créé avec succès', 'success');
        }}
      />

      {selectedTicket && (
        <ChatDrawer
          ticket={selectedTicket}
          onClose={() => setSelectedTicket(null)}
          isAdmin={false}
        />
      )}
    </div>
  );
};

export default Support;