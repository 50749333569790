import React from 'react';
import { ArrowLeft } from 'lucide-react';
import { Link } from 'react-router-dom';

const TermsOfService = () => {
  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <div className="bg-white rounded-xl shadow-sm p-8">
          <Link 
            to="/login"
            className="inline-flex items-center text-sm text-gray-500 hover:text-gray-700 mb-6"
          >
            <ArrowLeft className="h-4 w-4 mr-2" />
            Retour à la connexion
          </Link>

          <div className="border-b border-gray-200 pb-8 mb-8">
            <h1 className="text-3xl font-extrabold text-gray-900">Conditions Générales d'Utilisation</h1>
            <p className="mt-2 text-sm text-gray-600">Dernière mise à jour : 24/11/2024</p>
          </div>

          <div className="prose prose-purple max-w-none">
            <section className="mb-8">
              <h2 className="text-2xl font-bold text-purple-900 mb-4">1. Objet</h2>
              <div className="bg-gray-50 p-6 rounded-lg space-y-4">
                <p className="text-gray-700">
                  Les présentes Conditions Générales d'Utilisation (CGU) régissent les modalités et conditions d'utilisation des services proposés par Qvee (ci-après dénommé "le Service"), édité par Qvee (ci-après dénommée "l'Entreprise"). En accédant et en utilisant le Service, vous acceptez ces CGU dans leur intégralité.
                </p>
              </div>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold text-purple-900 mb-4">2. Acceptation des CGU</h2>
              <div className="bg-gray-50 p-6 rounded-lg space-y-4">
                <p className="text-gray-700">
                  Toute utilisation du Service implique l'acceptation entière et sans réserve des présentes CGU. L'utilisateur (ci-après dénommé "l'Utilisateur") reconnaît avoir pris connaissance des présentes CGU et accepte de s'y conformer.
                </p>
              </div>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold text-purple-900 mb-4">3. Services fournis</h2>
              <div className="bg-gray-50 p-6 rounded-lg space-y-4">
                <p className="text-gray-700">
                  Qvee permet à l'Utilisateur de créer et de publier des pages web en ligne, accessibles via des QR Codes générés par le Service. L'Entreprise met à disposition plusieurs plans tarifaires, chacun offrant des fonctionnalités spécifiques, telles que la génération de QR Codes, la publication de pages web, et la gestion des statistiques d'accès.
                </p>
              </div>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold text-purple-900 mb-4">4. Accès au Service</h2>
              <div className="bg-gray-50 p-6 rounded-lg space-y-4">
                <p className="text-gray-700">
                  L'accès au Service est conditionné à la création d'un compte par l'Utilisateur. L'Utilisateur s'engage à fournir des informations exactes et à les maintenir à jour. L'Utilisateur est responsable de la confidentialité de ses identifiants de connexion et s'engage à informer immédiatement l'Entreprise en cas de suspicion de perte, de vol ou d'utilisation frauduleuse de son compte.
                </p>
              </div>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold text-purple-900 mb-4">5. Utilisation des Services</h2>
              <div className="bg-gray-50 p-6 rounded-lg space-y-4">
                <p className="text-gray-700">L'Utilisateur s'engage à utiliser le Service de manière conforme aux lois en vigueur et aux présentes CGU. À ce titre, l'Utilisateur s'interdit notamment :</p>
                <ul className="list-disc list-inside text-gray-700 space-y-2">
                  <li>De publier tout contenu à caractère illégal, diffamatoire, offensant, obscène, pornographique, violent, haineux, raciste, ou tout autre contenu contraire à l'ordre public et aux bonnes mœurs.</li>
                  <li>D'utiliser plusieurs comptes pour bénéficier des services de Qvee, sous peine de résiliation immédiate de tous les comptes concernés.</li>
                  <li>D'exploiter toute faille ou bug du Service pour en tirer un avantage quelconque.</li>
                  <li>De publier des images, vidéos, ou tout autre média à caractère malsain, choquant ou offensant.</li>
                  <li>D'utiliser le Service pour toute activité frauduleuse, illégale ou non autorisée.</li>
                  <li>De partager son compte avec d'autres personnes pour publier des cuvées appartenant à un autre propriétaire.</li>
                </ul>
              </div>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold text-purple-900 mb-4">6. Responsabilités de l'Utilisateur</h2>
              <div className="bg-gray-50 p-6 rounded-lg space-y-4">
                <p className="text-gray-700">
                  L'Utilisateur est seul responsable du contenu publié via le Service. L'Entreprise se réserve le droit de supprimer sans préavis tout contenu jugé non conforme aux présentes CGU. En cas de manquement aux obligations décrites, l'Entreprise pourra, à sa seule discrétion, suspendre ou résilier le compte de l'Utilisateur sans préavis et sans droit à indemnisation.
                </p>
              </div>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold text-purple-900 mb-4">7. Suspension et résiliation du compte</h2>
              <div className="bg-gray-50 p-6 rounded-lg space-y-4">
                <p className="text-gray-700">
                  L'Entreprise se réserve le droit de suspendre ou de résilier le compte de l'Utilisateur, sans préavis ni indemnité, en cas de non-respect des présentes CGU ou de toute utilisation abusive ou inappropriée du Service. La résiliation entraîne la suppression immédiate de l'accès au Service et de toutes les données associées au compte.
                </p>
              </div>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold text-purple-900 mb-4">8. Propriété intellectuelle</h2>
              <div className="bg-gray-50 p-6 rounded-lg space-y-4">
                <p className="text-gray-700">
                  Tous les éléments du Service, y compris, sans s'y limiter, les textes, images, graphismes, logos, interfaces utilisateurs, design, technologie, et autres contenus, sont protégés par des droits de propriété intellectuelle. L'Utilisateur n'est pas autorisé à copier, modifier, distribuer, vendre ou utiliser de quelque manière que ce soit ces éléments sans l'autorisation préalable écrite de l'Entreprise.
                </p>
              </div>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold text-purple-900 mb-4">9. Limitation de responsabilité</h2>
              <div className="bg-gray-50 p-6 rounded-lg space-y-4">
                <p className="text-gray-700">
                  L'Entreprise s'engage à fournir le Service avec le soin raisonnable attendu d'un professionnel. Toutefois, l'Utilisateur reconnaît que le Service est fourni "en l'état" et que l'Entreprise ne garantit pas que le Service sera exempt d'erreurs ou d'interruptions. L'Entreprise ne saurait être tenue responsable de tout dommage direct ou indirect résultant de l'utilisation ou de l'impossibilité d'utiliser le Service.
                </p>
              </div>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold text-purple-900 mb-4">10. Confidentialité et protection des données</h2>
              <div className="bg-gray-50 p-6 rounded-lg space-y-4">
                <p className="text-gray-700">
                  Les données personnelles de l'Utilisateur sont traitées conformément à la Politique de Confidentialité de Qvee. L'Utilisateur dispose d'un droit d'accès, de rectification, d'opposition et de suppression de ses données personnelles, conformément à la réglementation en vigueur.
                </p>
              </div>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold text-purple-900 mb-4">11. Modifications des CGU</h2>
              <div className="bg-gray-50 p-6 rounded-lg space-y-4">
                <p className="text-gray-700">
                  L'Entreprise se réserve le droit de modifier à tout moment les présentes CGU. Toute modification sera notifiée à l'Utilisateur par email ou lors de sa prochaine connexion au Service. Les nouvelles CGU seront réputées acceptées si l'Utilisateur continue d'utiliser le Service après leur entrée en vigueur.
                </p>
              </div>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold text-purple-900 mb-4">12. Loi applicable et juridiction compétente</h2>
              <div className="bg-gray-50 p-6 rounded-lg space-y-4">
                <p className="text-gray-700">
                  Les présentes CGU sont régies par la loi française. En cas de litige, les parties s'engagent à rechercher une solution amiable. À défaut d'accord, les tribunaux compétents seront ceux de la juridiction du siège social de l'Entreprise.
                </p>
              </div>
            </section>

            <section className="mb-8">
              <h2 className="text-2xl font-bold text-purple-900 mb-4">13. Contact</h2>
              <div className="bg-gray-50 p-6 rounded-lg space-y-4">
                <p className="text-gray-700">
                  Pour toute question relative aux présentes CGU, l'Utilisateur peut contacter l'Entreprise aux coordonnées suivantes :
                </p>
                <ul className="text-gray-700 space-y-2">
                  <li>Téléphone : 09 77 21 99 98</li>
                  <li>Email : contact@qvee.fr</li>
                  <li>Adresse : 36 rue du ranc, 30630 Saint André de Roquepertuis, France</li>
                </ul>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsOfService;