import { 
  collection, 
  addDoc, 
  Timestamp,
  query,
  where,
  getDocs,
  deleteDoc,
  orderBy,
  limit 
} from 'firebase/firestore';
import { db } from './firebase';

// Durée de rétention des logs (180 jours)
const LOG_RETENTION_DAYS = 180;

export type LogType = 'auth' | 'payment' | 'cuvee' | 'moderation' | 'security';

interface LogData {
  type: LogType;
  action: string;
  userId?: string;
  userName: string;
  details: string;
  metadata?: {
    userName?: string;
    companyName?: string;
    cuveeName?: string;
    category?: string;
    words?: string[];
    email?: string;
    attempts?: number;
    duration?: string;
  };
}

export const addServerLog = async (data: LogData) => {
  try {
    const logsRef = collection(db, 'activityLogs');
    await addDoc(logsRef, {
      ...data,
      createdAt: Timestamp.now(),
      expiresAt: Timestamp.fromDate(
        new Date(Date.now() + LOG_RETENTION_DAYS * 24 * 60 * 60 * 1000)
      )
    });
  } catch (error) {
    console.error('Error adding server log:', error);
  }
};

// Fonction pour nettoyer les anciens logs
export const cleanupExpiredLogs = async () => {
  try {
    const logsRef = collection(db, 'activityLogs');
    const expiredLogsQuery = query(
      logsRef,
      where('expiresAt', '<=', Timestamp.now())
    );

    const snapshot = await getDocs(expiredLogsQuery);
    const deletePromises = snapshot.docs.map(doc => deleteDoc(doc.ref));
    await Promise.all(deletePromises);

    console.log(`Cleaned up ${snapshot.size} expired logs`);
  } catch (error) {
    console.error('Error cleaning up expired logs:', error);
  }
};

// Fonctions d'aide pour les logs courants
export const logAuthentication = async (userId: string, userName: string, action: string) => {
  await addServerLog({
    type: 'auth',
    action,
    userId,
    userName,
    details: `${action} pour l'utilisateur ${userName}`
  });
};

export const logPayment = async (userId: string, userName: string, plan: string, amount: number) => {
  await addServerLog({
    type: 'payment',
    action: 'Paiement',
    userId,
    userName,
    details: `Paiement de ${amount}€ pour le plan ${plan}`
  });
};

export const logCuveeAction = async (userId: string, userName: string, action: string, cuveeName: string) => {
  await addServerLog({
    type: 'cuvee',
    action,
    userId,
    userName,
    details: `${action} de la cuvée "${cuveeName}"`
  });
};

export const logModeration = async (
  userId: string,
  userName: string,
  cuveeName: string,
  companyName: string,
  category: string,
  words: string[]
) => {
  await addServerLog({
    type: 'moderation',
    action: 'Modération IA',
    userId,
    userName,
    details: `Blocage de la cuvée "${cuveeName}"`,
    metadata: {
      userName,
      companyName,
      cuveeName,
      category,
      words
    }
  });
};

export const logAntispamBlock = async (email: string, attempts: number) => {
  await addServerLog({
    type: 'security',
    action: 'Blocage antispam',
    userName: email,
    details: `Compte temporairement bloqué pour trop de tentatives de connexion`,
    metadata: {
      email,
      attempts,
      duration: '10 minutes'
    }
  });
};

// Fonction pour récupérer les logs avec pagination
export const fetchLogs = async (page: number, limit: number) => {
  try {
    const logsRef = collection(db, 'activityLogs');
    const q = query(
      logsRef,
      orderBy('createdAt', 'desc'),
      limit(limit)
    );

    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
  } catch (error) {
    console.error('Error fetching logs:', error);
    throw error;
  }
};