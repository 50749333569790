import React from 'react';
import { Info } from 'lucide-react';
import CertificationImage from '../CertificationImage';
import type { CuveeFormData } from '../CuveeForm';

interface CuveeQualityLabelsProps {
  data: CuveeFormData;
  onChange: (updates: Partial<CuveeFormData>) => void;
}

const certifications = [
  {
    id: 'vigneron-independant',
    name: 'Vigneron Indépendant',
    description: 'Label des vignerons qui cultivent leurs vignes, vinifient et commercialisent leurs vins.'
  },
  {
    id: 'hve',
    name: 'Haute Valeur Environnementale',
    description: 'Certification environnementale des exploitations agricoles.'
  },
  {
    id: 'terra-vitis',
    name: 'Terra Vitis',
    description: 'Certification environnementale spécifique à la viticulture.'
  },
  {
    id: 'viticulture-durable',
    name: 'Viticulture Durable en Champagne',
    description: 'Certification de viticulture durable spécifique à la Champagne.'
  },
  {
    id: 'biodyvin',
    name: 'Biodyvin',
    description: 'Label de viticulture biodynamique.'
  },
  {
    id: 'demeter',
    name: 'Demeter',
    description: 'Label international de l\'agriculture biodynamique.'
  },
  {
    id: 'demeter-certifie',
    name: 'Demeter Certifié',
    description: 'Certification Demeter officielle.'
  },
  {
    id: 'avn',
    name: 'AVN Association des Vins Naturels',
    description: 'Label des vins naturels.'
  },
  {
    id: 'sains',
    name: 'S.A.I.N.S',
    description: 'Sans Aucun Intrant Ni Sulfite (ajouté).'
  },
  {
    id: 'nature-progres',
    name: 'Nature & Progrès',
    description: 'Label bio associatif avec un cahier des charges strict.'
  },
  {
    id: 'bio-coherence',
    name: 'Bio Cohérence',
    description: 'Label bio français plus exigeant que le label AB.'
  },
  {
    id: 'ab',
    name: 'Agriculture Biologique',
    description: 'Label officiel de l\'agriculture biologique.'
  }
];

const CuveeQualityLabels: React.FC<CuveeQualityLabelsProps> = ({ data, onChange }) => {
  const toggleCertification = (certificationId: string) => {
    const currentCertifications = data.certifications || [];
    const newCertifications = currentCertifications.includes(certificationId)
      ? currentCertifications.filter(id => id !== certificationId)
      : [...currentCertifications, certificationId];
    
    onChange({ certifications: newCertifications });
  };

  return (
    <div className="space-y-6">
      <div className="bg-yellow-50 border border-yellow-200 rounded-lg p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <Info className="h-5 w-5 text-yellow-400" />
          </div>
          <div className="ml-3">
            <p className="text-sm text-yellow-700">
              Sélectionnez les certifications et labels qualité obtenus par cette cuvée. Ces informations seront affichées sur la page publique.
            </p>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {certifications.map((certification) => (
          <div
            key={certification.id}
            className={`relative flex flex-col items-center p-6 border-2 rounded-lg transition-colors cursor-pointer ${
              data.certifications?.includes(certification.id)
                ? 'border-purple-500 bg-purple-50'
                : 'border-gray-200 hover:border-purple-300'
            }`}
            onClick={() => toggleCertification(certification.id)}
          >
            <CertificationImage
              name={certification.id}
              className="h-24 w-auto mb-4 object-contain"
            />
            <h3 className="text-lg font-medium text-gray-900 text-center mb-2">
              {certification.name}
            </h3>
            <p className="text-sm text-gray-500 text-center">
              {certification.description}
            </p>
            <div
              className={`absolute top-3 right-3 h-4 w-4 rounded-full border ${
                data.certifications?.includes(certification.id)
                  ? 'bg-purple-500 border-purple-500'
                  : 'bg-white border-gray-300'
              }`}
            >
              {data.certifications?.includes(certification.id) && (
                <svg
                  className="h-4 w-4 text-white"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path d="M5 13l4 4L19 7" />
                </svg>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CuveeQualityLabels;