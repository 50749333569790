import React, { useState, useEffect } from 'react';
import { Download, QrCode, Clock, Lock, ArrowRight } from 'lucide-react';
import { useCuvees } from '../../hooks/useCuvees';
import { useQRCodes } from '../../hooks/useQRCodes';
import { useAuthContext } from '../../contexts/AuthContext';
import { usePlanLimits } from '../../hooks/usePlanLimits';
import QRCodePreview from '../../components/QRCodePreview';
import QRCodeSettings from '../../components/QRCodeSettings';
import QRCodeHistory from '../../components/QRCodeHistory';
import Toast, { ToastType } from '../../components/Toast';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { PagePublisher } from '../../services/pagePublisher';
import { Link } from 'react-router-dom';

const QRCodeGenerator: React.FC = () => {
  const { user } = useAuthContext();
  const { cuvees, loading: cuveesLoading } = useCuvees(user?.uid);
  const { qrCodes, loading: qrCodesLoading, saveQRCode, deleteQRCode, handleQRCodeDownload } = useQRCodes(user?.uid);
  const { currentPlan } = usePlanLimits(user?.uid);
  const [selectedCuvee, setSelectedCuvee] = useState('');
  const [qrColor, setQrColor] = useState('#000000');
  const [bgColor, setBgColor] = useState('#FFFFFF');
  const [size, setSize] = useState('256');
  const [style, setStyle] = useState<'classic' | 'rounded' | 'dots'>('classic');
  const [isGenerating, setIsGenerating] = useState(false);
  const [toast, setToast] = useState<{ message: string; type: ToastType } | null>(null);
  const [companySlug, setCompanySlug] = useState<string | null>(null);
  const [userStatus, setUserStatus] = useState<string>('active');
  const [userPlan, setUserPlan] = useState<'basic' | 'standard' | 'premium'>('basic');

  // Filter out cuvees that already have QR codes
  const availableCuvees = cuvees.filter(cuvee =>
    cuvee.status === 'published' && !qrCodes.some(qr => qr.cuveeId === cuvee.id)
  );

  useEffect(() => {
    const fetchUserData = async () => {
      if (!user?.uid) return;
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setUserStatus(userData.status || 'active');
        setUserPlan(userData.plan || 'basic');
      }
    };
    fetchUserData();
  }, [user]);

  useEffect(() => {
    const fetchCompanySlug = async () => {
      if (!user?.uid) return;

      try {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          const companyName = userData.company?.name || '';
          const slug = PagePublisher.generatePageSlug(companyName);
          setCompanySlug(slug);
        }
      } catch (error) {
        console.error('Error fetching company info:', error);
        showToast('Erreur lors de la récupération des informations de l\'entreprise', 'error');
      }
    };

    fetchCompanySlug();
  }, [user]);

  const showToast = (message: string, type: ToastType) => {
    setToast({ message, type });
  };

  const handleDownload = (qrCode: any) => {
    const svg = document.querySelector(`#qr-code-${qrCode.id} svg`);
    if (!svg) return;

    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();

    img.onload = () => {
      canvas.width = qrCode.settings.size;
      canvas.height = qrCode.settings.size;
      ctx?.drawImage(img, 0, 0);
      
      const pngFile = canvas.toDataURL('image/png');
      const downloadLink = document.createElement('a');
      downloadLink.download = `qr-code-${qrCode.cuveeName}.png`;
      downloadLink.href = pngFile;
      downloadLink.click();
    };

    img.src = 'data:image/svg+xml;base64,' + btoa(svgData);
  };

  const handleDeleteQRCode = async (qrCodeId: string) => {
    try {
      await deleteQRCode(qrCodeId);
      showToast('QR code supprimé avec succès', 'success');
    } catch (error) {
      console.error('Error deleting QR code:', error);
      showToast('Erreur lors de la suppression du QR code', 'error');
    }
  };

  const getQRCodeValue = () => {
    if (!selectedCuvee || !companySlug) return '';
    
    const cuvee = cuvees.find(c => c.id === selectedCuvee);
    if (!cuvee) return '';

    const cuveeSlug = PagePublisher.generatePageSlug(cuvee.name);
    return `https://app.qvee.fr/${companySlug}/${cuveeSlug}`;
  };

  const handleGenerateQRCode = async () => {
    if (!selectedCuvee || !user || !companySlug) return;
    
    const cuvee = cuvees.find(c => c.id === selectedCuvee);
    if (!cuvee) return;

    try {
      setIsGenerating(true);
      const qrCodeData = {
        userId: user.uid,
        cuveeId: cuvee.id,
        cuveeName: cuvee.name,
        cuveeYear: cuvee.vintage,
        url: getQRCodeValue(),
        settings: {
          size: parseInt(size),
          fgColor: qrColor,
          bgColor: bgColor,
          style: style
        },
        createdAt: new Date().toISOString()
      };

      await saveQRCode(qrCodeData);
      showToast('QR code généré avec succès', 'success');
      setSelectedCuvee(''); // Reset selection after successful generation
    } catch (error) {
      console.error('Error generating QR code:', error);
      showToast('Erreur lors de la génération du QR code', 'error');
    } finally {
      setIsGenerating(false);
    }
  };

  if (cuveesLoading || qrCodesLoading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-purple-600"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {toast && (
        <Toast
          message={toast.message}
          type={toast.type}
          onClose={() => setToast(null)}
        />
      )}

      <div>
        <h1 className="text-2xl font-bold text-gray-900">Générateur de QR Code</h1>
        <p className="mt-1 text-sm text-gray-600">
          Créez des QR codes personnalisés pour vos cuvées
        </p>
      </div>

      {userStatus === 'pending_deletion' && (
        <div className="bg-red-50 border-l-4 border-red-400 p-4 rounded-lg">
          <div className="flex">
            <div className="flex-shrink-0">
              <Clock className="h-5 w-5 text-red-400" />
            </div>
            <div className="ml-3">
              <p className="text-sm text-red-700">
                Votre compte est en cours de suppression. La génération de nouveaux QR codes est désactivée.
              </p>
            </div>
          </div>
        </div>
      )}

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div className="bg-white rounded-lg shadow p-6">
          <QRCodeSettings
            selectedCuvee={selectedCuvee}
            cuvees={availableCuvees}
            qrColor={qrColor}
            bgColor={bgColor}
            size={size}
            onCuveeChange={setSelectedCuvee}
            onQRColorChange={setQrColor}
            onBGColorChange={setBgColor}
            onSizeChange={setSize}
            userPlan={currentPlan}
          />
        </div>

        <div className="bg-white rounded-lg shadow p-6 space-y-6">
          <QRCodePreview
            value={getQRCodeValue()}
            size={parseInt(size)}
            bgColor={bgColor}
            fgColor={qrColor}
            isSelected={!!selectedCuvee}
            style={style}
          />

          <div className="flex justify-end">
            <button
              disabled={!selectedCuvee || isGenerating || userStatus === 'pending_deletion'}
              onClick={handleGenerateQRCode}
              className={`btn disabled:opacity-50 disabled:cursor-not-allowed flex items-center space-x-2 ${
                userStatus === 'pending_deletion' ? 'bg-gray-400 hover:bg-gray-400' : ''
              }`}
            >
              {userStatus === 'pending_deletion' ? (
                <>
                  <Clock className="h-4 w-4" />
                  <span>Compte en suppression</span>
                </>
              ) : (
                <>
                  <Download className="h-4 w-4" />
                  <span>{isGenerating ? 'Génération...' : 'Générer'}</span>
                </>
              )}
            </button>
          </div>
        </div>
      </div>

      <QRCodeHistory
        qrCodes={qrCodes}
        onDownload={handleDownload}
        onDelete={handleDeleteQRCode}
        userId={user?.uid || ''}
        onQRCodeDownload={handleQRCodeDownload}
        userPlan={currentPlan}
      />
    </div>
  );
};

export default QRCodeGenerator;