import React, { useState, useEffect, useRef } from 'react';
import { X, Send } from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import type { Ticket } from '../types/ticket';
import { TicketCategoryIcon, getCategoryLabel } from './TicketCategoryIcon';
import { doc, onSnapshot, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useAuthContext } from '../contexts/AuthContext';

interface ChatDrawerProps {
  ticket: Ticket | null;
  onClose: () => void;
  isAdmin: boolean;
}

const ChatDrawer: React.FC<ChatDrawerProps> = ({ 
  ticket, 
  onClose,
  isAdmin
}) => {
  const { user } = useAuthContext();
  const [newMessage, setNewMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentTicket, setCurrentTicket] = useState<Ticket | null>(ticket);
  const [error, setError] = useState<string | null>(null);
  const [userName, setUserName] = useState<string>('');
  const messagesEndRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setCurrentTicket(ticket);
  }, [ticket]);

  useEffect(() => {
    if (!user) return;

    const fetchUserName = async () => {
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setUserName(`${userData.firstName} ${userData.lastName}`);
      }
    };

    fetchUserName();
  }, [user]);

  useEffect(() => {
    if (!ticket) return;

    const unsubscribe = onSnapshot(doc(db, 'tickets', ticket.id), (doc) => {
      if (doc.exists()) {
        setCurrentTicket({
          id: doc.id,
          ...doc.data()
        } as Ticket);
      }
    });

    return () => unsubscribe();
  }, [ticket?.id]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [currentTicket?.messages]);

  const handleStatusChange = async (newStatus: string) => {
    if (!currentTicket) return;

    try {
      const ticketRef = doc(db, 'tickets', currentTicket.id);
      await updateDoc(ticketRef, {
        status: newStatus,
        updatedAt: new Date().toISOString()
      });
    } catch (error) {
      console.error('Error updating ticket status:', error);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!currentTicket || !user || !newMessage.trim() || isSubmitting) return;

    try {
      setIsSubmitting(true);
      setError(null);

      const ticketRef = doc(db, 'tickets', currentTicket.id);
      const message = {
        id: Date.now().toString(),
        content: newMessage.trim(),
        userId: isAdmin ? 'admin' : user.uid,
        userName: isAdmin ? 'Support' : userName,
        isAdmin,
        createdAt: new Date().toISOString()
      };

      const updateData = {
        messages: [...currentTicket.messages, message],
        updatedAt: new Date().toISOString(),
        status: currentTicket.status === 'open' ? 'in_progress' : currentTicket.status,
        lastReadTimestamp: {
          ...(currentTicket.lastReadTimestamp || {}),
          [isAdmin ? 'admin' : user.uid]: new Date().toISOString()
        }
      };

      await updateDoc(ticketRef, updateData);
      setNewMessage('');
    } catch (error) {
      console.error('Error sending message:', error);
      setError('Erreur lors de l\'envoi du message');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!currentTicket) return null;

  return (
    <div className="fixed inset-y-0 right-0 w-96 bg-white shadow-xl z-50 flex flex-col">
      <div className="p-4 border-b">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center space-x-3">
            <TicketCategoryIcon category={currentTicket.category} />
            <div>
              <h2 className="text-lg font-medium text-gray-900">
                {currentTicket.subject}
              </h2>
              <p className="text-sm text-gray-500">
                {getCategoryLabel(currentTicket.category)}
              </p>
            </div>
          </div>
          <button
            onClick={onClose}
            className="p-2 rounded-full hover:bg-gray-100 transition-colors"
          >
            <X className="h-5 w-5 text-gray-500" />
          </button>
        </div>

        {isAdmin && (
          <select
            value={currentTicket.status}
            onChange={(e) => handleStatusChange(e.target.value)}
            className="w-full rounded-lg border border-gray-300 px-3 py-2 text-sm focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
          >
            <option value="open">Ouvert</option>
            <option value="in_progress">En cours</option>
            <option value="resolved">Résolu</option>
            <option value="closed">Fermé</option>
          </select>
        )}
      </div>

      <div className="flex-1 overflow-y-auto p-4 space-y-4">
        {currentTicket.messages.map((message) => (
          <div
            key={message.id}
            className={`flex ${message.isAdmin ? 'justify-start' : 'justify-end'}`}
          >
            <div className={`max-w-[80%] rounded-lg p-4 ${
              message.isAdmin 
                ? 'bg-gray-100 text-gray-900' 
                : 'bg-purple-100 text-purple-900'
            }`}>
              <div className="text-sm font-medium mb-1">
                {message.userName}
              </div>
              <ReactMarkdown className="text-sm prose prose-sm max-w-none">
                {message.content}
              </ReactMarkdown>
            </div>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>

      <form onSubmit={handleSubmit} className="p-4 border-t">
        <div className="flex space-x-4">
          <input
            type="text"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder="Votre message..."
            className="flex-1 rounded-lg border border-gray-300 px-4 py-2 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:border-transparent"
          />
          <button
            type="submit"
            disabled={!newMessage.trim() || isSubmitting}
            className="btn flex items-center space-x-2 disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <Send className="h-4 w-4" />
            <span>{isSubmitting ? 'Envoi...' : 'Envoyer'}</span>
          </button>
        </div>
        {error && (
          <p className="mt-2 text-sm text-red-600">{error}</p>
        )}
      </form>
    </div>
  );
};

export default ChatDrawer;