import { useState, useEffect } from 'react';
import { doc, getDoc, collection, query, where, getDocs, updateDoc, onSnapshot } from 'firebase/firestore';
import { db } from '../lib/firebase';

export interface PlanLimits {
  basic: number;
  standard: number;
  premium: number;
  gold: number;
}

export const PLAN_LIMITS: PlanLimits = {
  basic: 5,
  standard: Infinity,
  premium: Infinity,
  gold: Infinity
};

export type PlanType = 'basic' | 'standard' | 'premium' | 'gold';

export const PLAN_FEATURES = {
  basic: {
    customQrCodes: false,
    unlimitedCuvees: false,
    dashboard: false,
    support: false
  },
  standard: {
    customQrCodes: false,
    unlimitedCuvees: true,
    dashboard: false,
    support: false
  },
  premium: {
    customQrCodes: true,
    unlimitedCuvees: true,
    dashboard: true,
    design: true,
    goldAccess: true,
    support: true
  },
  gold: {
    customQrCodes: true,
    unlimitedCuvees: true,
    dashboard: true,
    design: true,
    goldAccess: true,
    support: true
  }
};
export const usePlanLimits = (userId: string | undefined) => {
  const [currentPlan, setCurrentPlan] = useState<PlanType>('basic');
  const [publishedCount, setPublishedCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (!userId) {
      setLoading(false);
      return;
    }

    // Écouter les changements du plan de l'utilisateur
    const userUnsubscribe = onSnapshot(doc(db, 'users', userId), (userDoc) => {
      if (userDoc.exists()) {
        setCurrentPlan(userDoc.data().plan || 'basic');
      }
    });

    // Écouter les changements dans la collection des cuvées
    const cuveesUnsubscribe = onSnapshot(
      query(
        collection(db, 'cuvees'),
        where('userId', '==', userId),
        where('status', 'in', ['published', 'blocked'])
      ),
      (snapshot) => {
        setPublishedCount(snapshot.size);
        setLoading(false);
      },
      (err) => {
        console.error('Error fetching cuvees count:', err);
        setError(err.message);
        setLoading(false);
      }
    );

    return () => {
      userUnsubscribe();
      cuveesUnsubscribe();
    };
  }, [userId]);

  const handlePlanDowngrade = async (newPlan: 'basic' | 'standard') => {
    if (!userId) return;

    try {
      const cuveesRef = collection(db, 'cuvees');
      const q = query(
        cuveesRef,
        where('userId', '==', userId),
        where('status', 'in', ['published', 'blocked'])
      );
      const snapshot = await getDocs(q);
      const cuvees = snapshot.docs;

      // Calculer combien de cuvées doivent être dépubliées
      const limit = PLAN_LIMITS[newPlan];
      const toUnpublish = cuvees
        .filter(doc => doc.data().status === 'published') // Ne pas toucher aux cuvées bloquées
        .slice(limit);

      // Dépublier les cuvées excédentaires
      for (const cuveeDoc of toUnpublish) {
        await updateDoc(doc(db, 'cuvees', cuveeDoc.id), {
          status: 'plan_limited',
          lastModified: new Date().toISOString()
        });
      }

      return toUnpublish.length;
    } catch (err) {
      console.error('Error handling plan downgrade:', err);
      throw err;
    }
  };

  const canPublishMore = () => {
    const limit = PLAN_LIMITS[currentPlan];
    return publishedCount < limit;
  };

  const getRemainingSlots = () => {
    const limit = PLAN_LIMITS[currentPlan];
    if (limit === Infinity) return Infinity;
    return Math.max(0, limit - publishedCount);
  };

  const hasFeature = (feature: keyof typeof PLAN_FEATURES[keyof typeof PLAN_FEATURES]) => {
    return PLAN_FEATURES[currentPlan][feature];
  };
  const getCurrentLimit = () => {
    return PLAN_LIMITS[currentPlan];
  };

  return {
    currentPlan,
    publishedCount,
    loading,
    error,
    canPublishMore,
    getRemainingSlots,
    getCurrentLimit,
    handlePlanDowngrade,
    hasFeature,
    PLAN_LIMITS
  };
};