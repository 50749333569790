import React from 'react';
import { ArrowLeft } from 'lucide-react';
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <div className="bg-white rounded-xl shadow-sm p-8">
          <Link 
            to="/login"
            className="inline-flex items-center text-sm text-gray-500 hover:text-gray-700 mb-6"
          >
            <ArrowLeft className="h-4 w-4 mr-2" />
            Retour à la connexion
          </Link>

          <div className="border-b border-gray-200 pb-8 mb-8">
            <h1 className="text-3xl font-extrabold text-gray-900">
              Politique de Confidentialité et de Remboursement
            </h1>
            <p className="mt-2 text-sm text-gray-600">Dernière mise à jour : {new Date().toLocaleDateString()}</p>
          </div>

          <div className="prose prose-purple max-w-none space-y-8">
            <div className="bg-gray-50 p-6 rounded-lg">
              <p className="text-gray-700">
                Qvee (ci-après dénommé "l'Entreprise" ou "nous") s'engage à protéger la confidentialité des données personnelles de ses utilisateurs (ci-après dénommés "les Utilisateurs" ou "vous") et à respecter la réglementation en matière de remboursements, conformément aux lois françaises en vigueur. La présente page décrit la manière dont nous collectons, utilisons et protégeons vos données, ainsi que les modalités de remboursement applicables à nos services.
              </p>
            </div>

            <div className="bg-gray-50 p-6 rounded-lg">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">1. Collecte des données personnelles</h2>
              <p className="text-gray-700 mb-4">
                Les données personnelles que nous collectons sont celles nécessaires à l'utilisation de nos services en tant que client, notamment :
              </p>
              <ul className="list-disc list-inside text-gray-700 space-y-2">
                <li>Nom et prénom</li>
                <li>Adresse email</li>
                <li>Informations de contact (adresse postale, numéro de téléphone)</li>
                <li>Informations de facturation et de paiement</li>
                <li>Historique d'utilisation du Service (QR Codes créés, statistiques de pages, etc.)</li>
              </ul>
            </div>

            <div className="bg-gray-50 p-6 rounded-lg">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">2. Utilisation des données</h2>
              <p className="text-gray-700 mb-4">Les informations collectées sont utilisées pour :</p>
              <ul className="list-disc list-inside text-gray-700 space-y-2">
                <li>Fournir et gérer les services que nous proposons</li>
                <li>Permettre aux clients d'accéder à des statistiques sur les pages publiées</li>
                <li>Améliorer l'expérience utilisateur et le fonctionnement du Service</li>
                <li>Assurer la sécurité des données</li>
                <li>Respecter les obligations légales et réglementaires en vigueur</li>
              </ul>
            </div>

            <div className="bg-gray-50 p-6 rounded-lg">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">3. Suivi des données lors des scans de QR Codes</h2>
              <p className="text-gray-700 mb-4">
                Lorsque des QR Codes générés via notre service sont scannés par des utilisateurs non-clients, certaines données sont collectées pour offrir des statistiques à nos clients. Ces données incluent :
              </p>
              <ul className="list-disc list-inside text-gray-700 space-y-2">
                <li>La date et l'heure du scan</li>
                <li>La localisation géographique approximative (si autorisée)</li>
                <li>Le type de dispositif utilisé pour le scan</li>
              </ul>
            </div>

            <div className="bg-gray-50 p-6 rounded-lg">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">4. Partage des données</h2>
              <p className="text-gray-700 mb-4">
                Nous ne partageons pas vos données personnelles avec des tiers, sauf si cela est nécessaire pour :
              </p>
              <ul className="list-disc list-inside text-gray-700 space-y-2">
                <li>La fourniture des services (par exemple, services de paiement)</li>
                <li>Le respect d'obligations légales ou réglementaires</li>
                <li>La protection de nos droits ou ceux des autres Utilisateurs</li>
              </ul>
            </div>

            <div className="bg-gray-50 p-6 rounded-lg">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">5. Sécurité des données</h2>
              <p className="text-gray-700">
                La sécurité de vos données est une priorité pour nous. Nous utilisons les services de Firebase (Firestore et Firestorage) pour héberger et sécuriser vos données. Firebase offre des mesures de sécurité robustes pour protéger les informations personnelles, y compris le chiffrement des données en transit et au repos. Nous mettons également en œuvre des contrôles d'accès rigoureux pour garantir que seules les personnes autorisées peuvent accéder aux données personnelles.</p>
              <ul className="list-disc list-inside text-gray-700 space-y-2">
                <li>Pour en savoir plus sur la politique de confidentialité et les conditions de Firebase, veuillez consulter la Politique de Confidentialité de Firebase.</li>
              </ul>
            </div>

            <div className="bg-gray-50 p-6 rounded-lg">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">6. Conservation des données</h2>
              <p className="text-gray-700">
                Vos données personnelles sont conservées aussi longtemps que nécessaire pour atteindre les finalités décrites dans cette Politique de Confidentialité, ou pour se conformer à nos obligations légales, comptables ou de déclaration. Lorsque les données ne sont plus nécessaires, elles sont supprimées de manière sécurisée.
              </p>
            </div>

            <div className="bg-gray-50 p-6 rounded-lg">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">7. Droits des Utilisateurs</h2>
              <p className="text-gray-700 mb-4">
                Conformément à la réglementation en vigueur, notamment le Règlement Général sur la Protection des Données (RGPD), vous disposez des droits suivants concernant vos données personnelles :
              </p>
              <ul className="list-disc list-inside text-gray-700 space-y-2">
                <li><b>Droit d'accès</b> : vous pouvez demander à accéder aux données que nous détenons à votre sujet.</li>
                <li><b>Droit de rectification</b> : vous pouvez demander la correction de données inexactes ou incomplètes.</li>
                <li><b>Droit de suppression</b> : vous pouvez demander la suppression de vos données personnelles, sous réserve de nos obligations légales.</li>
                <li><b>Droit d'opposition</b> : vous pouvez vous opposer au traitement de vos données personnelles dans certaines circonstances.</li>
                <li><b>Droit de portabilité</b> : vous pouvez demander à recevoir vos données personnelles dans un format structuré et couramment utilisé.</li>
              </ul>
              <p>Pour exercer vos droits, veuillez nous contacter à l'adresse suivante :</p>
               <ul className="list-none space-y-1">
                  <li>Email : contact@qvee.fr</li>
                  <li>Téléphone : 09 77 21 99 98</li>
                  <li>Adresse : 36 rue du ranc, 30630 Saint André de Roquepertuis, France</li>
                </ul>
             
            </div>

            <div className="bg-gray-50 p-6 rounded-lg">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">8. Modalités de Remboursement</h2>
              <div className="space-y-4">
                <p className="text-gray-700">
                  Conformément à la réglementation française, les Utilisateurs bénéficient d'un droit de rétractation de 14 jours à compter de la souscription d'un service. Les demandes de remboursement doivent être effectuées dans ce délai et seront traitées dans les meilleurs délais, sous réserve de conformité avec les conditions énoncées.</p>
<p className="text-gray-700">
Frais de création de compte pour l'offre Basic : Un montant de 10 € est facturé pour la création de compte dans le cadre de l'offre Basic. Ces frais ne sont pas remboursables, car ils couvrent les coûts de gestion et d'administration liés à la mise en place du compte.

Pour toute demande de remboursement ou pour obtenir des informations supplémentaires, veuillez nous contacter aux coordonnées fournies ci-dessous.
                </p>
                <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4">
                  <p className="text-yellow-700">
                    <strong>Important :</strong> Les frais de création de compte Basic (10€) ne sont pas remboursables.
                  </p>
                </div>
              </div>
            </div>

            <div className="bg-gray-50 p-6 rounded-lg">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">9. Modifications de la Politique</h2>
              <p className="text-gray-700">
                Nous nous réservons le droit de modifier cette Politique de Confidentialité et les modalités de remboursement à tout moment pour refléter les changements législatifs, réglementaires ou ceux liés à nos pratiques commerciales. Toute modification sera notifiée sur notre site web ou par email. La version la plus récente sera toujours disponible sur notre site web.
              </p>
            </div>

            <div className="bg-gray-50 p-6 rounded-lg">
              <h2 className="text-2xl font-bold text-gray-900 mb-4">10. Contact</h2>
              <div className="space-y-2 text-gray-700">
                <p>Pour toute question concernant cette Politique de Confidentialité et Remboursements ou la manière dont nous traitons vos données personnelles, veuillez nous contacter aux coordonnées suivantes :</p>
                <ul className="list-none space-y-1">
                  <li>Email : contact@qvee.fr</li>
                  <li>Téléphone : 09 77 21 99 98</li>
                  <li>Adresse : 36 rue du ranc, 30630 Saint André de Roquepertuis, France</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;