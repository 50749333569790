import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PagePublisher } from '../services/pagePublisher';
import type { PublishedPage } from '../hooks/usePublishedPages';
import { AlertTriangle } from 'lucide-react';
import PublishedCuveePreview from '../components/PublishedCuveePreview';
import { getDownloadURL, ref } from 'firebase/storage';
import { storage } from '../lib/firebase';

const PublishedCuvee = () => {
  const { companySlug, pageSlug } = useParams();
  const [page, setPage] = useState<PublishedPage | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPage = async () => {
      if (!companySlug || !pageSlug) return;

      try {
        setLoading(true);
        const pageData = await PagePublisher.getPageByUrl(companySlug, pageSlug);
        
        if (!pageData) {
          setError('not_found');
          return;
        }

        // Si la cuvée est bloquée, on affiche une erreur
        if (pageData.content.cuvee.status === 'blocked') {
          setError('blocked');
          return;
        }

        // Créer une copie profonde des données pour éviter les mutations directes
        const updatedPageData = JSON.parse(JSON.stringify(pageData));

        // Récupérer le logo de l'entreprise
        if (updatedPageData.content.company.logoPath) {
          try {
            const logoRef = ref(storage, updatedPageData.content.company.logoPath);
            updatedPageData.content.company.logo = await getDownloadURL(logoRef);
          } catch (err) {
            console.error('Error loading company logo:', err);
          }
        }

        // Récupérer l'image de la cuvée
        if (updatedPageData.content.cuvee.photoPath) {
          try {
            const photoRef = ref(storage, updatedPageData.content.cuvee.photoPath);
            updatedPageData.content.cuvee.photo = await getDownloadURL(photoRef);
          } catch (err) {
            console.error('Error loading cuvee photo:', err);
          }
        }

        setPage(updatedPageData);
        setError(null);
      } catch (err) {
        console.error('Error fetching page:', err);
        setError('not_found');
      } finally {
        setLoading(false);
      }
    };

    fetchPage();
  }, [companySlug, pageSlug]);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-purple-600"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center p-4">
        <div className="max-w-md w-full bg-white rounded-lg shadow-lg p-8 text-center">
          <div className="mx-auto flex h-20 w-20 items-center justify-center rounded-full bg-red-100 mb-6">
            <AlertTriangle className="h-10 w-10 text-red-600" />
          </div>
          {error === 'blocked' ? (
            <>
              <h1 className="text-2xl font-bold text-gray-900 mb-4">
                Contenu non disponible
              </h1>
              <p className="text-gray-600">
                Cette page a été retirée car elle ne respecte pas nos conditions générales d'utilisation.
              </p>
            </>
          ) : (
            <>
              <h1 className="text-2xl font-bold text-gray-900 mb-4">
                Page non trouvée
              </h1>
              <p className="text-gray-600">
                La page que vous recherchez n'existe pas ou a été supprimée.
              </p>
            </>
          )}
          <div className="mt-8 text-sm text-gray-500">
            Pour plus d'informations, veuillez contacter notre support à{' '}
            <a href="mailto:support@qvee.fr" className="text-purple-600 hover:text-purple-700">
              support@qvee.fr
            </a>
          </div>
        </div>
      </div>
    );
  }

  if (!page) return null;

  return <PublishedCuveePreview page={page} showPublicLink={false} />;
};

export default PublishedCuvee;