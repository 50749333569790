import { useState, useEffect } from 'react';
import { 
  collection, 
  query, 
  where, 
  getDocs, 
  addDoc, 
  updateDoc, 
  deleteDoc, 
  doc, 
  getDoc, 
  onSnapshot 
} from 'firebase/firestore';
import { db } from '../lib/firebase';
import { useActivityLogs } from './useActivityLogs';
import { logCuveeAction } from '../lib/serverLogs';
import { PagePublisher } from '../services/pagePublisher';
import { usePlanLimits } from './usePlanLimits';
import { checkForBanwords } from '../utils/banwords';
import { CuveeStatus } from '../types/cuvee';

export interface Cuvee {
  id: string;
  lastModified: string;
  userId: string;
}

export const checkCuveeNameExists = async (userId: string, cuveeName: string, excludeCuveeId?: string): Promise<boolean> => {
  try {
    const normalizedName = cuveeName.toLowerCase().trim();
    
    const cuveesRef = collection(db, 'cuvees');
    const q = query(cuveesRef, where('userId', '==', userId));
    const snapshot = await getDocs(q);
    
    // Vérifier si le nom existe déjà (insensible à la casse)
    const exists = snapshot.docs.some(doc => {
      const cuveeData = doc.data();
      // Exclure la cuvée en cours de modification si un ID est fourni
      if (excludeCuveeId && doc.id === excludeCuveeId) {
        return false;
      }
      return cuveeData.name.toLowerCase().trim() === normalizedName;
    });

    return exists;
  } catch (error) {
    console.error('Error checking cuvee name:', error);
    throw new Error('Erreur lors de la vérification du nom de la cuvée');
  }
};

export const useCuvees = (userId: string | undefined) => {
  const [cuvees, setCuvees] = useState<Cuvee[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { addLog } = useActivityLogs(userId);
  const { canPublishMore } = usePlanLimits(userId);

  useEffect(() => {
    if (!userId) {
      setLoading(false);
      return;
    }

    const unsubscribe = onSnapshot(
      query(collection(db, 'cuvees'), where('userId', '==', userId)),
      (snapshot) => {
        const cuveesData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        })) as Cuvee[];

        cuveesData.sort((a, b) => {
          return new Date(b.lastModified).getTime() - new Date(a.lastModified).getTime();
        });

        setCuvees(cuveesData);
        setLoading(false);
      },
      (err) => {
        console.error('Error fetching cuvees:', err);
        setError(err.message || 'Une erreur est survenue lors de la récupération des cuvées');
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [userId]);

  const addCuvee = async (cuveeData: any) => {
    if (!userId) {
      throw new Error('Utilisateur non authentifié');
    }

    if (!canPublishMore() && cuveeData.status === CuveeStatus.PUBLISHED) {
      throw new Error('Vous avez atteint la limite de cuvées publiées pour votre plan');
    }

    try {
      // Vérifier si le nom existe déjà (insensible à la casse)
      const exists = await checkCuveeNameExists(userId, cuveeData.name);
      if (exists) {
        throw new Error('Une cuvée avec ce nom existe déjà');
      }

      const validatedData = {
        ...cuveeData,
        userId,
        lastModified: new Date().toISOString(),
        status: cuveeData.status || CuveeStatus.DRAFT
      };

      if (!validatedData.name) {
        throw new Error('Le nom de la cuvée est requis');
      }

      const cuveesRef = collection(db, 'cuvees');
      const docRef = await addDoc(cuveesRef, validatedData);

      const userDoc = await getDoc(doc(db, 'users', userId));
      const userData = userDoc.data();

      await logCuveeAction(
        userId,
        userData?.email,
        'Création',
        validatedData.name
      );

      await addLog('cuvee_create', {
        cuveeName: validatedData.name,
        cuveeId: docRef.id
      });

      if (validatedData.status === CuveeStatus.PUBLISHED) {
        await PagePublisher.handleCuveePublication({
          id: docRef.id,
          ...validatedData
        }, userId);
        
        await addLog('cuvee_publish', {
          cuveeName: validatedData.name,
          cuveeId: docRef.id
        });
      }

      return {
        id: docRef.id,
        ...validatedData
      };
    } catch (err: any) {
      throw err;
    }
  };

  const updateCuvee = async (id: string, updates: any) => {
    if (!userId) {
      throw new Error('Utilisateur non authentifié');
    }

    try {
      const cuveeRef = doc(db, 'cuvees', id);
      const cuveeDoc = await getDoc(cuveeRef);

      if (!cuveeDoc.exists()) {
        throw new Error('Cuvée non trouvée');
      }

      const currentData = cuveeDoc.data();
      if (currentData.userId !== userId) {
        throw new Error('Non autorisé à modifier cette cuvée');
      }

      // Si le nom est modifié, vérifier qu'il n'existe pas déjà
      if (updates.name && updates.name !== currentData.name) {
        const exists = await checkCuveeNameExists(userId, updates.name, id);
        if (exists) {
          throw new Error('Une cuvée avec ce nom existe déjà');
        }
      }

      if (updates.status === CuveeStatus.PUBLISHED && currentData.status !== CuveeStatus.PUBLISHED && !canPublishMore()) {
        throw new Error('Vous avez atteint la limite de cuvées publiées pour votre plan');
      }

      const validatedData = {
        ...updates,
        lastModified: new Date().toISOString()
      };


      await updateDoc(cuveeRef, validatedData);

      const userDoc = await getDoc(doc(db, 'users', userId));
      const userData = userDoc.data();

      await logCuveeAction(
        userId,
        userData?.email,
        'Modification',
        validatedData.name || currentData.name
      );

      await addLog('cuvee_update', {
        cuveeName: validatedData.name || currentData.name,
        cuveeId: id
      });

      // Gérer la publication/dépublication
      if (updates.status === CuveeStatus.PUBLISHED) {
        await PagePublisher.handleCuveePublication({
          id,
          ...currentData,
          ...validatedData
        }, userId);

        await addLog('cuvee_publish', {
          cuveeName: validatedData.name || currentData.name,
          cuveeId: id
        });
      } else if (currentData.status === CuveeStatus.PUBLISHED && updates.status !== CuveeStatus.PUBLISHED) {
        await PagePublisher.handleCuveeUnpublication(id);
      }

      return {
        id,
        ...currentData,
        ...validatedData
      };
    } catch (err: any) {
      console.error('Error updating cuvee:', err);
      throw new Error(err.message || 'Une erreur est survenue lors de la mise à jour de la cuvée');
    }
  };

  const deleteCuvee = async (id: string) => {
    if (!userId) {
      throw new Error('Utilisateur non authentifié');
    }

    try {
      const cuveeRef = doc(db, 'cuvees', id);
      const cuveeDoc = await getDoc(cuveeRef);

      if (!cuveeDoc.exists()) {
        throw new Error('Cuvée non trouvée');
      }

      const currentData = cuveeDoc.data();
      if (currentData.userId !== userId) {
        throw new Error('Non autorisé à supprimer cette cuvée');
      }

      const userDoc = await getDoc(doc(db, 'users', userId));
      const userData = userDoc.data();

      await logCuveeAction(
        userId,
        userData?.email,
        'Suppression',
        currentData.name
      );

      await addLog('cuvee_delete', {
        cuveeName: currentData.name,
        cuveeId: id
      });

      if (currentData.status === CuveeStatus.PUBLISHED) {
        await PagePublisher.handleCuveeDeletion(id);
      }

      // Supprimer les QR codes associés
      const qrCodesRef = collection(db, 'qrCodes');
      const qrCodesQuery = query(qrCodesRef, where('cuveeId', '==', id));
      const qrCodesSnapshot = await getDocs(qrCodesQuery);
      
      const deleteQRCodes = qrCodesSnapshot.docs.map(doc => deleteDoc(doc.ref));
      await Promise.all(deleteQRCodes);

      await deleteDoc(cuveeRef);
    } catch (err: any) {
      console.error('Error deleting cuvee:', err);
      throw new Error(err.message || 'Une erreur est survenue lors de la suppression de la cuvée');
    }
  };

  return {
    cuvees,
    loading,
    error,
    addCuvee,
    updateCuvee,
    deleteCuvee
  };
};