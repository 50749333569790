import React from 'react';
import { 
  Wine, 
  Calendar, 
  Star, 
  Beaker, 
  ThermometerSun, 
  Utensils,
  Building2,
  Globe,
  MapPin,
  Leaf,
  Apple,
  Flame,
  GlassWater,
  Droplet,
  Scale,
  Monitor,
  Smartphone,
  Shield,
  TestTube,
  Pipette,
  Wind,
  Plus,
  Sparkles,
  Gauge,
  Phone,
  AlertTriangle
} from 'lucide-react';
import CertificationImage from './CertificationImage';

interface PublishedCuveePreviewProps {
  page: {
    fullUrl: string;
    content: {
      cuvee: any;
      design: {
        colors: {
          primary: string;
          secondary: string;
          text: string;
          background: string;
          boxText: string;
        };
        typography: {
          headingFont: string;
          bodyFont: string;
        };
        footer: {
          text: string;
          showSocials: boolean;
        };
        display?: {
          showFooter?: boolean;
          showCompanyName?: boolean;
          showLogo?: boolean;
          showCertifications?: boolean;
        };
      };
      company: {
        name: string;
        logo?: string;
        website?: string;
        phone?: string;
        certifications?: string[];
        address?: {
          street?: string;
          city?: string;
          postalCode?: string;
          country?: string;
        };
      };
    };
  };
  showPublicLink?: boolean;
  viewMode?: 'desktop' | 'mobile';
  onViewModeChange?: (mode: 'desktop' | 'mobile') => void;
}

const certificationDetails: Record<string, { name: string; description: string }> = {
  'vigneron-independant': {
    name: 'Vigneron Indépendant',
    description: 'Label des vignerons qui cultivent leurs vignes, vinifient et commercialisent leurs vins.'
  },
  'hve': {
    name: 'Haute Valeur Environnementale',
    description: 'Certification environnementale des exploitations agricoles.'
  },
  'terra-vitis': {
    name: 'Terra Vitis',
    description: 'Certification environnementale spécifique à la viticulture.'
  },
  'viticulture-durable': {
    name: 'Viticulture Durable en Champagne',
    description: 'Certification de viticulture durable spécifique à la Champagne.'
  },
  'biodyvin': {
    name: 'Biodyvin',
    description: 'Label de viticulture biodynamique.'
  },
  'demeter': {
    name: 'Demeter',
    description: 'Label international de l\'agriculture biodynamique.'
  },
  'demeter-certifie': {
    name: 'Demeter Certifié',
    description: 'Certification Demeter officielle.'
  },
  'avn': {
    name: 'AVN Association des Vins Naturels',
    description: 'Label des vins naturels.'
  },
  'sains': {
    name: 'S.A.I.N.S',
    description: 'Sans Aucun Intrant Ni Sulfite (ajouté).'
  },
  'nature-progres': {
    name: 'Nature & Progrès',
    description: 'Label bio associatif avec un cahier des charges strict.'
  },
  'bio-coherence': {
    name: 'Bio Cohérence',
    description: 'Label bio français plus exigeant que le label AB.'
  },
  'ab': {
    name: 'Agriculture Biologique',
    description: 'Label officiel de l\'agriculture biologique.'
  }
};

const defaultDisplaySettings = {
  showFooter: true,
  showCompanyName: true,
  showLogo: true,
  showCertifications: true
};

interface InfoCardProps {
  icon: any;
  label: string;
  value: string | number;
  design: PublishedCuveePreviewProps['page']['content']['design'];
  viewMode: 'desktop' | 'mobile';
}

const InfoCard: React.FC<InfoCardProps> = ({ 
  icon: Icon, 
  label, 
  value, 
  design,
  viewMode 
}) => (
  <div 
    className={`p-4 rounded-xl bg-opacity-5 border border-opacity-10 transition-colors duration-200 ${
      viewMode === 'mobile' ? 'col-span-12' : ''
    }`}
    style={{ 
      backgroundColor: `${design.colors.primary}10`,
      borderColor: design.colors.primary
    }}
  >
    <div className="flex items-center gap-3 mb-2">
      <Icon className="h-5 w-5" style={{ color: design.colors.primary }} />
      <div 
        className="text-sm font-medium"
        style={{ color: design.colors.text }}
      >
        {label}
      </div>
    </div>
    <div 
      className="text-xl font-bold"
      style={{ color: design.colors.primary }}
    >
      {value}
    </div>
  </div>
);

const SectionTitle: React.FC<{ 
  icon: any; 
  children: React.ReactNode; 
  design: PublishedCuveePreviewProps['page']['content']['design'] 
}> = ({ 
  icon: Icon, 
  children,
  design 
}) => (
  <h2 
    className="text-2xl md:text-3xl font-bold flex items-center gap-3 mb-6"
    style={{ 
      color: design.colors.primary,
      fontFamily: design.typography.headingFont 
    }}
  >
    <Icon className="h-6 w-6" />
    {children}
  </h2>
);

const DetailSection: React.FC<{ 
  icon: any; 
  title: string; 
  content: string;
  design: PublishedCuveePreviewProps['page']['content']['design'];
}> = ({ 
  icon: Icon, 
  title, 
  content,
  design 
}) => (
  <div className="space-y-2">
    <h3 className="text-lg font-medium flex items-center gap-2">
      <Icon className="h-5 w-5" style={{ color: design.colors.primary }} />
      {title}
    </h3>
    <p className="text-gray-600 ml-7">{content}</p>
  </div>
);

const AdditivesList: React.FC<{
  title: string;
  items: string[];
  icon: any;
}> = ({ title, items, icon: Icon }) => (
  <div className="space-y-2">
    <h3 className="text-lg font-medium flex items-center gap-2">
      <Icon className="h-5 w-5" />
      {title}
    </h3>
    <ul className="list-disc list-inside ml-7 space-y-1">
      {items.map((item, index) => (
        <li key={index} className="text-gray-600">{item}</li>
      ))}
    </ul>
  </div>
);

const hasAdditives = (items?: string[]) => {
  return items && items.length > 0;
};

const PublishedCuveePreview: React.FC<PublishedCuveePreviewProps> = ({ 
  page, 
  showPublicLink = false,
  viewMode = 'desktop',
  onViewModeChange
}) => {
  const { content: { cuvee, design, company } } = page;
  const displaySettings = { ...defaultDisplaySettings, ...design.display };

  if (cuvee.status === 'blocked') {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center p-4">
        <div className="max-w-md w-full bg-white rounded-lg shadow-lg p-8 text-center">
          <div className="mx-auto flex h-20 w-20 items-center justify-center rounded-full bg-red-100 mb-6">
            <AlertTriangle className="h-10 w-10 text-red-600" />
          </div>
          <h1 className="text-2xl font-bold text-gray-900 mb-4">
            Contenu non disponible
          </h1>
          <p className="text-gray-600">
            Cette page a été retirée car elle ne respecte pas nos conditions générales d'utilisation.
          </p>
          <div className="mt-8 text-sm text-gray-500">
            Pour plus d'informations, veuillez contacter notre support à{' '}
            <a href="mailto:support@qvee.fr" className="text-purple-600 hover:text-purple-700">
              support@qvee.fr
            </a>
          </div>
        </div>
      </div>
    );
  }

  const hasAnyAdditives = () => {
    const { ingredients } = cuvee;
    if (!ingredients) return false;
    
    return (
      hasAdditives(ingredients.vinification) ||
      hasAdditives(ingredients.preservatives) ||
      hasAdditives(ingredients.regulators) ||
      hasAdditives(ingredients.stabilizers) ||
      hasAdditives(ingredients.clarifiers) ||
      hasAdditives(ingredients.otherAdditives) ||
      hasAdditives(ingredients.packagingGases)
    );
  };

  return (
    <div 
      className="min-h-screen"
      style={{
        backgroundColor: design.colors.background,
        color: design.colors.text
      }}
    >
      <header className="border-b" style={{ borderColor: `${design.colors.primary}20` }}>
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-6">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-4">
              {displaySettings.showLogo && company.logo && (
                <img 
                  src={company.logo} 
                  alt={company.name}
                  className="h-12 w-auto"
                />
              )}
              {displaySettings.showCompanyName && (
                <div 
                  className="text-xl font-bold" 
                  style={{ 
                    color: design.colors.primary,
                    fontFamily: design.typography.headingFont
                  }}
                >
                  {company.name}
                </div>
              )}
            </div>

            {showPublicLink && (
              <div className="flex items-center space-x-1 bg-gray-100 rounded-lg p-1">
                <button
                  onClick={() => onViewModeChange?.('desktop')}
                  className={`flex items-center space-x-1 px-3 py-1.5 rounded-md text-sm transition-colors ${
                    viewMode === 'desktop'
                      ? 'bg-white text-gray-700 shadow-sm'
                      : 'text-gray-500 hover:text-gray-700'
                  }`}
                >
                  <Monitor className="h-4 w-4" />
                  <span>Ordinateur</span>
                </button>
                <button
                  onClick={() => onViewModeChange?.('mobile')}
                  className={`flex items-center space-x-1 px-3 py-1.5 rounded-md text-sm transition-colors ${
                    viewMode === 'mobile'
                      ? 'bg-white text-gray-700 shadow-sm'
                      : 'text-gray-500 hover:text-gray-700'
                  }`}
                >
                  <Smartphone className="h-4 w-4" />
                  <span>Mobile</span>
                </button>
              </div>
            )}
          </div>
        </div>
      </header>

      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 sm:py-12">
        <div className="space-y-8">
          <div className="space-y-8">
            <div className="flex flex-col md:flex-row md:items-center gap-8">
              {cuvee.photo && (
                <div className="flex-shrink-0">
                  <img 
                    src={cuvee.photo} 
                    alt={cuvee.name}
                    className="w-[150px] h-[150px] object-cover rounded-lg shadow-md"
                  />
                </div>
              )}
              <div>
                <h1 
                  className="text-4xl font-bold mb-2"
                  style={{ 
                    color: design.colors.primary,
                    fontFamily: design.typography.headingFont 
                  }}
                >
                  {cuvee.name}
                </h1>
                <div className="flex items-center space-x-4 text-sm">
                  <span>{cuvee.vintage}</span>
                  <span>•</span>
                  <span>{cuvee.type}</span>
                  <span>•</span>
                  <span>{cuvee.appellation}</span>
                </div>
              </div>
            </div>

            {cuvee.description && (
              <div 
                className="prose max-w-none"
                style={{ fontFamily: design.typography.bodyFont }}
              >
                <p>{cuvee.description}</p>
              </div>
            )}

            <div className={`grid grid-cols-1 ${viewMode === 'mobile' ? 'grid-cols-12' : 'sm:grid-cols-2 lg:grid-cols-3'} gap-4`}>
              {cuvee.alcoholContent > 0 && (
                <InfoCard 
                  icon={Droplet}
                  label="Degré d'alcool"
                  value={`${cuvee.alcoholContent}%`}
                  design={design}
                  viewMode={viewMode}
                />
              )}
              {cuvee.volume > 0 && (
                <InfoCard 
                  icon={Scale}
                  label="Volume"
                  value={`${cuvee.volume}ml`}
                  design={design}
                  viewMode={viewMode}
                />
              )}
              {cuvee.lotNumber && (
                <InfoCard 
                  icon={Star}
                  label="N° de lot"
                  value={cuvee.lotNumber}
                  design={design}
                  viewMode={viewMode}
                />
              )}
            </div>
          </div>

          {(cuvee.blend || cuvee.agingPotential || cuvee.peak || cuvee.vinification || cuvee.storageAdvice || cuvee.tastingNotes) && (
            <div className="bg-white rounded-xl p-8 shadow-sm">
              <SectionTitle icon={GlassWater} design={design}>Caractéristiques</SectionTitle>
              <div className="space-y-6">
                {cuvee.blend && (
                  <DetailSection 
                    icon={Beaker}
                    title="Assemblage"
                    content={cuvee.blend}
                    design={design}
                  />
                )}
                {cuvee.agingPotential && (
                  <DetailSection 
                    icon={Calendar}
                    title="Temps de garde"
                    content={cuvee.agingPotential}
                    design={design}
                  />
                )}
                {cuvee.peak && (
                  <DetailSection 
                    icon={Star}
                    title="Apogée"
                    content={cuvee.peak}
                    design={design}
                  />
                )}
                {cuvee.vinification && (
                  <DetailSection 
                    icon={Beaker}
                    title="Vinification"
                    content={cuvee.vinification}
                    design={design}
                  />
                )}
                {cuvee.storageAdvice && (
                  <DetailSection 
                    icon={ThermometerSun}
                    title="Conseil de conservation"
                    content={cuvee.storageAdvice}
                    design={design}
                  />
                )}
                {cuvee.tastingNotes && (
                  <DetailSection 
                    icon={Utensils}
                    title="Notes de dégustation"
                    content={cuvee.tastingNotes}
                    design={design}
                  />
                )}
              </div>
            </div>
          )}

          {(cuvee.energy?.kcal > 0 || cuvee.nutritionalValues?.carbohydrates > 0) && (
            <div className="bg-white rounded-xl p-8 shadow-sm">
              <SectionTitle icon={Apple} design={design}>Valeurs nutritionnelles</SectionTitle>
              <p className="text-sm text-gray-500 mb-6">Pour 100ml</p>
              
              <div className="space-y-4">
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-6">
                  <InfoCard 
                    icon={Flame}
                    label="Énergie"
                    value={`${cuvee.energy.kcal} kcal`}
                    design={design}
                    viewMode={viewMode}
                  />
                  <InfoCard 
                    icon={Flame}
                    label="Énergie"
                    value={`${cuvee.energy.kj} kJ`}
                    design={design}
                    viewMode={viewMode}
                  />
                </div>

                <div className="space-y-2">
                  {cuvee.nutritionalValues.fat > 0 && (
                    <div className="flex justify-between items-center py-2 border-b border-gray-100">
                      <span className="text-sm text-gray-600">Matières grasses</span>
                      <span className="font-medium">{cuvee.nutritionalValues.fat} g</span>
                    </div>
                  )}
                  {cuvee.nutritionalValues.saturatedFat > 0 && (
                    <div className="flex justify-between items-center py-2 border-b border-gray-100">
                      <span className="text-sm text-gray-600">dont acides gras saturés</span>
                      <span className="font-medium">{cuvee.nutritionalValues.saturatedFat} g</span>
                    </div>
                  )}
                  {cuvee.nutritionalValues.carbohydrates > 0 && (
                    <div className="flex justify-between items-center py-2 border-b border-gray-100">
                      <span className="text-sm text-gray-600">Glucides</span>
                      <span className="font-medium">{cuvee.nutritionalValues.carbohydrates} g</span>
                    </div>
                  )}
                  {cuvee.nutritionalValues.sugars > 0 && (
                    <div className="flex justify-between items-center py-2 border-b border-gray-100">
                      <span className="text-sm text-gray-600">dont sucres</span>
                      <span className="font-medium">{cuvee.nutritionalValues.sugars} g</span>
                    </div>
                  )}
                  {cuvee.nutritionalValues.proteins > 0 && (
                    <div className="flex justify-between items-center py-2 border-b border-gray-100">
                      <span className="text-sm text-gray-600">Protéines</span>
                      <span className="font-medium">{cuvee.nutritionalValues.proteins} g</span>
                    </div>
                  )}
                  {cuvee.nutritionalValues.salt > 0 && (
                    <div className="flex justify-between items-center py-2 border-b border-gray-100">
                      <span className="text-sm text-gray-600">Sel</span>
                      <span className="font-medium">{cuvee.nutritionalValues.salt} g</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {(cuvee.ingredients || hasAnyAdditives()) && (
            <div className="bg-white rounded-xl p-8 shadow-sm">
              <SectionTitle icon={TestTube} design={design}>Additifs soumis à étiquetage</SectionTitle>
              
              {hasAnyAdditives() ? (
                <div className="space-y-6">
                  {cuvee.ingredients?.vinification && hasAdditives(cuvee.ingredients.vinification) && (
                    <AdditivesList
                      title="Ingrédients lors de la vinification"
                      items={cuvee.ingredients.vinification}
                      icon={Beaker}
                    />
                  )}
                  {cuvee.ingredients?.preservatives && hasAdditives(cuvee.ingredients.preservatives) && (
                    <AdditivesList
                      title="Conservateurs utilisés"
                      items={cuvee.ingredients.preservatives}
                      icon={Shield}
                    />
                  )}
                  {cuvee.ingredients?.regulators && hasAdditives(cuvee.ingredients.regulators) && (
                    <AdditivesList
                      title="Régulateurs utilisés"
                      items={cuvee.ingredients.regulators}
                      icon={Gauge}
                    />
                  )}
                  {cuvee.ingredients?.stabilizers && hasAdditives(cuvee.ingredients.stabilizers) && (
                    <AdditivesList
                      title="Stabilisants utilisés"
                      items={cuvee.ingredients.stabilizers}
                      icon={Sparkles}
                    />
                  )}
                  {cuvee.ingredients?.clarifiers && hasAdditives(cuvee.ingredients.clarifiers) && (
                    <AdditivesList
                      title="Clarifiants"
                      items={cuvee.ingredients.clarifiers}
                      icon={Pipette}
                    />
                  )}
                  {cuvee.ingredients?.otherAdditives && hasAdditives(cuvee.ingredients.otherAdditives) && (
                    <AdditivesList
                      title="Autres additifs"
                      items={cuvee.ingredients.otherAdditives}
                      icon={Plus}
                    />
                  )}
                  {cuvee.ingredients?.packagingGases && hasAdditives(cuvee.ingredients.packagingGases) && (
                    <AdditivesList
                      title="Gaz d'emballage"
                      items={cuvee.ingredients.packagingGases}
                      icon={Wind}
                    />
                  )}
                </div>
              ) : (
                <div className="flex items-center justify-center py-8 text-gray-500">
                  <Shield className="h-5 w-5 mr-2" style={{ color: design.colors.primary }} />
                  <p>Aucun additif utilisé</p>
                </div>
              )}
            </div>
          )}

          {displaySettings.showCertifications && cuvee.certifications && cuvee.certifications.length > 0 && (
            <div className="bg-white rounded-xl p-8 shadow-sm">
              <SectionTitle icon={Leaf} design={design}>Certifications du domaine</SectionTitle>
              <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-6">
                {cuvee.certifications.map((certification) => (
                  <div 
                    key={certification}
                    className="p-6 rounded-xl flex flex-col items-center justify-center text-center bg-white border transition-all duration-200 hover:shadow-md"
                    style={{ 
                      borderColor: `${design.colors.primary}20`
                    }}
                  >
                    <CertificationImage
                      name={certification}
                      className="h-16 w-auto mb-3"
                    />
                    <span className="text-sm font-medium" style={{ color: design.colors.text }}>
                      {certificationDetails[certification]?.name || certification}
                    </span>
                    {certificationDetails[certification]?.description && (
                      <p className="mt-2 text-xs text-gray-500">
                        {certificationDetails[certification].description}
                      </p>
                    )}
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </main>

      {displaySettings.showFooter && (
        <footer 
          className="border-t mt-8 py-8"
          style={{ borderColor: `${design.colors.primary}20` }}
        >
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center space-y-4">
              {design.footer.text && (
                <p 
                  className="text-sm"
                  style={{ fontFamily: design.typography.bodyFont }}
                >
                  {design.footer.text}
                </p>
              )}
              {company.address && (
                <address className="text-xs sm:text-sm not-italic space-y-2">
                  {company.address.street && <div>{company.address.street}</div>}
                  <div>
                    {company.address.postalCode} {company.address.city}
                  </div>
                  {company.address.country && <div>{company.address.country}</div>}
                  
                  <div className="flex items-center justify-center space-x-4 mt-4">
                    {company.website && (
                      <a 
                        href={company.website}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center text-gray-600 hover:text-purple-600"
                      >
                        <Globe className="h-4 w-4 mr-1" />
                        <span>{company.website}</span>
                      </a>
                    )}
                    {company.phone && (
                      <a 
                        href={`tel:${company.phone}`}
                        className="flex items-center text-gray-600 hover:text-purple-600"
                      >
                        <Phone className="h-4 w-4 mr-1" />
                        <span>{company.phone}</span>
                      </a>
                    )}
                  </div>
                </address>
              )}
            </div>
          </div>
        </footer>
      )}
    </div>
  );
};

export default PublishedCuveePreview;