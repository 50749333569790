import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { 
  X, 
  Wine, 
  BarChart3, 
  QrCode, 
  Settings, 
  Palette, 
  Users,
  Shield,
  CreditCard,
  FileText,
  LogOut,
  Search,
  LifeBuoy,
  Lock,
  Crown,
  Bell
} from 'lucide-react';
import { useAuthContext } from '../contexts/AuthContext';
import LogoutConfirmation from './LogoutConfirmation';
import { PLAN_FEATURES, PlanType } from '../hooks/usePlanLimits';
import { useTickets } from '../hooks/useTickets';

interface MobileMenuProps {
  isOpen: boolean;
  onClose: () => void;
  isAdmin: boolean;
  userProfile: any;
  userPlan?: string;
}

const MobileMenu: React.FC<MobileMenuProps> = ({
  isOpen,
  onClose,
  isAdmin,
  userProfile,
  userPlan = 'basic'
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { logout } = useAuthContext();
  const { unreadCount } = useTickets(userProfile?.id, isAdmin);
  const [showLogoutConfirmation, setShowLogoutConfirmation] = React.useState(false);

  const handleLogoutClick = () => {
    setShowLogoutConfirmation(true);
  };

  const handleLogoutConfirm = async () => {
    try {
      await logout();
      setShowLogoutConfirmation(false);
      navigate('/login');
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  if (!isOpen) return null;

  const adminMenuItems = [
    { icon: <BarChart3 size={20} />, label: 'Dashboard', path: '/admin/dashboard' },
    { icon: <Wine size={20} />, label: 'Cuvées', path: '/admin/cuvees' },
    { icon: <Users size={20} />, label: 'Utilisateurs', path: '/users' },
    { 
      icon: <LifeBuoy size={20} />, 
      label: 'Support', 
      path: '/admin/support',
      badge: unreadCount > 0 ? unreadCount : undefined
    },
    { icon: <Bell size={20} />, label: 'Notifications', path: '/admin/notifications' },
    { icon: <FileText size={20} />, label: 'Logs serveur', path: '/admin/logs' }
  ];

  const userMenuItems = [
    { 
      icon: <BarChart3 size={20} />, 
      label: 'Dashboard',
      path: '/dashboard',
      requiresFeature: 'dashboard',
      redirectTo: '/upsells'
    },
    { icon: <Wine size={20} />, label: 'Mes Cuvées', path: '/cuvees' },
    { icon: <QrCode size={20} />, label: 'QR Codes', path: '/qr-codes' },
    { 
      icon: <Palette size={20} />, 
      label: 'Design', 
      path: '/design',
      requiresFeature: 'design',
      redirectTo: '/upsells'
    },
  ];

  const bottomMenuItems = [
    { 
      icon: <LifeBuoy size={20} />, 
      label: 'Support', 
      path: '/support',
      requiresFeature: 'support',
      redirectTo: '/upsells'
    },
    { icon: <CreditCard size={20} />, label: 'Abonnement', path: '/subscription' },
    { icon: <Settings size={20} />, label: 'Paramètres', path: '/settings' }
  ];

  const menuItems = isAdmin ? adminMenuItems : userMenuItems;

  return (
    <div className="fixed inset-0 z-50 md:hidden">
      {/* Backdrop */}
      <div 
        className="fixed inset-0 bg-black bg-opacity-50 transition-opacity"
        onClick={onClose}
      />

      {/* Drawer */}
      <div className="fixed inset-y-0 left-0 w-64 bg-white shadow-lg transform transition-transform duration-300 ease-in-out">
        {/* Header */}
        <div className="h-16 flex items-center justify-between px-4 bg-purple-700">
          <div className="flex items-center space-x-2">
            {isAdmin ? (
              <>
                <Shield className="h-8 w-8 text-white" />
                <span className="text-white text-xl font-semibold">Admin</span>
              </>
            ) : (
              <>
                <Wine className="h-8 w-8 text-white" />
                <span className="text-white text-xl font-semibold">Qvee</span>
              </>
            )}
          </div>
          <button
            onClick={onClose}
            className="p-2 rounded-full hover:bg-purple-600 transition-colors"
          >
            <X className="h-6 w-6 text-white" />
          </button>
        </div>

        {/* User Info */}
        <div className="p-4 border-b border-gray-200">
          <div>
            <div className="flex items-center justify-between mb-1">
              <p className="font-medium text-gray-900">{userProfile.firstName} {userProfile.lastName}</p>
              {isAdmin ? (
                <div className="flex items-center text-red-600">
                  <Shield className="h-4 w-4 mr-1" />
                  <span className="text-xs font-medium">Admin</span>
                </div>
              ) : userProfile.plan && (
                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                  userProfile.plan === 'premium' 
                    ? 'bg-purple-100 text-purple-800'
                    : userProfile.plan === 'standard'
                    ? 'bg-blue-100 text-blue-800'
                    : userProfile.plan === 'gold'
                    ? 'bg-yellow-100 text-yellow-800 flex items-center'
                    : 'bg-gray-100 text-gray-800'
                }`}>
                  {userProfile.plan === 'gold' && (
                    <Crown className="h-3 w-3 mr-1" />
                  )}
                  {userProfile.plan.charAt(0).toUpperCase() + userProfile.plan.slice(1)}
                </span>
              )}
            </div>
            <p className="text-sm text-gray-500">{userProfile.email}</p>
          </div>
        </div>

        {/* Search (Admin only) */}
        {isAdmin && (
          <div className="p-4 border-b border-gray-200">
            <div className="relative">
              <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
              <input
                type="text"
                placeholder="Rechercher..."
                className="w-full pl-10 pr-4 py-2 rounded-lg border border-gray-300 focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
              />
            </div>
          </div>
        )}

        {/* Menu Items */}
        <div className="flex-1 overflow-y-auto">
          <nav className="px-2 py-4 space-y-1">
            {menuItems.map((item) => {
              const isActive = location.pathname === item.path;
              const isLocked = !isAdmin && item.requiresFeature && !PLAN_FEATURES[userPlan as PlanType][item.requiresFeature as keyof typeof PLAN_FEATURES[PlanType]];

              return (
                <Link
                  key={item.path}
                  to={isLocked ? (item.redirectTo || '#') : item.path}
                  onClick={onClose}
                  className={`${
                    isActive
                      ? 'bg-purple-50 text-purple-700'
                      : isLocked
                      ? 'text-gray-400 bg-gray-50 cursor-not-allowed'
                      : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                  } group flex items-center justify-between px-4 py-3 text-base font-medium rounded-md transition-colors duration-150`}
                >
                  <div className="flex items-center">
                    <span className="flex-shrink-0 w-6">{item.icon}</span>
                    <span className="ml-3">{item.label}</span>
                  </div>
                  <div className="flex items-center space-x-2">
                    {item.badge !== undefined && !isLocked && (
                      <span className="inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                        {item.badge}
                      </span>
                    )}
                    {isLocked && <Lock className="h-4 w-4 text-[#9333EA]" />}
                  </div>
                </Link>
              );
            })}
          </nav>
        </div>

        {/* Bottom Actions */}
        <div className="border-t border-gray-200 p-4 space-y-2">
          {!isAdmin && (
            <>
              {bottomMenuItems.map((item) => {
                if ((item.hideForBasic && userPlan === 'basic') || 
                    (item.hideForStandard && userPlan === 'standard')) {
                  return null;
                }

                  const isActive = location.pathname === item.path;
                  const isLocked = item.requiresFeature && !PLAN_FEATURES[userPlan as PlanType][item.requiresFeature as keyof typeof PLAN_FEATURES[PlanType]];

                  return (
                    <Link
                      key={item.path}
                      to={isLocked ? (item.redirectTo || '#') : item.path}
                      onClick={onClose}
                      className={`${
                        isActive
                          ? 'bg-purple-50 text-purple-700'
                          : isLocked
                          ? 'text-gray-400 bg-gray-50 cursor-not-allowed'
                          : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900'
                      } group flex items-center justify-between px-4 py-3 text-base font-medium rounded-md transition-colors duration-150`}
                    >
                      <div className="flex items-center">
                        <span className="flex-shrink-0 w-6">{item.icon}</span>
                        <span className="ml-3">{item.label}</span>
                      </div>
                      <div className="flex items-center space-x-2">
                        {item.badge !== undefined && !isLocked && (
                          <span className="inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                            {item.badge}
                          </span>
                        )}
                        {isLocked && <Lock className="h-4 w-4 text-[#9333EA]" />}
                      </div>
                    </Link>
                  );
                })}
            </>
          )}

          <button
            onClick={handleLogoutClick}
            className="w-full flex items-center px-4 py-3 text-base font-medium text-red-600 hover:bg-red-50 rounded-md transition-colors duration-150"
          >
            <span className="flex-shrink-0 w-6">
              <LogOut className="h-5 w-5" />
            </span>
            <span className="ml-3">Se déconnecter</span>
          </button>
        </div>
      </div>

      <LogoutConfirmation
        isOpen={showLogoutConfirmation}
        onClose={() => setShowLogoutConfirmation(false)}
        onConfirm={handleLogoutConfirm}
      />
    </div>
  );
};

export default MobileMenu;